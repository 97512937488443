import React, { useEffect, useState } from "react";
import "./Predictions.css";
import axios from "axios";
import MatchDetails from "./MatchDetails";

const client = axios.create({
  baseURL: "https://leagueprodatabackend.vercel.app/",
});

const LoadingMessage = () => (
  <div className="loader-screen">
    <div className="loading-indicator">
      <div className="dot-spinner">
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
      </div>
    </div>
  </div>
);

const HomePredict = () => {
  const [calendar, setCalendar] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [showMatchDetails, setShowMatchDetails] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [nextPage, setNextPage] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false); // State to track data loading
  const [showPagination, setShowPagination] = useState(true);
  const [tournament, setTournament] = useState("All");


  const handleTournamentChange = (e) => {
    const newTournament = e.target.value;
    setTournament(newTournament);
    localStorage.setItem("lastTournament", newTournament); // Guardar en localStorage
  };
  
  useEffect(() => {
    const savedTournament = localStorage.getItem("lastTournament");
    if (savedTournament) {
      setTournament(savedTournament); // Restaurar el último torneo
    }
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        const response = await client.get("api/predictions/schedule/", {
          params: {
            page: currentPage,
            page_size: pageSize,
            tournament: tournament, // Passing tournament filter
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        const responseData = response.data;

        setCalendar(responseData.results);
        setNextPage(responseData.next);
        setLoading(false);
        setIsLoadingData(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingData(false);
      }
    };

    fetchData();
  }, [currentPage, pageSize, tournament]);

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        const authResponse = await axios.post(
          "https://leagueprodatabackend.vercel.app/api/get_session_info/",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        const authData = authResponse.data;
        setAuthenticated(authData.authenticated);
        setUsername(authData.username);
      } catch (error) {
        console.error("Error fetching authentication data:", error);
      }
    };

    fetchAuthData();
  }, []);

  const getModifiedMatchId = (match) => {
    const parts = match.matchId.split("/");
    const modifiedMatchId = parts.length > 1 ? parts[1] : match.matchId;
    return modifiedMatchId.replace(/[-_]/g, " ");
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const openMatchDetails = (match) => {
    setSelectedMatch(match);
    if (authenticated) {
      setShowMatchDetails(true);
      setShowPagination(false); // Oculta los botones de paginación
    } else {
      setShowLoginModal(true);
    }
  };

  const closeMatchDetails = () => {
    setSelectedMatch(null);
    setShowMatchDetails(false);
    setShowPagination(true); // Muestra nuevamente los botones de paginación
  };

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const loadPage = (page) => {
    if (page >= 1) {
      setCurrentPage(page);
    }
  };
  const setDocumentTitle = () => {
    document.title = `League Pro Data | Predictions`;
  };

  // Establece el título cuando el componente se monta
  useEffect(() => {
    setDocumentTitle();

    // Restablece el título original cuando el componente se desmonta
    return () => {
      document.title = "League Pro Data | Predictions";
    };
  });

  const tournamentOptions = [
    { value: "All", label: "All Tournaments" },
    { value: "Americas Challengers 2024", label: "Americas Challengers 2024" },
    {
      value: "Worlds 2024 Play-In",
      label: "2024 Season World Championship/Play-In",
    },
  ];


  return (
    <div className="prediction-home">
      <h4>2024 LEAGUE PRO DATA PREDICTIONS</h4>
      {!showMatchDetails && ( 
        <select
        className="select-tournament"
        value={tournament}
        onChange={handleTournamentChange} // Usar la nueva función
      >
        {tournamentOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      )}
      {loading || isLoadingData ? <LoadingMessage /> : null}{" "}
      {!loading &&
        !isLoadingData && ( 
          <>
            {showMatchDetails && authenticated ? (
              <MatchDetails match={selectedMatch} onClose={closeMatchDetails} />
            ) : (
              calendar.map((match) => (
                <div className="prediction-card" key={match.matchId}>
                  <div className="prediction-title">
                    <h6>
                      {getModifiedMatchId(match)} - Best of {match.bo}
                    </h6>
                  </div>
                  <div className="prediction-content more-info">
                    <div className="left">
                      <h6>{formatDate(match.date)}</h6>
                    </div>
                    <div className="center">
                      <div className="team-one">
                        <img
                          src={require(`./teamImages/${match.teamBlue.toUpperCase()}.webp`)}
                          className="team-image"
                          alt="teamimage"
                        ></img>
                      </div>
                      <div className="score">0 - 0</div>
                      <div className="team-two">
                        <img
                          src={require(`./teamImages/${match.teamRed.toUpperCase()}.webp`)}
                          className="team-image"
                          alt="teamimage"
                        ></img>
                      </div>
                    </div>
                    <div className="right">
                      <button
                        className={`button3 ${
                          authenticated && match.has_prediction
                            ? "disabled"
                            : ""
                        }`}
                        id="btn30"
                        onClick={() => openMatchDetails(match)}
                        disabled={authenticated && match.has_prediction}
                      >
                        {authenticated && match.has_prediction
                          ? "Voted"
                          : "Predict"}
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
            {showPagination && (
              <div className="pagination">
                <button
                  className="pagination-button"
                  onClick={() => {
                    loadPage(currentPage - 1);
                    window.scrollTo({ top: 0 });
                  }}
                  disabled={currentPage === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4 12H20M4 12L8 8M4 12L8 16"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Previous
                </button>
                <button
                  className="pagination-button"
                  onClick={() => {
                    loadPage(currentPage + 1);
                    window.scrollTo({ top: 0 });
                  }}
                  disabled={!nextPage}
                >
                  Next
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4 12H20M20 12L16 8M20 12L16 16"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            )}
          </>
        )}
      {showLoginModal && !authenticated && (
        <div className="login-modal">
          <div className="login-modal-content modal-2">
            <h2>You must be logged in to make a prediction</h2>
            <button className="button3" onClick={toggleLoginModal}>
              Close
            </button>
            <a href="/login">
              <button className="button3" onClick={toggleLoginModal}>
                Log in
              </button>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePredict;
