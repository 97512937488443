import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import {
  FaUserAlt,
  FaUserNinja,
  FaCodeBranch,
  FaLink,
  FaCalendar,
  FaUsers,
} from "react-icons/fa";
import "./proviews.css";
import Logo from "../images/LPD.png";
import CompleteProfile from "../accounts/CompleteProfile";

const StyledTable = styled.table`
  margin: 0 auto;
  border-collapse: collapse;
  width: 80%;
  font-size: 18px; /* Aumenté el tamaño de la fuente */
  background-color: rgba(0, 0, 0, 0.5);
`;

const StyledTh = styled.th`
  background-color: #24282f;
  color: #fff;
  padding: 16px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  touch-action: manipulation;
  position: relative; /* Para añadir la línea bajo el th */
  transition: all 0.3s ease; /* Animación de la línea */

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px; /* Tamaño de la línea */
    width: 0;
    background-color: #e38030; /* Color de la línea */
    transition: width 0.3s ease; /* Cambié la transición solo para 'width' */
  }

  &:hover::after {
    width: 100%; /* Mostrar la línea al pasar el mouse */
  }
`;

const StyledTd = styled.td`
  padding: 16px;
  text-align: center;
  font-size: 18px;
  color: "#fff";
  font-family: "Spiegel-Regular", sans-serif;
`;

const Icon = styled.span`
  margin-right: 6px;
`;

const CustomIcon = styled.a`
  display: block;
  font-size: 20px;
  text-align: center;
  padding: 16px; /* Incrementé el padding */
  color: white;
`;

const LoadingMessage = () => (
  <div className="loader-screen">
    <div className="loading-indicator">
      <div class="dot-spinner">
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
      </div>
    </div>
  </div>
);

const Proviews = () => {
  const [playerData, setPlayerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedHeader, setSelectedHeader] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const playersPerPage = 5;
  const [searchTermChampion, setSearchTermChampion] = useState("");
  const [searchTermPlayer, setSearchTermPlayer] = useState("");
  const [searchTermTeam, setSearchTermTeam] = useState("");
  const [searchTermRole, setSearchTermRole] = useState("");
  const [championsInfo, setChampionsInfo] = useState({});
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [selectedRole, setSelectedRole] = useState("");

  const [sortOrders, setSortOrders] = useState({});
  const [sessionExpired, setSessionExpired] = useState("");
  const [permissionError, setPermissionError] = useState("");

  const roleValues = ["TOP", "JUNGLE", "MID", "ADC", "SUPPORT"];
  const accessToken = localStorage.getItem("accessToken");
  const [isProfileComplete, setIsProfileComplete] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!accessToken) {
        setIsProfileComplete(false);
        return;
      }
  
      setLoading(true);
  
      try {
        // Fetch user profile
        const userProfileResponse = await axios.get(
          "https://leagueprodatabackend.vercel.app/api/user/profile/",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
  
        const { country, birth_date, favorite_player, favorite_team, discord_user } = userProfileResponse.data;
        const isComplete =
          country && country.trim() &&
          birth_date && birth_date.trim() &&
          favorite_player && favorite_player.trim() &&
          favorite_team && favorite_team.trim() &&
          discord_user && discord_user.trim();
          
        setIsProfileComplete(isComplete);
  
        if (!isComplete) {
          setLoading(false);
          return;
        }
  
        // Fetch data
        const versionsResponse = await axios.get(
          "https://ddragon.leagueoflegends.com/api/versions.json"
        );
        const latestVersion = versionsResponse.data[0];
  
        const championsResponse = await axios.get(
          `https://ddragon.leagueoflegends.com/cdn/${latestVersion}/data/en_US/champion.json`
        );
        const championsData = championsResponse.data.data;
  
        setChampionsInfo(championsData);
        setVersions(versionsResponse.data);
        setSelectedVersion(latestVersion);
  
        const proviewsResponse = await axios.get(
          "https://leagueprodatabackend.vercel.app/api/proviews-lpl-paid/",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
  
        if (proviewsResponse.status === 200) {
          const allPlayers = proviewsResponse.data || [];
  
          const sortedPlayers = allPlayers.sort((a, b) => {
            const dateA = parseDateString(a.date);
            const dateB = parseDateString(b.date);
            return dateB - dateA;
          });
  
          const playersWithRanks = sortedPlayers.map((player, index) => ({
            ...player,
            rank: index + 1,
          }));
  
          setPlayerData(playersWithRanks);
        } else {
          console.error("Unexpected response:", proviewsResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error.response?.status);
        if (error.response?.status === 403) {
          setPermissionError(true);
        }
  
        if (error.response?.status === 401) {
          setSessionExpired(true);
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [accessToken]);
  
  if (!accessToken) {
    return (
      <div className="proviews-view">
        <h1
          className="font-main"
          style={{ textAlign: "center", color: "#fff" }}
        >
          Please log in to access this content
        </h1>
      </div>
    );
  }

  if (sessionExpired) {
    return (
      <div className="proviews-view">
        <h1
          className="font-main"
          style={{ textAlign: "center", color: "#fff" }}
        >
          Your session has expired. Please log in again.
        </h1>
      </div>
    );
  }

  if (permissionError) {
    return (
      <div className="proviews-view">
        <img src={Logo} alt="Logo" className="login-logo" />
        <h1 className="font-main login-message">
          Please subscribe to Patreon to access this content
        </h1>
        <button
          className="subscribe-button"
          onClick={() => window.open("https://www.patreon.com/MoleculeLoL")}
        >
          Subscribe to Patreon
        </button>
      </div>
    );
  }

  if (isProfileComplete === false) {
    return (
      <div className="proviews-view">
        <CompleteProfile />
      </div>
    );
  }

  // Helper function to get the champion photo URL
  const getChampionPhotoURL = (championId) => {
    const championObject = Object.values(championsInfo).find(
      (champion) => champion.key === championId.toString()
    );

    if (championObject) {
      return `https://ddragon.leagueoflegends.com/cdn/${selectedVersion}/img/champion/${championObject.id}.png`;
    } else {
      // Handle the case where the champion object is not found
      return ""; // or provide a default URL
    }
  };

  // Helper function to get the champion photo Alt
  const getChampionAlt = (championId) => {
    const championObject = Object.values(championsInfo).find(
      (champion) => champion.key === championId.toString()
    );

    if (championObject) {
      return championObject.id;
    } else {
      // Handle the case where the champion object is not found
      return ""; // or provide a default URL
    }
  };

  const parseDateString = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return new Date(year, month - 1, day); // Month is zero-based in JavaScript Dates
  };

  const parseKDA = (kdaString) => {
    const [kills, deaths, assists] = kdaString.split("/").map(Number);
    return { kills, deaths, assists, kda: (kills + assists) / deaths };
  };

  const handleSort = (field) => {
    const newOrder = sortOrders[field] === "asc" ? "desc" : "asc";
    const updatedSortOrders = { ...sortOrders, [field]: newOrder };
    const stringFields = ["player", "role", "teamBlue", "teamRed", "patch"];
    const sorted = [...playerData].sort((a, b) => {
      if (field === "date") {
        const dateA = parseDateString(a[field]);
        const dateB = parseDateString(b[field]);
        return newOrder === "asc" ? dateA - dateB : dateB - dateA;
      }
      if (stringFields.includes(field)) {
        return newOrder === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field]);
      }
      if (field === "champion" || field === "enemyChampion") {
        return newOrder === "asc"
          ? getChampionAlt(a[field]).localeCompare(getChampionAlt(b[field]))
          : getChampionAlt(b[field]).localeCompare(getChampionAlt(a[field]));
      }
      if (field === "kda") {
        const kdaA = parseKDA(a[field]);
        const kdaB = parseKDA(b[field]);
        return newOrder === "asc" ? kdaA - kdaB : kdaB - kdaA;
      }
      if (a[field] === b[field]) {
        const secondaryField = field === "date" ? "patch" : "date";
        return sortOrders[secondaryField] === "asc"
          ? a[secondaryField] - b[secondaryField]
          : b[secondaryField] - a[secondaryField];
      }
      const orderMultiplier = newOrder === "asc" ? 1 : -1;
      return (a[field] - b[field]) * orderMultiplier;
    });

    const isSameAsCurrent =
      JSON.stringify(playerData) === JSON.stringify(sorted);

    if (isSameAsCurrent) {
      updatedSortOrders[field] = newOrder === "asc" ? "desc" : "asc";
      sorted.reverse();
    }
    setPlayerData(sorted);
    setSortOrders(updatedSortOrders);
  };

  const filteredPlayers = playerData.filter(
    (player) =>
      (getChampionAlt(player.champion)
        .toLowerCase()
        .includes(searchTermChampion) ||
        getChampionAlt(player.enemyChampion)
          .toLowerCase()
          .includes(searchTermChampion)) &&
      player.player.toLowerCase().includes(searchTermPlayer) &&
      (player.teamBlue.toUpperCase().includes(searchTermTeam) ||
        player.teamRed.toUpperCase().includes(searchTermTeam)) &&
      player.role.toLowerCase().includes(searchTermRole)
  );

  const indexOfLastPlayer = currentPage * playersPerPage;
  const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
  const currentPlayers = filteredPlayers.slice(
    indexOfFirstPlayer,
    indexOfLastPlayer
  );

  // Cambia a la página Next
  const nextPage = () => {
    if (indexOfLastPlayer < playerData.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Cambia a la página Back
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleSearchChampion = (event) => {
    setSearchTermChampion(event.target.value.toLowerCase()); // Actualiza el término de búsqueda en minúsculas
    setCurrentPage(1); // Reinicia a la primera página al iniciar una nueva búsqueda
  };

  const handleSearchPlayer = (event) => {
    setSearchTermPlayer(event.target.value.toLowerCase()); // Actualiza el término de búsqueda en minúsculas
    setCurrentPage(1); // Reinicia a la primera página al iniciar una nueva búsqueda
  };

  const handleSearchTeam = (event) => {
    setSearchTermTeam(event.target.value.toUpperCase()); // Actualiza el término de búsqueda en minúsculas
    setCurrentPage(1); // Reinicia a la primera página al iniciar una nueva búsqueda
  };

  const handleSearchRole = (role) => {
    if (selectedRole === role) {
      setSelectedRole("");
      setSearchTermRole("");
    } else {
      setSearchTermRole(role); // Actualiza el término de búsqueda en minúsculas
      setSelectedRole(role);
    }
    setCurrentPage(1); // Reinicia a la primera página al iniciar una nueva búsqueda
  };

  return (
    <div className="proviews">
      {loading ? (
        <LoadingMessage />
      ) : isProfileComplete === false ? (
        <CompleteProfile />
      ) : (
        <>
          <h1
            className="font-main"
            style={{
              textAlign: "center",
              fontSize: "28px",
              marginBottom: "50px",
              marginTop: "50px",
              textTransform: "uppercase",
              color: "#fff",
            }}
          >
            PROVIEWS 2024 LEAGUE PRO DATA
          </h1>
          <div className="categories searchBarContainerProviews">
            <input
              type="text"
              placeholder="Champion"
              value={searchTermChampion}
              onChange={handleSearchChampion}
              className="searchBarProviews"
            />
            <input
              type="text"
              placeholder="Player"
              value={searchTermPlayer}
              onChange={handleSearchPlayer}
              className="searchBarProviews"
            />
            <input
              type="text"
              placeholder="Team"
              value={searchTermTeam}
              onChange={handleSearchTeam}
              className="searchBarProviews"
            />
            <div className="extra-category">
              <div className="extra-category-filter">
                <div className="role-buttons">
                  {roleValues.map((role, index) => (
                    <button
                      key={index}
                      value={role}
                      onClick={() => handleSearchRole(role.toLowerCase())}
                      className={
                        selectedRole === role.toLowerCase()
                          ? "selected-role-button"
                          : ""
                      }
                    >
                      <img
                        src={require(`../images/${role.toLowerCase()}.png`)}
                        alt={role}
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <StyledTable>
            <thead>
              <tr>
                <StyledTh
                  className={
                    selectedHeader === "date"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("date")}
                  onClick={() => handleSort("date")}
                >
                  <Icon>
                    <FaCalendar />
                  </Icon>
                  Date
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "patch"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("patch")}
                  onClick={() => handleSort("patch")}
                >
                  <Icon>
                    <FaCodeBranch />
                  </Icon>
                  Patch
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "gameNumber"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("gameNumber")}
                  onClick={() => handleSort("gameNumber")}
                >
                  <Icon>
                    <FaCodeBranch />
                  </Icon>
                  Game
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "player"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("player")}
                  onClick={() => handleSort("player")}
                >
                  <Icon>
                    <FaUserAlt />
                  </Icon>
                  Player
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "Enemy Player"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("Enemy Player")}
                  onClick={() => handleSort("Enemy Player")}
                >
                  <Icon>
                    <FaUserAlt />
                  </Icon>
                  Enemy Player
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "role"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("role")}
                  onClick={() => handleSort("role")}
                >
                  Role
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "kda"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("kda")}
                  onClick={() => handleSort("kda")}
                >
                  KDA
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "teamBlue"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("teamBlue")}
                  onClick={() => handleSort("teamBlue")}
                >
                  <Icon>
                    <FaUsers />
                  </Icon>
                  Team Blue
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "teamRed"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("teamRed")}
                  onClick={() => handleSort("teamRed")}
                >
                  <Icon>
                    <FaUsers />
                  </Icon>
                  Team Red
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "champion"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("champion")}
                  onClick={() => handleSort("champion")}
                >
                  <Icon>
                    <FaUserNinja />
                  </Icon>
                  Champ
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "enemyChampion"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("enemyChampion")}
                  onClick={() => handleSort("enemyChampion")}
                >
                  <Icon>
                    <FaUserNinja />
                  </Icon>
                  Enemy
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "vod"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("vod")}
                  onClick={() => handleSort("vod")}
                >
                  VOD
                </StyledTh>
              </tr>
            </thead>
            <tbody>
              {currentPlayers.map((player, index) => (
                <tr key={index}>
                  <StyledTd
                    className={
                      player.win
                        ? "winner"
                        : player.win === false
                        ? "loser"
                        : ""
                    }
                    rank={player.rank}
                    field="date"
                  >
                    {player.date}
                  </StyledTd>
                  <StyledTd rank={player.rank} field="patch">
                    {player.patch}
                  </StyledTd>
                  <StyledTd rank={player.rank} field="patch">
                    {player.gameNum}
                  </StyledTd>
                  <StyledTd rank={player.rank} field="player">
                    {player.player}
                  </StyledTd>
                  <StyledTd rank={player.rank} field="player">
                    {player.enemyPlayer}
                  </StyledTd>
                  <StyledTd rank={player.rank} field="role">
                    {player.role}
                  </StyledTd>
                  <StyledTd rank={player.rank} field="kda">
                    {player.kda}
                  </StyledTd>
                  <StyledTd rank={player.rank} field="teamBlue">
                    <img
                      src={require(`../images/teamImages/${player.teamBlue.toUpperCase()}.webp`)}
                      className="team-image"
                      alt="teamimage"
                    ></img>
                  </StyledTd>
                  <StyledTd rank={player.rank} field="teamRed">
                    <img
                      src={require(`../images/teamImages/${player.teamRed.toUpperCase()}.webp`)}
                      className="team-image"
                      alt="teamimage"
                    ></img>
                  </StyledTd>
                  <StyledTd rank={player.rank} field="champion">
                    <img
                      src={getChampionPhotoURL(player.champion)}
                      alt={getChampionAlt(player.champion)}
                      width="40"
                      height="40"
                    />
                  </StyledTd>
                  <StyledTd rank={player.rank} field="enemyChampion">
                    <img
                      src={getChampionPhotoURL(player.enemyChampion)}
                      alt={getChampionAlt(player.enemyChampion)}
                      width="40"
                      height="40"
                    />
                  </StyledTd>
                  <StyledTd rank={player.rank} field="link">
                    <CustomIcon href={player.url} target="_blank">
                      <FaLink />
                    </CustomIcon>
                  </StyledTd>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      )}
      <div className="buttons-pagination">
        <button
          className="button-pag"
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Back
        </button>
        <button
          className="button-pag"
          onClick={nextPage}
          disabled={indexOfLastPlayer >= playerData.length}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Proviews;
