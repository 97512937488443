import "./login.css";
import React, { useState } from 'react';
import Logo from '../images/LPD.png';
import axios from 'axios';

const PasswordRecoveryForm = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleRecoverPassword = async (e) => {
    e.preventDefault();

    try {
      // Enviar la solicitud POST al endpoint de restablecimiento de contraseña
      const response = await axios.post('https://leagueprodatabackend.vercel.app/api/password-reset/request/', { email });

      // Verificar el estado de la respuesta
      if (response.status === 200) {
        setSuccessMessage('Password recovery request successful');
        setErrorMessage('');
      } else {
        setSuccessMessage('');
        setErrorMessage('Error recovering password. Please try again.');
        // También puedes agregar lógica para notificar al administrador aquí
        // Puedes hacer una nueva solicitud POST al administrador o enviar un correo electrónico
      }
    } catch (error) {
      // Manejar cualquier error de la solicitud
      console.error('Error recovering password:', error);
      setSuccessMessage('');
      setErrorMessage('Error recovering password. Please try again.');
      // También puedes agregar lógica para notificar al administrador aquí
    }
  };

  return (
    <div className="login-box">
      <div className="login-header">
        <img src={Logo} alt="Logo" className="login-logo" />
        <h2>Password Recovery</h2>
      </div>
      <form onSubmit={handleRecoverPassword}>
        <div className="input-container">
          <label className="account-label" htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="loginButton"
          />
        </div>
        <button className="loginButton loginButtonColor" type="submit">
          Recover Password
        </button>
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </form>
    </div>
  );
};

export default PasswordRecoveryForm;
