import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import axios from "axios";
import "./Navbar.css";
import About from "./About";
import Hero from "./Hero";
import HomeSection from "./HomeSection";
import Predictions from "./lolpredict/Predictions";
import Login from "../accounts/LoginForm";
import Register from "../accounts/RegisterForm";
import Home from "../home/Home";
import InhouseNorte from "../inhouses/InhouseRanking";
import Proviews from "../proviews/Proviews";
import { verify_refresh_token, verifyTokenExpired } from "../utils/tokenUtils"; // Asegúrate de importar la función correcta
import PasswordRecovery from "../accounts/passwordRecovery";
import PasswordResetForm from "../accounts/passwordRecoveryForm";
import AccountActivated from "../accounts/accountActivated";
import ProviewsPaid from "../proviews/proviewsPaid";
import LLAMVPS from "../isurus/LLAMVPS";
import DiscordLinkedSuccess from "../accounts/DiscordLinkedSuccess";
import LinkDiscord from "../accounts/LinkDiscord";
import UpdateProfile from "../accounts/UpdateProfileForm";
import CompleteProfile from "../accounts/CompleteProfile";
import PrivacyPolicy from "../home/PrivacyPolicy";

function NavbarScript() {
  useEffect(() => {
    const dropdownBtn = document.querySelectorAll(".dropdown-btn");
    const dropdown = document.querySelectorAll(".dropdown");
    const hamburgerBtn = document.getElementById("hamburger");
    const navMenu = document.querySelector(".menu");
    const links = document.querySelectorAll(".dropdown a");

    function setAriaExpandedFalse() {
      dropdownBtn.forEach((btn) => btn.setAttribute("aria-expanded", "false"));
    }

    function closeDropdownMenu() {
      dropdown.forEach((drop) => {
        drop.classList.remove("active");
        drop.addEventListener("click", (e) => e.stopPropagation());
      });
    }

    function toggleHamburger() {
      navMenu.classList.toggle("show");
    }

    dropdownBtn.forEach((btn) => {
      btn.addEventListener("click", function (e) {
        const dropdownIndex = e.currentTarget.dataset.dropdown;
        const dropdownElement = document.getElementById(dropdownIndex);

        dropdownElement.classList.toggle("active");
        dropdown.forEach((drop) => {
          if (drop.id !== btn.dataset["dropdown"]) {
            drop.classList.remove("active");
          }
        });
        e.stopPropagation();
        btn.setAttribute(
          "aria-expanded",
          btn.getAttribute("aria-expanded") === "false" ? "true" : "false"
        );
      });
    });

    // close dropdown menu when the dropdown links are clicked
    links.forEach((link) =>
      link.addEventListener("click", () => {
        closeDropdownMenu();
        setAriaExpandedFalse();
        toggleHamburger();
      })
    );

    // close dropdown menu when you click on the document body
    document.documentElement.addEventListener("click", () => {
      closeDropdownMenu();
      setAriaExpandedFalse();
    });

    // close dropdown when the escape key is pressed
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        closeDropdownMenu();
        setAriaExpandedFalse();
      }
    });

    // toggle hamburger menu
    hamburgerBtn.addEventListener("click", toggleHamburger);
  }, []); // El segundo argumento [] asegura que esto se ejecute una vez al montar el componente

  return null; // Este componente no renderiza nada en la interfaz
}

const Navbar = ({ onNavbarLoad }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
  };

  useEffect(() => {
    const checkToken = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const currentPath = window.location.pathname;

      const isLogin = currentPath === "/predictions";

      if (!accessToken && isLogin) {
        window.location.href = "/login";
      }

      const isTokenExpired = verifyTokenExpired(accessToken);

      if (isTokenExpired) {
        const refreshedToken = await verify_refresh_token();

        if (!refreshedToken && isLogin) {
          window.location.href = "/login";
        }
      }

      try {
        const authResponse = await axios.post(
          "https://leagueprodatabackend.vercel.app/api/get_session_info/",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        const authData = authResponse.data;
        setAuthenticated(authData.authenticated);
        setUsername(authData.username);
        setLoading(false);
        onNavbarLoad();
      } catch (error) {
        console.error("Error fetching authentication data:", error);
        setLoading(false);
      }
    };

    checkToken();
  }, []);
  // Si los datos ya están disponibles en el almacenamiento local, evita el estado de "loading"
  if (!loading) {
    return (
      <>
        <div className="container-main">
          <header id="nav-menu" aria-label="navigation bar">
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
            />
            <link
              href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css"
              rel="stylesheet"
            />
            <div class="container-nav">
              <div class="nav-start">
                <a class="logo" href="/">
                  <img
                    src={require("../images/LOGO.webp")}
                    width="180"
                    height="40"
                    alt="Logo"
                  />
                </a>
                <nav class="menu">
                  <ul class="menu-bar">
                    <li>
                      <button
                        class="nav-link dropdown-btn"
                        data-dropdown="dropdown1"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-label="browse"
                      >
                        Browse
                        <i class="bx bx-chevron-down" aria-hidden="true"></i>
                      </button>
                      <div id="dropdown1" class="dropdown">
                        <ul role="menu">
                          <li role="menuitem">
                            <a class="dropdown-link" href="/predictions">
                              <img
                                src={require("../images/worldsblack.png")}
                                className="icon"
                                width="20px"
                                height="18px"
                                alt="worlds"
                              />
                              <div>
                                <span class="dropdown-link-title">
                                  Predictions
                                </span>
                                <p>Worlds Predictions</p>
                              </div>
                            </a>
                          </li>
                          <li role="menuitem">
                            <a class="dropdown-link" href="/Proviews-2024">
                              <img
                                src={require("../images/LPLPROVIEWS.webp")}
                                className="icon"
                                width="20px"
                                height="18px"
                                alt="lpllogo"
                              />
                              <div>
                                <span class="dropdown-link-title">
                                  Proviews
                                </span>
                                <p>POV Players LPL 2024</p>
                              </div>
                            </a>
                          </li>
                          <li role="menuitem">
                            <a class="dropdown-link" href="/ranking">
                              <img
                                src={require("../images/worldsblack.png")}
                                className="icon"
                                width="20px"
                                height="18px"
                                alt="worlds"
                              />
                              <div>
                                <span class="dropdown-link-title">
                                  Worlds 2024 Ranking
                                </span>
                                <p>Worlds 2024 Pro Player SoloQ Ranking</p>
                              </div>
                            </a>
                          </li>
                        </ul>
                        <ul role="menu">
                          <li class="dropdown-title">
                            <span class="dropdown-link-title">
                              Browse by apps
                            </span>
                          </li>
                          <li role="menuitem">
                            <a class="dropdown-link" href="/Predictions">
                              <img
                                className="iconApps"
                                src={require("../images/worldswhite.png")}
                                width="30px"
                                height="18px"
                                alt="predictionsicon"
                              />
                              Predictions
                            </a>
                          </li>
                          <li role="menuitem">
                            <a class="dropdown-link" href="/Proviews">
                              <img
                                className="iconApps"
                                src={require("../images/LPLPROVIEWS.webp")}
                                width="30px"
                                height="18px"
                                alt="lplicon"
                              />
                              Proviews 2023
                            </a>
                          </li>
                          <li role="menuitem">
                            <a class="dropdown-link" href="/Proviews-2024">
                              <img
                                className="iconApps"
                                src={require("../images/LPLPROVIEWS.webp")}
                                width="30px"
                                height="18px"
                                alt="lplicon"
                              />
                              Proviews 2024
                            </a>
                          </li>
                          <li role="menuitem">
                            <a class="dropdown-link" href="/ranking">
                              <img
                                className="iconApps"
                                src={require("../images/worldswhite.png")}
                                width="20px"
                                height="18px"
                                alt="worlds"
                                class="icon"
                              />
                              Worlds Ranking
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a class="nav-link" href="/ranking">
                        Leagues
                      </a>
                    </li>
                    <li>
                      <a class="nav-link" href="/ranking/worlds">
                        Worlds 2024 Ranking
                      </a>
                    </li>
                    <li>
                      <a class="nav-link" href="/Proviews-2024">
                        Proviews 2024
                      </a>
                    </li>

                    {authenticated ? (
                      <>
                        <li>
                          <a
                            className="dropdown-link mobile-only"
                            href="/update-profile"
                          >
                            <img
                              src={require("../images/user.webp")}
                              className="icon"
                              width="20px"
                              height="18px"
                              alt="user"
                            />
                            <div>
                              <span className="dropdown-link-title">
                                {username}
                              </span>
                              <p>Update Profile</p>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            aria-label="Logout"
                            class="mobile-only nav-link"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLogout();
                            }}
                          >
                            Logout
                          </a>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <a
                            href="/login"
                            aria-label="Login"
                            class="mobile-only nav-link"
                          >
                            Login
                          </a>
                        </li>
                        <li>
                          <a
                            href="/register"
                            aria-label="Register"
                            class="mobile-only nav-link"
                          >
                            Register
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </nav>
              </div>
              <div class="nav-end">
                <div className="right-container">
                  {authenticated ? (
                    <>
                      <a className="dropdown-link" href="/update-profile">
                        <img
                          src={require("../images/user.webp")}
                          className="icon"
                          width="20px"
                          height="18px"
                          alt="user"
                        />
                        <div>
                          <span className="dropdown-link-title">
                            {username}
                          </span>
                          <p>Update Profile</p>
                        </div>
                      </a>{" "}
                      <button
                        className="btn btn-primary"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </>
                  ) : (
                    <>
                      <a aria-label="logout" href="/login">
                        <button className="btn btn-primary">Login</button>
                      </a>
                      <a aria-label="logout" href="/register">
                        <button className="btn btn-primary">Register</button>
                      </a>
                    </>
                  )}
                  <a
                    href="https://discord.gg/mgzzzg8AEb"
                    aria-label="Join to Discord"
                  >
                    <button className="btn btn-primary">
                      Join Discord Server
                    </button>
                  </a>
                </div>
                <button
                  id="hamburger"
                  aria-label="hamburger"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="bx bx-menu" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </header>
          <div class="content-wrapper">
            <div class="wrapper">
              <Routes>
                <Route path="/ranking" element={<HomeSection />} />
                <Route path="/about" element={<About />} />
                <Route path="/ranking/:league" element={<Hero />} />
                <Route path="/predictions/*" element={<Predictions />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<PasswordRecovery />} />
                <Route path="/" element={<Home />} />
                <Route path="/Proviews" element={<Proviews />} />
                <Route
                  path="/reset-password/:uidb64/:token"
                  element={<PasswordResetForm />}
                />
                <Route
                  path="/account-activated/"
                  element={<AccountActivated />}
                />
                <Route path="/Proviews-2024/" element={<ProviewsPaid />} />
                <Route path="/Link-Discord/" element={<LinkDiscord />} />
                <Route
                  path="/Discord-Success/"
                  element={<DiscordLinkedSuccess />}
                />
                <Route path="/update-Profile/" element={<UpdateProfile />} />
                <Route path="/content-access/" element={<CompleteProfile />} />
                <Route path="/privacy-policy/" element={<PrivacyPolicy />} />

                
              </Routes>
            </div>
          </div>
        </div>
        <NavbarScript />{" "}
      </>
    );
  }

  return (
    <div className="loader-screenNavBar">
      <div className="loading-indicator">
        <div className="dot-spinner">
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
