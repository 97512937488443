import React from 'react'

const About = () => {
  return (
    <div className='about-container'>
      <div className='about-section'>
        <div className='about-title-container'>
          <h1 className='about-title black-color'>Our Team</h1>
        </div>
        <div className='team-wrapper'>
          <div className='team-wrapper-title-container'>
            <button data-text="Awesome" className="wrapper-title">
              <span className="actual-text">&nbsp;Founders&nbsp;</span>
              <span className="hover-text" aria-hidden="true">&nbsp;Founders&nbsp;</span>
            </button>
          </div>
          <div className='team-container'>
            <div className='team-card'>
              <img src={require('../images/deam.jpeg')} className='about-avatar' alt='team-member'></img>
              <h2>Deam147</h2>
              <h2>CEO / Full-Stack Developer</h2>
            </div>
            <div className='team-card'>
              <img src={require('../images/xura.png')} className='about-avatar' alt='team-member'></img>
              <h2>Xuradel</h2>
              <h2>Full-Stack Developer</h2>
            </div>
            <div className='team-card'>
              <img src={require('../images/guzH.jpg')} className='about-avatar' alt='team-member'></img>
              <h2>GuzH </h2>
              <h2>Back-End Developer</h2>
            </div>
          </div>
          <span className='fancy' ></span>
          <h1 className='about-title'>About us</h1>
          <h2 className='about-text'>League Pro Data is your go-to destination for comprehensive analysis of competitive and solo queue games in the world of League of Legends. We are dedicated to providing pro players and enthusiasts with valuable insights, solo queue rankings, and access to LPL (League of Legends Pro League) VOD Proviews.<br />
            League Pro Data is committed to fostering a thriving community of passionate League of Legends players. Whether you're a pro player looking to refine your skills or a fan eager to immerse yourself in the world of esports, we're here to support your journey.
          </h2>

        </div>
      </div>
    </div>
  )
}

export default About
