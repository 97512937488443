import React from 'react'

const Footer = () => {
    return (
        <footer>
            <div class="footer">
                <div class="row">
                    <a href="https://twitter.com/LeagueProData" aria-label="Twitter"><i class="fa-brands fa-x-twitter"></i></a>
                    <a href="https://discord.gg/mgzzzg8AEb" aria-label="Join to Discord"><i class="fa-brands fa-discord"></i></a>
                </div>
                <div class="row">
                    <a href="/privacy-policy" aria-label="Privacy Policy"> © 2024 League Pro Data - Private Policy</a>
                </div>
                <div class="row">
                    League Pro Data isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends. League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc..
                </div>
            </div>
        </footer>
    )
}

export default Footer
