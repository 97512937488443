import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch, faXTwitter , faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import LeaguesItem from "./LeaguesItem";
import "./custom-leagues.css";

const LeagueCard = ({ leagueName, image, twitter, twitch, instagram, creatorName, onClick }) => (
  <div className="cl-league-card">
    <img src={image} alt={`${creatorName} avatar`} className="cl-league-avatar" />
    <h3 className="cl-league-name">{creatorName}</h3>
    <div className="cl-league-socials">
      {twitch && (
        <a href={twitch} target="_blank" rel="noopener noreferrer" aria-label="Twitch">
          <FontAwesomeIcon icon={faTwitch} />
        </a>
      )}
      {twitter && (
        <a href={twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
      )}
      {instagram && (
        <a href={instagram} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      )}
    </div>
    <button className="cl-more-button" onClick={() => onClick(leagueName)}>
      More <FontAwesomeIcon icon={faChevronRight} />
    </button>
  </div>
);

const CustomLeagues = () => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showLeaguesItem, setShowLeaguesItem] = useState(false);
  const [selectedName, setSelectedName] = useState("");

  useEffect(() => {
    const fetchLeagues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://leagueprodatabackend.vercel.app/api/get_all_custom_leagues/?page=${currentPage}&page_size=6`
        );
        setLeagues(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 6));
      } catch (error) {
        console.error("Error fetching leagues:", error);
      }
      setLoading(false);
    };

    fetchLeagues();
  }, [currentPage]);

  const handleMoreClick = (name) => {
    setSelectedName(name);
    setShowLeaguesItem(true);
  };

  const handleBackClick = () => {
    setShowLeaguesItem(false);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  if (loading) {
    return (
      <div className="cl-loading">
        <div className="cl-loading-spinner"></div>
        <p>Loading leagues...</p>
      </div>
    );
  }

  if (showLeaguesItem) {
    return <LeaguesItem onBackClick={handleBackClick} name={selectedName} />;
  }

  return (
    <div className="cl-custom-leagues">
      <h1 className="cl-title">Custom Leagues</h1>
      <p className="cl-subtitle">Join your favorite Content Creator!</p>
      <div className="cl-leagues-grid">
        {leagues.map((league) => (
          <LeagueCard key={league.leagueName} {...league} onClick={handleMoreClick} />
        ))}
      </div>
      <div className="cl-pagination">
        <button className="cl-pagination-button" onClick={handlePrevPage} disabled={currentPage === 1}>
          <FontAwesomeIcon icon={faChevronLeft} /> Previous
        </button>
        <button className="cl-pagination-button" onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default CustomLeagues;