import "./login.css";
import React, { useState } from 'react';
import { getToken } from '../utils/tokenUtils'; // Ajusta la ruta según la estructura de tu proyecto
import Logo from '../images/LPD.png';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Llamada a tu función getToken
      const token = await getToken(username, password);

      if (token.status === 200) {
        // Inicio de sesión exitoso - puedes redirigir al usuario a otra página
        window.location.href = '/';
        // Lógica de redirección o actualización de estado tras un inicio de sesión exitoso
      } else {
        // Manejar fallo de inicio de sesión, mostrar mensaje de error
        console.error('Login failed');
        setError(token.response.data.message);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="login-box">
      <div className="login-header">
        <img src={Logo} alt="Logo" className="login-logo" />
        <h2>LOGIN</h2>
      </div>
      <form onSubmit={handleLogin}>
        <div className="input-container">
          <label className="account-label" htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="loginButton"
          />
        </div>
        <div className="input-container">
          <label className="account-label" htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="loginButton"
          />
        </div>
        <button className="loginButton loginButtonColor" type="submit">Login</button>
        {error && <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>{error}</p>}
        <p style={{ color: 'white', textAlign: 'center', marginTop: '10px' }}>
          <a href="/forgot-password" className="forgot-password">Forgot Password</a>
        </p>
      </form>
    </div>
  );
};

export default LoginForm;
