import React from 'react'
import Accounts from './Accounts'



const Hero = () => {

    return (
        <body>
            <div className='hero-section'>
                <Accounts></Accounts>
            </div>
        </body>
    )
}

export default Hero
