import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const PasswordResetForm = () => {
  const { uidb64, token } = useParams();
  const navigate = useNavigate(); // Hook de navegación
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      setSuccessMessage(''); // Clear success message if there's an error
      return;
    }

    try {
      await axios.post(`https://leagueprodatabackend.vercel.app/api/password-reset/confirm/${uidb64}/${token}/`, {
        new_password: newPassword,
      });

      setSuccessMessage('Password reset successfully. Please log in.');
      setError(''); // Clear error message if successful

      // Redirigir al usuario a la página de inicio de sesión después de 2 segundos
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      console.error('Error resetting password:', error.response?.data?.message);
      setSuccessMessage(''); // Clear success message if there's an error
      setError(error.response?.data?.message || 'An error occurred.'); // Set error message
    }
  };

  return (
    <div className="login-box">
      <div className="login-header">
        <h2>Reset Password</h2>
      </div>
      <form onSubmit={handleResetPassword}>
        <div className="input-container">
          <label className="account-label" htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="loginButton"
          />
        </div>
        <div className="input-container">
          <label className="account-label" htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="loginButton"
          />
        </div>
        <button className="loginButton loginButtonColor" type="submit">
          Set New Password
        </button>
        {error && <p style={{ color: 'red', textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>{error}</p>}
        {successMessage && (
          <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
            <p style={{ color: 'green' }}>{successMessage}</p>
            <button 
              style={{ marginTop: '10px' }}
              onClick={() => navigate('/login')}
            >
              Go to Login
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default PasswordResetForm;
