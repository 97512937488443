import React, { useState } from "react";
import axios from "axios";
import "./loginPrompt.css"; // Usa el mismo archivo CSS

const LinkDiscordPrompt = () => {
  const [isLinking, setIsLinking] = useState(false);
  const [error, setError] = useState("");

  const handleLinkDiscord = async () => {
    setIsLinking(true);
    try {
      // Solicita la URL de autenticación de Discord desde el backend
      const response = await axios.post(
        "https://leagueprodatabackend.vercel.app/link-discord/",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      // Obtén la URL de autenticación de Discord del backend
      const discordAuthUrl = response.data.discord_auth_url;
      window.location.href = discordAuthUrl;
    } catch (error) {
      setError("Error linking Discord. Please try again.");
      setIsLinking(false);
    }
  };

  return (
    <div className="wrapper-prompt">
      <div className="login-prompt">
        <h2>Please link your Discord account to continue.</h2>
        <button
          onClick={handleLinkDiscord}
          disabled={isLinking}
          className="loginButtonPrompt loginButtonColor"
        >
          {isLinking ? "Linking..." : "Link Discord"}
        </button>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default LinkDiscordPrompt;
