import React, { useState, useEffect } from "react";
import "./Predictions.css";
import HomePredict from "./HomePredict";
import AccountPredict from "./AccountPredict";
import Vote from "./Vote";
import "./Predictions.css";
import axios from "axios";
import Leagues from "./Leagues";
import Info from "./Info";
import Prizes from "./Prizes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";

const Predictions = () => {
  // Initialize state to manage the selected option
  const [selectedOption, setSelectedOption] = useState("home");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [username, setUsername] = useState("");
  const [userCount, setUserCount] = useState(0); // Nuevo estado para el conteo de usuarios
  const [showPrizes, setShowPrizes] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Realizar una solicitud a la API para obtener la información del usuario autenticado
        const authResponse = await axios.post(
          "https://leagueprodatabackend.vercel.app/api/get_session_info/",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        // Extraer el nombre de usuario de la respuesta
        const authData = authResponse.data;
        setAuthenticated(authData.authenticated);
        setUsername(authData.username);

        // Una vez que se ha obtenido la información de autenticación, puedes establecer loading en falso
        setLoading(false);
      } catch (error) {
        console.error(
          "Error al obtener la información de autenticación o las predicciones: ",
          error
        );
        setLoading(false);
      }
    };

    fetchData();
    // Obtén el valor del parámetro "section" de la URL
    const params = queryString.parse(window.location.search);
    // Utiliza el operador condicional para establecer la opción predeterminada en "home" si no se proporciona ningún parámetro
    setSelectedOption(params.section || "home");
  }, []);

  useEffect(() => {
    // Tu código existente para obtener la información del usuario autenticado

    // Agrega una nueva solicitud para obtener el conteo de usuarios
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(
          "https://leagueprodatabackend.vercel.app/api/user_count/"
        );
        setUserCount(response.data.user_count); // Almacena el conteo en el estado
      } catch (error) {
        console.error("Error al obtener el conteo de usuarios: ", error);
      }
    };

    fetchUserCount();
  }, []);

  const togglePrizes = () => {
    setShowPrizes(!showPrizes);
  };

  const handleOptionClick = (option) => {
    if (option === "prizes") {
      setShowPrizes(false);
    } else {
      setShowPrizes(true);
    }
    setSelectedOption(option);
  };
  // Function to handle checkbox toggle
  const handleCheckboxToggle = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };
  return (
    <>
      <div className="predictions">
        <div
          className={`sidebar ${isCheckboxChecked ? "sidebar-collapsed" : ""}`}
        >
          <div className="sidebar-title">
            <img
              className={`${isCheckboxChecked ? "hidden" : ""}`}
              src={require("./data/worlds_icon_white.png")}
            ></img>
            <button
              class="button10"
              checked={isCheckboxChecked}
              onClick={handleCheckboxToggle}
            >
              <div class="button-box">
                <span class="button-elem">
                  <svg
                    className={`${isCheckboxChecked ? "turn-direction" : ""}`}
                    xmlns="http://www.w3.org/2000/svg"
                    shape-rendering="geometricPrecision"
                    text-rendering="geometricPrecision"
                    image-rendering="optimizeQuality"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    viewBox="0 0 319 511.61"
                  >
                    <path d="M48.92 505.72 5.88 462.68c-7.85-7.85-7.83-20.72 0-28.54l178.35-178.35L5.88 77.44c-7.85-7.85-7.8-20.73 0-28.54L48.92 5.87c7.83-7.82 20.71-7.82 28.54 0l192.25 192.26.37.36 43.04 43.03c7.82 7.84 7.86 20.69 0 28.54l-43.04 43.04-.37.36L77.46 505.72c-7.85 7.86-20.68 7.86-28.54 0z" />
                  </svg>
                </span>
              </div>
            </button>
          </div>
          <div className="sidebar-options">
            <p className={`${isCheckboxChecked ? "hidden" : ""}`}>General</p>
            <div className="option">
              <button
                onClick={() => handleOptionClick("home")}
                className="button-option"
              >
                <img
                  src={require("./lolpredict/icons8-home-48.png")}
                  className="option-icon"
                  alt="home"
                />
                <p className={`${isCheckboxChecked ? "hidden" : ""}`}>Home</p>
              </button>
            </div>

            <div className="option">
              <button
                onClick={() => handleOptionClick("vote")}
                className="button-option"
              >
                <img
                  src={require("./lolpredict/icons8-prediction-48.png")}
                  className="option-icon"
                  alt="predict"
                />
                <p className={`${isCheckboxChecked ? "hidden" : ""}`}>
                  Ranking
                </p>
              </button>
            </div>

            <div className="option">
              <button
                onClick={() => handleOptionClick("leagues")}
                className="button-option"
              >
                <img
                  src={require("./lolpredict/twitch.png")}
                  className="option-icon"
                  alt="leagues"
                />
                <p className={`${isCheckboxChecked ? "hidden" : ""}`}>
                  Leagues
                </p>
              </button>
            </div>
            <div className="option">
              <button
                onClick={() => handleOptionClick("info")}
                className="button-option"
              >
                <img
                  src={require("./lolpredict/info.png")}
                  className="option-icon"
                  alt="info"
                />
                <p className={`${isCheckboxChecked ? "hidden" : ""}`}>Info</p>
              </button>
            </div>
            {authenticated ? (
              <div className="option">
                <button
                  onClick={() => handleOptionClick("account")}
                  className="button-option"
                >
                  <img
                    src={require("./lolpredict/icons8-account-32.png")}
                    className="option-icon"
                    alt="account"
                  />
                  <p className={`${isCheckboxChecked ? "hidden" : ""}`}>
                    My Predictions
                  </p>
                </button>
              </div>
            ) : null}
            <p className={`credits ${isCheckboxChecked ? "hidden" : ""}`}>
              Powered by{" "}
              <a
                href="https://twitter.com/LeagueProData"
                target="_blank"
                rel="noopener noreferrer"
              >
                League Pro Data
              </a>
            </p>
          </div>
        </div>
        <div className="predictions-content">
          {/* Conditionally render components based on selectedOption */}
          {selectedOption === "home" && <HomePredict />}
          {selectedOption === "vote" && <Vote />}
          {selectedOption === "account" && <AccountPredict />}
          {selectedOption === "leagues" && <Leagues />}
          {selectedOption === "info" && <Info />}

        </div>
      </div>
    </>
  );
};

export default Predictions;
