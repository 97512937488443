import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faBookmark,
  faInfoCircle,
  faTrophy, 
  faCalendarAlt, 
  faStickyNote, 
  faGavel, 
  faGamepad,
  faTimes,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import "./leagues-item.css";

const LeagueModal = ({ show, onClose, name, reloadData }) => {
  const [creatorCode, setCreatorCode] = useState("");
  const [apiResponseMessage, setApiResponseMessage] = useState("");

  const handleJoinLeague = async () => {
    try {
      const joinData = {
        creator_code: creatorCode,
        username: localStorage.getItem("username"),
      };

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };

      const response = await axios.post(
        "https://leagueprodatabackend.vercel.app/api/join_league/",
        joinData,
        { headers }
      );

      setApiResponseMessage(response.data.detail);
      onClose();
      reloadData();
    } catch (error) {
      console.error("Error al realizar la solicitud POST:", error);
      setApiResponseMessage(
        error.response?.data?.detail || "An error occurred. Please try again."
      );
    }
  };

  if (!show) return null;

  return (
    <div className="li-modal-overlay">
      <div className="li-modal">
        <h2 className="li-modal-title">
          Do you want to join {name}'s custom league?
        </h2>
        <input
          type="text"
          onChange={(e) => setCreatorCode(e.target.value)}
          className="li-input"
          placeholder="Creator code"
        />
        <div className="li-modal-buttons">
          <button
            className="li-button li-button-primary"
            onClick={handleJoinLeague}
          >
            Join!
          </button>
          <button className="li-button li-button-secondary" onClick={onClose}>
            Close
          </button>
        </div>
        {apiResponseMessage && (
          <p className="li-message">{apiResponseMessage}</p>
        )}
      </div>
    </div>
  );
};

const LeagueInfoModal = ({ show, onClose, leagueInfo }) => {
  if (!show) return null;

  return (
    <div className="li-modal-overlay">
      <div className="li-modal li-info-modal">
        <button className="li-modal-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="li-modal-title">League Information</h2>
        <div className="li-info-content">
          <div className="li-info-item">
            <FontAwesomeIcon icon={faUser} className="li-info-icon" />
            <p><strong>Creator:</strong> {leagueInfo.creatorName}</p>
          </div>
          <div className="li-info-item">
            <FontAwesomeIcon icon={faCalendarAlt} className="li-info-icon" />
            <div>
              <p><strong>Start Date:</strong> {new Date(leagueInfo.dateStart).toLocaleDateString()}</p>
              <p><strong>End Date:</strong> {new Date(leagueInfo.dateFinish).toLocaleDateString()}</p>
            </div>
          </div>
          <div className="li-info-item">
            <FontAwesomeIcon icon={faTrophy} className="li-info-icon" />
            <div>
              <p><strong>Prizes:</strong></p>
              <ul className="li-prize-list">
                <li>1st Place: {leagueInfo.prize_1 || "Not specified"}</li>
                <li>2nd Place: {leagueInfo.prize_2 || "Not specified"}</li>
                <li>3rd Place: {leagueInfo.prize_3 || "Not specified"}</li>
              </ul>
            </div>
          </div>
          <div className="li-info-item">
            <FontAwesomeIcon icon={faStickyNote} className="li-info-icon" />
            <p><strong>Notes:</strong> {leagueInfo.notes || "No notes"}</p>
          </div>
          <div className="li-info-item">
            <FontAwesomeIcon icon={faGavel} className="li-info-icon" />
            <p><strong>Rules:</strong> {leagueInfo.rules || "No specific rules"}</p>
          </div>
          <div className="li-info-item">
            <FontAwesomeIcon icon={faGamepad} className="li-info-icon" />
            <div>
              <p><strong>Tournaments:</strong></p>
              <ul className="li-tournament-list">
                {leagueInfo.tournaments.map((tournament, index) => (
                  <li key={index}>{tournament}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="li-modal-footer">
          <button className="li-button li-button-secondary" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const LeaguesItem = ({ onBackClick, name }) => {
  const [userPoints, setUserPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [myPointsData, setMyPointsData] = useState(null);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [leagueInfo, setLeagueInfo] = useState(null);
  const pageSize = 5;

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://leagueprodatabackend.vercel.app/api/user-points-league/?league_name=${name}&page=${page}&page_size=${pageSize}`
      );
      setUserPoints(response.data.results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user points:", error);
      setLoading(false);
    }
  };

  const fetchMyPoints = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      const response = await axios.get(
        `https://leagueprodatabackend.vercel.app/api/get_my_points_league/?league_name=${name}`,
        { headers }
      );
      setMyPointsData(response.data);
    } catch (error) {
      console.error("Error fetching user points:", error);
      setMyPointsData(null);
    }
  };

  const fetchLeagueInfo = async () => {
    try {
      const response = await axios.get(
        `https://leagueprodatabackend.vercel.app/league/${name}/`
      );
      setLeagueInfo(response.data);
    } catch (error) {
      console.error("Error fetching league info:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchMyPoints();
    fetchLeagueInfo();
  }, [name, page]);

  const nextPage = useCallback(() => {
    if (userPoints.length >= pageSize) {
      setPage((prevPage) => prevPage + 1);
      window.scrollTo(0, 0);
    }
  }, [userPoints]);

  const prevPage = useCallback(() => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
      window.scrollTo(0, 0);
    }
  }, [page]);

  const reloadData = () => {
    setLoading(true);
    fetchUsers();
    fetchMyPoints();
  };

  return (
    <div className="li-container">
      <button className="li-back-button" onClick={onBackClick}>
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </button>

      {loading ? (
        <div className="li-loading">
          <div className="li-spinner"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <div className="li-header">
            <h1 className="li-title">{name.toUpperCase()} RANKING</h1>
            <div className="li-header-buttons">
              <button
                className="li-info-button"
                onClick={() => setShowInfoModal(true)}
              >
                <FontAwesomeIcon icon={faInfoCircle} /> League Info
              </button>
              <button
                className="li-join-button"
                onClick={() => setShowJoinModal(true)}
              >
                <FontAwesomeIcon icon={faBookmark} /> Join League
              </button>
            </div>
          </div>

          {myPointsData && (
            <div className="li-user-card">
              <h2>Your Position</h2>
              <div className="li-user-info">
                <span className="li-user-rank">
                  {myPointsData.user_position}
                </span>
                <span className="li-user-name">{myPointsData.username}</span>
                <span className="li-user-points">
                  {myPointsData.user_points} Points
                </span>
              </div>
            </div>
          )}

          <div className="li-ranking-list">
            <div className="li-ranking-header">
              <span>#</span>
              <span>User</span>
              <span>Points</span>
            </div>
            {userPoints.map((user, index) => (
              <div
                key={index}
                className={`li-ranking-item ${
                  index < 3 && page === 1 ? `li-top-${index + 1}` : ""
                }`}
              >
                <span className="li-rank">
                  {index + 1 + (page - 1) * pageSize}
                </span>
                <span className="li-username">
                  {user.verified && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="li-verified-icon"
                    />
                  )}
                  {user.user__username}
                  {user.verified && (
                    <a
                      href={user.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="li-twitter-link"
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                  )}
                </span>
                <span className="li-points">{user.total_points} Points</span>
              </div>
            ))}
          </div>

          <div className="li-pagination">
            <button
              onClick={prevPage}
              disabled={page === 1 || loading}
              className="li-pagination-button"
            >
              <FontAwesomeIcon icon={faChevronLeft} /> Previous
            </button>
            <button
              onClick={nextPage}
              disabled={userPoints.length < pageSize || loading}
              className="li-pagination-button"
            >
              Next <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </>
      )}

      <LeagueModal
        show={showJoinModal}
        onClose={() => setShowJoinModal(false)}
        name={name}
        reloadData={reloadData}
      />

      <LeagueInfoModal
        show={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        leagueInfo={leagueInfo}
      />
    </div>
  );
};

export default LeaguesItem;
