import React, { useState, useEffect } from "react";
import axios from "axios";
import PredictModal from "./Predict-modal";
const LoadingMessage = () => (
  <div className="loader-screen">
    <div className="loading-indicator">
      <div className="dot-spinner">
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
      </div>
    </div>
  </div>
);

function PredictionsComponent() {
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [showModal, setShowModal] = useState(false);
  const [showModalArray, setShowModalArray] = useState(
    predictions.map(() => false)
  );
  const [nextPageUrl, setNextPageUrl] = useState(null); // Track the next page URL
  const [prevPageUrl, setPrevPageUrl] = useState(null); // Track the previous page URL

  // Function to toggle the modal for a specific prediction
  const toggleModal = (index) => {
    const newShowModalArray = [...showModalArray];
    newShowModalArray[index] = !newShowModalArray[index];
    setShowModalArray(newShowModalArray);
  };

  useEffect(() => {
    const fetchData = async () => {

      const accessToken = localStorage.getItem("accessToken");

      try {
        // Realizar una solicitud a la API para obtener la información del usuario autenticado
        const authResponse = await axios.post(
          "https://leagueprodatabackend.vercel.app/api/get_session_info/",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        // Extraer el nombre de usuario de la respuesta
        const authData = authResponse.data;
        setAuthenticated(authData.authenticated);
        setUsername(authData.username);

        // Realizar una solicitud a la nueva API para obtener las predicciones del usuario autenticado
        if (authData.authenticated) {
          const postData = {
            username: authData.username,
          };

          const predictionsResponse = await axios.post(
            "https://leagueprodatabackend.vercel.app/api/get_user_predictions/",
            postData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
          setPredictions(predictionsResponse.data.results);

          // Store the next and previous page URLs
          setNextPageUrl(predictionsResponse.data.next);
          setPrevPageUrl(predictionsResponse.data.previous);
        }

        // Una vez que se ha obtenido la información de autenticación, puedes establecer loading en falso
        setLoading(false);
      } catch (error) {
        console.error(
          "Error al obtener la información de autenticación o las predicciones: ",
          error
        );
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getModifiedMatchId = (match) => {
    const parts = match.split("/");
    const modifiedMatchId = parts.length > 1 ? parts[1] : match;
    return modifiedMatchId.replace(/[-_]/g, " ");
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const loadNextPredictions = async () => {
    if (nextPageUrl) {
      try {
        setLoading(true);
        const authResponse = await axios.post(
          "https://leagueprodatabackend.vercel.app/api/get_session_info/",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        // Check if the user is still authenticated
        if (authResponse.data.authenticated) {
          const postData = {
            username: username,
          };

          // Make a POST request to load more predictions (next page)
          const response = await axios.post(nextPageUrl, postData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          });

          // Update predictions with the new data
          setPredictions(response.data.results);
          setLoading(false);
          // Store the next and previous page URLs
          setNextPageUrl(response.data.next);
          setPrevPageUrl(response.data.previous);
        }
      } catch (error) {
        console.error("Error loading next predictions:", error);
      }
    }
  };

  const loadPrevPredictions = async () => {
    if (prevPageUrl) {
      try {
        setLoading(true);
        const authResponse = await axios.post(
          "https://leagueprodatabackend.vercel.app/api/get_session_info/",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        // Check if the user is still authenticated
        if (authResponse.data.authenticated) {
          const postData = {
            username: username,
          };

          // Make a POST request to load previous predictions (previous page)
          const response = await axios.post(prevPageUrl, postData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          });

          // Update predictions with the new data
          setPredictions(response.data.results);
          setLoading(false);
          // Store the next and previous page URLs
          setNextPageUrl(response.data.next);
          setPrevPageUrl(response.data.previous);
        }
      } catch (error) {
        console.error("Error loading previous predictions:", error);
      }
    }
  };

  return (
    <div className="prediction-home">
      <h4>MY PREDICTIONS</h4>
      {loading ? (
        <LoadingMessage />
      ) : (
        <>
          {predictions.length === 0 && authenticated ? (
            <p>You don't have any completed predictions.</p>
          ) : (
            predictions.map((prediction, index) => (
              <div className="prediction-card" key={prediction.matchId}>
                <div className="prediction-title">
                  <h6>
                    {getModifiedMatchId(prediction.matchId)} - Best of{" "}
                    {prediction.bo}
                  </h6>
                </div>
                <div className="prediction-content">
                  <div className="left">
                    <h6>{formatDate(prediction.date)}</h6>
                  </div>
                  <div className="center">
                    <div className="team-one">
                      <img
                        src={require(`./teamImages/${prediction.blue_team.toUpperCase()}.webp`)}
                        className="team-image"
                        alt="teamimage"
                      ></img>
                    </div>
                    <div className="score">
                      {prediction.blueScore} - {prediction.redScore}
                    </div>
                    <div className="team-two">
                      <img
                        src={require(`./teamImages/${prediction.red_team.toUpperCase()}.webp`)}
                        className="team-image"
                        alt="teamimage"
                      ></img>
                    </div>
                  </div>
                  <div className="right">
                    <button
                      className={`button3`}
                      id="btn30"
                      onClick={() => toggleModal(index)}
                    >
                      More
                    </button>
                    {showModalArray[index] && (
                      <PredictModal
                        show={showModalArray[index]}
                        onClose={() => toggleModal(index)}
                        prediction={prediction}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </>
      )}
      <div className="pagination">
        {prevPageUrl && (
          <button className="pagination-button" onClick={loadPrevPredictions}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 12H20M4 12L8 8M4 12L8 16"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Previous
          </button>
        )}
        {nextPageUrl && (
          <button className="pagination-button" onClick={loadNextPredictions}>
            Next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 12H20M20 12L16 8M20 12L16 16"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export default PredictionsComponent;
