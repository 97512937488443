import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy & Terms of Service</h1>
      <section>
        <h2>1. Data Collection and Usage</h2>
        <p>We collect and use your personal information to provide and improve our services. This includes:</p>
        <ul>
          <li>Account information (e.g., username, email)</li>
          <li>Usage data (e.g., interactions with our platform)</li>
          <li>Device information (e.g., IP address, browser type)</li>
        </ul>
      </section>
      <section>
        <h2>2. Data Protection</h2>
        <p>We implement security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</p>
      </section>
      <section>
        <h2>3. User Rights</h2>
        <p>You have the right to access, correct, or delete your personal information. Contact us to exercise these rights.</p>
      </section>
      <section>
        <h2>4. Terms of Service</h2>
        <p>By using our service, you agree to:</p>
        <ul>
          <li>Comply with all applicable laws and regulations</li>
          <li>Respect the intellectual property rights of our content</li>
          <li>Not engage in any harmful or disruptive behavior on our platform</li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicy;