export default function returnSvg(value, isHovered){
    let fillColor = isHovered ? "#f59336" : "#FFFFFF"; // Cambia los colores según sea necesario

    const LLA =
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="30.000000pt" height="30.000000pt" viewBox="0 0 220.000000 144.000000"
    preserveAspectRatio="xMidYMid meet">
   <metadata>
   Created by potrace 1.16, written by Peter Selinger 2001-2019
   </metadata>
   <g transform="translate(0.000000,144.000000) scale(0.100000,-0.100000)"
   fill={fillColor} stroke="none">
   <path d="M1680 1400 l0 -39 -46 19 c-25 11 -51 20 -57 20 -7 0 -88 -176 -182
   -390 l-169 -390 -36 -10 c-19 -5 -80 -23 -135 -39 -54 -17 -100 -29 -102 -27
   -2 2 13 38 33 82 20 43 56 124 79 179 80 187 186 430 201 459 8 16 14 32 14
   37 0 9 -219 101 -232 97 -11 -4 -52 -93 -155 -333 -31 -71 -75 -175 -100 -230
   -65 -148 -67 -151 -119 -270 -26 -60 -51 -114 -55 -118 -6 -8 -271 -89 -274
   -84 -1 1 36 88 82 192 46 105 103 235 127 290 24 55 78 178 121 273 42 96 75
   178 73 184 -4 10 -204 98 -224 98 -12 0 -10 5 -189 -405 -70 -159 -133 -303
   -140 -320 -73 -159 -195 -450 -195 -464 0 -12 18 -25 53 -39 51 -21 54 -25 67
   -73 8 -28 20 -53 26 -56 7 -2 87 17 177 44 160 48 165 49 154 26 -5 -13 -8
   -25 -6 -26 13 -11 211 -87 224 -87 10 0 27 24 47 68 16 37 40 90 53 117 l23
   51 132 38 c72 21 133 36 135 34 2 -1 -17 -48 -41 -103 -24 -55 -44 -104 -44
   -110 0 -14 214 -99 232 -93 7 3 53 97 101 209 l89 204 116 34 c64 18 122 36
   129 38 10 4 13 -45 13 -241 l0 -246 130 0 130 0 0 284 0 283 130 39 c110 33
   130 42 130 59 0 26 -61 225 -70 225 -3 0 -47 -13 -98 -29 l-92 -29 0 304 0
   304 -130 0 -130 0 0 -40z m0 -523 l0 -123 -61 -16 c-56 -15 -60 -15 -54 0 13
   37 110 262 113 262 1 0 2 -55 2 -123z"/>
   </g>
   </svg>

   const LCK = 
   <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   width="30.000000pt" height="30.000000pt" viewBox="0 0 1280.000000 899.000000"
   preserveAspectRatio="xMidYMid meet">
  
  <g transform="translate(0.000000,899.000000) scale(0.100000,-0.100000)"
  fill={fillColor} stroke="none">
  <path d="M3 8974 c-3 -9 -2 -19 3 -23 6 -3 37 -26 69 -50 77 -57 834 -622 900
  -671 28 -20 120 -89 205 -152 85 -63 236 -174 335 -248 242 -179 408 -303 600
  -445 199 -147 418 -309 690 -511 116 -85 254 -188 309 -227 54 -40 216 -160
  361 -267 145 -107 330 -244 412 -305 166 -122 412 -305 673 -499 96 -71 256
  -190 355 -264 99 -73 200 -148 225 -167 25 -19 167 -125 315 -235 149 -111
  281 -212 294 -225 l23 -23 -129 -258 c-71 -143 -206 -410 -301 -594 -223 -437
  -247 -489 -226 -497 26 -10 -11 -35 799 557 116 84 233 170 260 190 135 99
  208 150 217 150 6 0 62 -37 125 -82 64 -46 134 -96 158 -113 23 -16 115 -84
  205 -150 316 -233 626 -459 694 -507 66 -46 106 -60 106 -38 0 10 -147 326
  -160 345 -21 29 -500 985 -500 997 0 16 237 195 1125 853 88 65 192 142 230
  170 374 277 525 389 610 451 55 41 226 168 380 282 154 114 362 268 463 342
  350 257 932 687 1239 915 66 49 170 126 230 170 59 44 230 170 378 280 149
  110 286 212 305 225 19 13 49 35 66 49 17 14 71 55 120 91 258 187 634 472
  634 480 0 15 -46 12 -135 -9 -44 -10 -190 -44 -325 -75 -540 -124 -1075 -248
  -1543 -360 -56 -13 -210 -49 -342 -80 -132 -31 -334 -78 -450 -106 -115 -27
  -289 -68 -385 -91 -96 -22 -265 -62 -375 -89 -110 -26 -281 -67 -380 -90 -99
  -23 -297 -70 -440 -105 -143 -34 -359 -86 -480 -115 -455 -108 -569 -139 -585
  -162 -8 -11 -38 -64 -66 -117 -102 -195 -194 -370 -274 -526 -46 -88 -154
  -295 -240 -460 -86 -165 -208 -398 -269 -518 -101 -194 -115 -216 -126 -200
  -16 23 -143 262 -270 508 -203 395 -432 839 -560 1085 l-133 254 -53 13 c-30
  7 -90 21 -134 32 -172 41 -733 175 -1080 256 -126 30 -336 80 -465 110 -129
  31 -311 74 -405 96 -93 21 -217 50 -275 64 -163 39 -640 152 -1140 270 -250
  59 -536 126 -635 150 -99 24 -344 82 -545 129 -201 47 -428 101 -505 120 -159
  41 -173 42 -182 20z"/>
  <path d="M2290 1105 l0 -1105 1270 0 1270 0 0 290 0 290 -995 0 -995 0 0 815
  0 815 -275 0 -275 0 0 -1105z"/>
  <path d="M5110 1105 l0 -1105 1275 0 1275 0 0 290 0 290 -990 0 -990 0 0 530
  0 530 988 2 987 3 3 283 2 282 -1275 0 -1275 0 0 -1105z"/>
  <path d="M7930 1105 l0 -1105 285 0 285 0 2 408 3 407 565 0 564 0 51 -110
  c27 -61 59 -131 69 -155 33 -75 110 -247 175 -390 33 -74 61 -136 61 -137 0
  -2 140 -3 310 -3 171 0 310 2 310 4 0 7 -60 147 -97 226 -19 41 -69 152 -110
  245 -41 94 -90 202 -108 240 -17 39 -62 138 -100 221 l-67 152 60 133 c33 74
  95 211 137 304 179 395 287 646 280 653 -2 3 -139 7 -303 10 l-299 5 -37 -77
  c-20 -42 -36 -79 -36 -81 0 -2 -20 -47 -45 -99 -24 -52 -83 -182 -131 -288
  -47 -106 -95 -210 -105 -230 l-19 -38 -565 0 -565 0 0 405 0 405 -285 0 -285
  0 0 -1105z"/>
  </g>
  </svg>

  const LPL =
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="40.000000pt" height="40.000000pt" viewBox="0 0 1920.000000 1158.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1158.000000) scale(0.100000,-0.100000)"
fill={fillColor} stroke="none">
<path d="M6435 11512 c6 -5 314 -241 685 -524 371 -283 914 -696 1205 -918
292 -223 843 -643 1225 -935 383 -291 1259 -960 1948 -1485 689 -525 1257
-955 1262 -955 6 0 202 376 436 835 234 459 559 1098 723 1420 164 322 439
862 611 1200 172 338 332 652 356 698 l43 82 -2987 0 -2987 -1 220 -108 c473
-232 1694 -831 1944 -952 l263 -129 792 0 c436 0 795 -2 798 -5 4 -5 -562
-1128 -575 -1142 -5 -4 -405 191 -1997 975 -217 107 -521 256 -675 332 -341
168 -796 392 -1250 615 -187 92 -452 223 -590 290 -137 67 -439 216 -670 330
-540 267 -793 389 -780 377z"/>
<path d="M7562 9859 c36 -52 155 -227 265 -389 223 -330 1149 -1695 1321
-1949 62 -90 112 -168 112 -173 0 -4 -396 -8 -880 -8 -484 0 -880 3 -880 6 0
4 100 203 222 443 l222 436 -367 537 c-202 295 -375 546 -386 559 l-18 22
-223 -439 c-122 -241 -253 -497 -290 -569 -37 -71 -159 -310 -271 -530 -111
-220 -346 -681 -521 -1024 -175 -344 -316 -626 -315 -628 2 -2 1562 -2 3467
-1 l3463 3 -119 91 c-300 229 -1325 1011 -1974 1505 -393 300 -1021 779 -1395
1064 -374 285 -858 654 -1075 820 -217 165 -401 305 -409 310 -8 4 15 -34 51
-86z"/>
<path d="M1676 4771 c5 -4 209 -160 454 -346 245 -186 451 -344 459 -351 11
-10 -1 -39 -72 -176 -46 -89 -171 -334 -277 -543 -106 -209 -221 -434 -255
-500 -34 -66 -170 -331 -301 -590 -131 -259 -363 -713 -514 -1010 l-275 -539
-435 -349 c-239 -191 -439 -352 -445 -358 -6 -6 1006 -8 2749 -7 l2757 3 25
45 c13 25 150 291 303 593 l278 547 -1494 0 c-884 0 -1493 4 -1493 9 0 5 94
193 208 418 115 224 293 575 397 778 103 204 231 456 285 560 54 105 225 440
380 745 155 305 343 673 417 818 l134 262 -1647 0 c-907 0 -1644 -4 -1638 -9z"/>
<path d="M7436 4727 c38 -29 248 -189 467 -355 218 -167 397 -306 397 -309 0
-5 -519 -1028 -780 -1538 -54 -104 -165 -324 -249 -487 -375 -739 -460 -905
-551 -1083 -250 -487 -480 -943 -480 -948 0 -4 447 -7 994 -7 l994 0 531 1042
c291 574 534 1049 538 1056 6 10 113 -67 408 -292 l400 -306 1200 0 c1138 0
1206 1 1325 20 544 84 1025 361 1357 782 111 140 173 246 380 651 112 221 275
539 360 706 105 205 153 309 147 318 -5 7 -243 190 -529 408 l-519 395 -3229
0 -3229 0 68 -53z m5254 -1143 c0 -3 -63 -131 -141 -284 -101 -201 -156 -296
-195 -344 -97 -118 -246 -210 -397 -246 -79 -19 -121 -20 -1220 -20 l-1139 0
223 438 c122 240 226 443 232 450 7 9 284 12 1323 12 723 0 1314 -3 1314 -6z"/>
<path d="M14740 4776 c0 -2 209 -163 464 -357 l465 -354 -133 -260 c-74 -143
-218 -426 -322 -630 -103 -203 -427 -840 -720 -1414 l-532 -1044 -423 -339
c-233 -187 -433 -348 -444 -359 -19 -19 20 -19 2737 -19 l2756 0 232 457 c128
252 263 517 301 589 38 72 69 134 69 138 0 3 -670 6 -1490 6 -1077 0 -1490 3
-1490 11 0 11 137 288 285 574 46 88 214 419 375 735 161 316 350 688 420 825
623 1220 730 1432 730 1439 0 3 -738 6 -1640 6 -902 0 -1640 -2 -1640 -4z"/>
</g>
</svg>

const CBLOL =
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="40.000000pt" height="40.000000pt" viewBox="0 0 751.000000 532.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,532.000000) scale(0.100000,-0.100000)"
fill={fillColor} stroke="none">
<path d="M3750 5307 c0 -4 -90 -388 -201 -854 -110 -467 -206 -872 -213 -902
l-12 -54 -259 321 c-142 177 -260 320 -261 319 -2 -1 29 -127 68 -279 l71
-277 -65 -69 c-71 -75 -131 -157 -126 -171 2 -5 35 -34 74 -65 l72 -56 48 65
c26 36 51 62 54 58 10 -11 92 -332 88 -343 -2 -4 -131 50 -286 122 -156 71
-285 128 -287 126 -2 -2 19 -39 47 -82 l51 -79 -22 -91 c-32 -133 -41 -200
-41 -308 0 -246 66 -468 200 -670 75 -114 179 -226 285 -306 144 -108 373
-203 550 -227 l51 -6 57 -90 c32 -50 61 -88 66 -85 5 3 32 44 62 90 l53 85 50
6 c178 24 407 118 551 227 307 232 485 590 485 976 0 108 -9 175 -41 308 l-22
91 51 79 c28 43 49 80 47 82 -2 2 -131 -55 -287 -126 -155 -72 -284 -126 -286
-122 -4 11 78 332 88 343 3 4 28 -22 54 -58 l48 -65 72 56 c39 31 72 60 74 65
5 14 -55 96 -126 171 l-65 69 71 277 c39 152 70 278 68 279 -1 1 -119 -142
-261 -319 l-259 -321 -12 54 c-81 347 -416 1754 -419 1758 -3 2 -5 1 -5 -2z
m181 -1954 l162 -692 140 70 139 70 -23 -38 c-119 -193 -591 -928 -595 -926
-9 3 -604 946 -600 950 2 2 62 -25 133 -61 l130 -65 158 672 c87 370 163 692
168 717 9 41 11 43 18 20 4 -14 81 -336 170 -717z m-763 -1138 c176 -275 319
-501 318 -503 -6 -5 -117 38 -191 75 -246 124 -428 348 -511 628 -28 97 -43
297 -29 391 l7 47 43 -69 c24 -38 188 -294 363 -569z m1591 475 c1 -279 -84
-497 -273 -697 -99 -105 -224 -194 -339 -242 -146 -60 -165 -103 235 527 257
403 365 565 370 553 4 -9 7 -72 7 -141z"/>
<path d="M3680 2884 c-40 -115 -73 -216 -72 -224 1 -16 139 -410 146 -417 2
-2 11 15 19 39 8 24 42 120 75 213 l59 170 -73 205 c-40 113 -75 209 -76 214
-2 5 -37 -85 -78 -200z"/>
<path d="M573 1114 c-340 -46 -528 -197 -564 -449 -27 -200 27 -378 149 -485
162 -143 530 -209 951 -169 384 36 588 176 626 432 l7 47 -265 0 -266 0 -6
-22 c-12 -38 -56 -77 -109 -95 -75 -26 -358 -26 -434 0 -101 36 -142 91 -142
194 0 108 46 165 160 196 120 33 335 29 430 -9 42 -17 100 -75 100 -99 0 -13
39 -15 266 -15 l266 0 -7 48 c-16 108 -66 206 -137 266 -97 84 -264 143 -461
165 -131 15 -439 12 -564 -5z"/>
<path d="M1810 565 l0 -566 708 3 c700 3 708 3 772 25 163 55 242 156 242 308
0 101 -57 197 -162 270 l-39 27 40 50 c54 69 74 141 60 219 -18 104 -67 160
-177 202 -59 22 -65 22 -751 25 l-693 3 0 -566z m1065 291 c65 -27 57 -106
-12 -125 -22 -6 -143 -11 -285 -11 l-248 0 0 75 0 75 255 0 c199 0 263 -3 290
-14z m105 -431 c30 -15 47 -53 37 -79 -3 -8 -18 -23 -33 -33 -25 -16 -59 -18
-341 -21 l-313 -3 0 75 0 76 310 0 c259 0 316 -3 340 -15z"/>
<path d="M3610 565 l0 -565 712 0 c454 0 709 3 702 10 -5 5 -117 86 -249 180
l-240 170 -202 0 -203 0 0 385 0 385 -260 0 -260 0 0 -565z"/>
<path d="M4862 1118 c-197 -24 -370 -91 -466 -179 -53 -48 -116 -151 -116
-189 0 -10 62 -61 154 -128 l154 -112 96 0 96 0 0 54 c0 138 77 200 269 215
119 10 260 -3 318 -29 89 -39 123 -90 123 -181 0 -69 -24 -116 -78 -150 -68
-43 -127 -52 -386 -58 l-239 -6 236 -179 237 -178 107 11 c282 29 471 117 563
261 53 83 72 159 74 290 0 93 -4 129 -21 185 -67 214 -255 333 -588 374 -106
13 -428 12 -533 -1z"/>
<path d="M6080 565 l0 -565 712 0 c425 0 708 4 703 9 -6 5 -118 86 -250 180
l-240 171 -202 0 -203 0 0 385 0 385 -260 0 -260 0 0 -565z"/>
</g>
</svg>

const LJL =
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="30.000000pt" height="30.000000pt" viewBox="0 0 1024.000000 1024.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
fill={fillColor} stroke="none">
<path d="M1632 10233 c2 -5 25 -47 51 -95 148 -268 298 -704 426 -1238 46
-193 127 -590 136 -672 4 -32 10 -58 14 -58 3 0 26 35 50 78 55 96 145 238
234 365 l68 98 -11 57 c-57 277 -219 870 -277 1010 -4 9 558 12 2791 12 1538
0 2796 -1 2796 -3 0 -2 -16 -50 -35 -108 -73 -216 -183 -626 -236 -878 l-19
-94 27 -35 c50 -66 208 -309 263 -405 30 -53 58 -96 61 -97 4 0 12 37 19 83
19 127 86 452 136 662 127 531 268 938 423 1223 28 51 51 95 51 98 0 2 -1569
4 -3486 4 -1917 0 -3484 -3 -3482 -7z"/>
<path d="M3906 8551 c17 -10 40 -31 52 -46 22 -28 22 -30 22 -554 0 -504 -1
-527 -20 -558 -10 -18 -34 -40 -52 -50 l-33 -18 90 -3 c50 -2 231 -2 403 0
365 3 324 -11 390 140 62 144 62 151 3 86 -29 -31 -75 -68 -103 -82 -49 -24
-61 -26 -200 -26 -168 0 -199 9 -217 61 -7 22 -11 181 -11 493 0 520 -1 510
75 553 l40 23 -235 0 c-234 0 -235 0 -204 -19z"/>
<path d="M4932 8549 c23 -12 46 -35 58 -60 20 -39 21 -51 18 -737 -3 -625 -5
-703 -21 -757 -23 -80 -81 -194 -124 -245 -19 -22 -31 -40 -27 -40 4 0 50 24
103 52 107 60 221 160 272 240 l34 53 5 707 c5 697 5 707 26 735 11 15 36 38
54 51 l33 22 -234 0 -234 -1 37 -20z"/>
<path d="M5624 8547 c78 -45 76 -27 76 -600 0 -356 -3 -515 -11 -530 -17 -32
-61 -77 -76 -77 -7 0 -13 -4 -13 -10 0 -14 732 -13 781 0 32 9 40 18 68 79 40
88 82 193 78 198 -2 2 -18 -17 -35 -40 -17 -24 -57 -61 -88 -83 l-56 -39 -169
-3 -169 -4 -29 30 c-20 19 -31 43 -36 73 -3 24 -5 243 -3 486 3 430 4 442 24
470 11 15 36 38 54 51 l33 22 -234 0 -234 0 39 -23z"/>
<path d="M2583 8258 c-256 -412 -426 -837 -485 -1210 -20 -130 -17 -615 5
-743 82 -472 251 -833 578 -1241 192 -238 531 -567 794 -770 134 -104 261
-194 271 -194 6 0 20 21 33 48 41 84 169 267 265 378 184 214 398 377 640 490
l106 49 0 424 0 424 -52 -7 c-77 -10 -271 -77 -379 -131 -202 -102 -359 -231
-509 -419 -46 -58 -89 -106 -96 -106 -6 0 -65 35 -130 79 -389 257 -718 739
-882 1291 -101 336 -145 657 -145 1045 0 251 11 409 42 591 10 60 17 110 16
112 -1 1 -33 -48 -72 -110z"/>
<path d="M7574 8354 c18 -58 47 -311 57 -491 23 -404 -26 -844 -137 -1224
-162 -559 -495 -1051 -888 -1310 -65 -44 -124 -79 -130 -79 -7 0 -50 48 -96
106 -150 188 -307 317 -509 419 -108 54 -302 121 -379 131 l-52 7 2 -424 3
-423 130 -66 c149 -74 216 -116 332 -207 205 -159 425 -420 538 -636 17 -31
34 -57 38 -57 11 0 151 100 272 194 312 242 650 579 858 853 312 412 476 808
527 1273 14 124 14 441 0 560 -14 122 -71 371 -114 497 -84 248 -244 573 -408
827 -45 70 -53 79 -44 50z"/>
<path d="M2372 4899 c-75 -538 -208 -951 -398 -1242 -45 -69 -101 -137 -175
-210 -122 -120 -122 -122 -94 -230 97 -372 791 -1121 1867 -2015 239 -198 583
-474 828 -663 168 -130 622 -472 685 -516 l30 -21 30 21 c17 12 138 102 270
201 1167 873 2137 1713 2642 2288 312 355 482 634 483 795 0 28 -15 47 -108
140 -205 204 -341 460 -452 856 -44 153 -100 434 -126 622 -8 66 -17 121 -18
123 -2 1 -48 -53 -103 -120 -55 -68 -141 -168 -191 -223 l-90 -101 23 -114
c106 -522 290 -962 517 -1232 l40 -48 -58 -82 c-296 -425 -989 -1087 -1934
-1849 -357 -287 -906 -709 -923 -709 -32 0 -852 642 -1318 1032 -758 634
-1357 1230 -1567 1562 l-31 48 59 73 c223 282 401 717 500 1225 l19 94 -78 86
c-74 81 -212 244 -276 325 l-31 38 -22 -154z"/>
<path d="M5080 4773 c-43 -97 -106 -165 -189 -208 -253 -128 -552 -448 -693
-741 -71 -149 -71 -150 4 -204 100 -72 386 -363 474 -482 152 -205 292 -457
394 -710 24 -60 45 -107 46 -105 1 1 30 67 63 146 123 292 261 526 434 741 93
116 295 315 405 400 39 30 71 61 71 68 2 28 -69 183 -124 271 -159 257 -408
503 -626 617 -87 46 -131 93 -183 196 l-42 85 -34 -74z"/>
<path d="M3446 3139 l-46 -97 -88 -13 c-137 -19 -134 -15 -47 -97 l76 -73 -16
-87 c-23 -132 -28 -127 77 -72 l93 49 92 -50 c106 -57 101 -62 76 74 l-16 91
79 72 79 72 -35 6 c-57 10 -164 26 -172 26 -4 0 -30 44 -57 98 l-49 97 -46
-96z"/>
<path d="M6702 3141 c-22 -49 -45 -92 -50 -95 -5 -3 -40 -10 -78 -16 -38 -6
-82 -13 -98 -16 l-28 -6 73 -74 73 -73 -13 -78 c-6 -43 -15 -91 -18 -107 l-6
-29 96 51 96 50 92 -49 c51 -27 94 -48 95 -46 1 1 -6 48 -15 104 l-18 102 74
73 c83 83 86 78 -48 97 l-86 13 -44 90 c-24 49 -47 92 -50 94 -4 2 -25 -36
-47 -85z"/>
<path d="M4218 2446 l-47 -94 -100 -12 c-56 -7 -101 -16 -101 -19 0 -3 32 -38
71 -77 l71 -72 -16 -95 c-9 -52 -16 -99 -16 -104 0 -6 42 12 94 38 l94 49 97
-51 c78 -41 96 -48 92 -33 -3 11 -13 59 -22 109 l-17 90 78 71 c43 39 73 73
68 76 -5 3 -52 11 -105 18 l-96 13 -44 90 c-24 49 -46 90 -50 92 -3 2 -26 -38
-51 -89z"/>
<path d="M5927 2446 l-46 -94 -101 -12 c-55 -7 -100 -16 -100 -19 0 -3 32 -38
71 -77 l71 -72 -16 -95 c-9 -52 -16 -99 -16 -104 0 -6 42 12 94 38 l94 49 94
-50 95 -50 -19 107 -20 108 77 71 c43 39 74 73 69 76 -5 3 -52 11 -105 18
l-96 13 -44 90 c-24 49 -47 90 -50 92 -3 2 -26 -38 -52 -89z"/>
<path d="M5074 1848 l-48 -96 -85 -12 c-134 -17 -132 -14 -48 -98 l74 -73 -18
-102 c-9 -56 -16 -103 -14 -105 2 -2 41 17 87 42 47 25 91 46 98 46 8 0 54
-21 103 -46 l90 -46 -6 28 c-3 16 -11 64 -18 107 l-13 78 74 74 c41 41 70 75
65 76 -6 0 -53 8 -105 17 l-94 17 -47 95 -46 95 -49 -97z"/>
</g>
</svg>

const LCS =
<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="40.000000pt" height="40.000000pt" viewBox="0 0 800.000000 1494.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1494.000000) scale(0.100000,-0.100000)" fill={fillColor} stroke="none">
<path d="M4050 14888 c-13 -24 -69 -122 -125 -218 -56 -96 -155 -267 -220 -380 -65 -113 -166 -288 -225 -390 -59 -102 -174 -302 -257 -445 -137 -238 -267 -463 -566 -980 -184 -317 -249 -474 -304 -731 -26 -121 -27 -145 -27 -374 0 -229 1 -253 27 -374 58 -272 118 -410 347 -806 84 -146 203 -350 263 -455 504 -875 638 -1106 664 -1147 12 -21 23 -39 23 -42 0 -2 46 -82 101 -177 56 -96 109 -187 118 -204 42 -77 205 -354 207 -352 2 2 471 814 507 877 10 19 71 125 135 235 64 110 165 286 225 390 60 105 168 290 239 412 213 366 242 448 242 683 0 210 -34 322 -168 555 -32 55 -61 104 -66 108 -4 5 -96 -146 -205 -335 -109 -189 -237 -411 -286 -494 -48 -83 -97 -169 -109 -190 -12 -21 -73 -127 -135 -234 -62 -107 -170 -294 -240 -415 -70 -121 -130 -224 -134 -228 -5 -5 -53 70 -109 165 -128 222 -403 697 -547 948 -62 107 -151 261 -198 342 -101 176 -148 282 -181 409 -65 250 -57 492 24 739 44 133 54 153 420 785 48 83 139 240 202 350 64 110 173 299 243 420 342 592 355 620 377 819 24 217 -19 396 -154 634 -44 78 -82 142 -83 142 -1 0 -12 -19 -25 -42z"/>
<path d="M4583 13888 c-22 -40 -97 -170 -165 -288 -68 -118 -167 -289 -220 -380 -52 -91 -173 -300 -268 -465 -96 -165 -229 -397 -298 -515 -263 -455 -273 -474 -308 -575 -58 -171 -58 -418 0 -587 30 -90 61 -148 267 -505 117 -203 272 -471 344 -597 72 -125 135 -228 140 -228 5 0 42 58 83 128 41 71 107 185 147 254 39 69 115 199 167 290 52 91 139 242 193 335 127 220 287 496 410 710 53 94 101 174 105 178 4 5 28 -27 53 -70 24 -43 101 -175 170 -293 145 -251 185 -328 221 -430 48 -133 68 -236 73 -385 12 -306 -47 -506 -251 -850 -107 -179 -171 -315 -198 -415 -19 -72 -23 -108 -22 -240 1 -138 4 -165 28 -245 36 -119 54 -157 138 -302 l71 -122 140 242 c256 444 410 711 473 819 181 307 266 550 300 858 42 374 -38 778 -219 1110 -34 63 -116 207 -182 320 -65 113 -175 304 -245 425 -535 931 -544 946 -554 928 -16 -31 -311 -543 -629 -1093 -49 -85 -140 -243 -201 -350 -191 -332 -258 -445 -265 -452 -3 -4 -43 55 -88 132 l-82 140 161 278 c88 152 229 396 313 542 84 146 194 337 245 425 208 359 229 417 237 641 9 228 -25 351 -160 582 -39 67 -74 122 -77 122 -3 0 -24 -33 -47 -72z"/>
<path d="M1774 10943 c-135 -238 -181 -427 -155 -635 21 -169 65 -282 201 -513 25 -44 74 -127 107 -185 34 -58 110 -190 170 -295 61 -104 169 -291 240 -415 71 -124 231 -400 355 -615 347 -601 366 -633 528 -915 84 -146 201 -348 260 -450 59 -102 160 -277 225 -390 234 -407 365 -633 370 -638 2 -2 23 32 47 75 47 83 206 359 458 793 210 364 374 649 432 750 105 185 144 338 135 535 -8 194 -44 303 -170 518 l-69 118 -43 -73 c-23 -40 -103 -179 -177 -308 -75 -129 -240 -414 -366 -634 -127 -219 -233 -403 -235 -410 -9 -23 -1 -35 -332 539 -76 132 -186 323 -245 425 -125 216 -481 832 -618 1070 -138 240 -203 352 -429 743 -42 72 -118 204 -168 292 -199 347 -428 740 -435 748 -4 5 -43 -53 -86 -130z"/>
<path d="M1980 3870 l0 -690 270 0 270 0 0 90 0 90 -180 0 -180 0 0 530 c0 567 1 557 -49 614 -21 24 -84 56 -111 56 -20 0 -20 -2 -20 -690z"/>
<path d="M2782 4261 c-66 -23 -113 -67 -144 -135 -23 -50 -23 -56 -23 -401 0 -338 1 -352 22 -396 49 -106 146 -160 289 -161 87 -1 165 19 208 53 25 19 26 25 26 116 l0 95 -40 -35 c-55 -51 -136 -73 -205 -58 -98 22 -129 89 -123 268 l3 88 198 3 197 2 0 129 c0 233 -23 326 -94 389 -69 60 -209 79 -314 43z m180 -152 c33 -18 48 -71 48 -169 l0 -90 -110 0 -110 0 1 88 c0 103 15 152 49 169 31 16 94 17 122 2z"/>
<path d="M3422 4266 c-88 -29 -92 -35 -92 -137 l0 -90 33 24 c66 49 172 62 237 29 61 -30 75 -68 79 -221 2 -95 0 -131 -8 -127 -6 4 -11 14 -11 23 0 30 -42 84 -81 103 -46 24 -147 27 -188 5 -44 -22 -78 -70 -90 -125 -14 -64 -14 -380 0 -440 13 -56 52 -108 95 -126 43 -18 137 -18 173 0 37 20 91 82 91 106 0 10 5 22 10 25 6 4 10 -20 10 -64 l0 -71 96 0 95 0 -3 433 c-3 415 -4 434 -25 485 -32 79 -66 117 -130 148 -48 22 -75 28 -153 31 -63 2 -109 -2 -138 -11z m215 -528 c34 -28 43 -74 42 -210 -1 -73 -4 -144 -8 -158 -4 -14 -22 -35 -41 -48 -43 -29 -92 -24 -129 13 -26 25 -26 27 -26 194 0 155 2 171 20 191 11 12 29 26 40 31 26 11 82 4 102 -13z"/>
<path d="M4115 4261 c-48 -22 -78 -53 -102 -106 -16 -35 -18 -78 -21 -378 -2 -222 1 -356 8 -392 20 -98 80 -158 169 -170 61 -8 128 9 162 42 24 22 34 41 67 123 8 21 11 10 17 -62 12 -137 -24 -214 -117 -252 -71 -30 -167 -15 -230 35 l-38 30 0 -84 c0 -94 8 -108 77 -133 56 -20 228 -18 294 3 77 25 149 98 174 178 18 56 19 93 16 615 -1 305 -4 556 -6 558 -2 2 -41 0 -86 -5 l-84 -8 -5 -80 c-5 -80 -9 -82 -29 -14 -31 99 -162 148 -266 100z m229 -142 c54 -25 57 -50 54 -390 l-3 -311 -33 -29 c-63 -57 -159 -31 -182 49 -13 49 -13 565 0 614 19 68 95 98 164 67z"/>
<path d="M5622 4261 c-66 -23 -113 -67 -144 -135 -23 -50 -23 -57 -23 -396 l0 -345 26 -55 c34 -73 77 -114 147 -140 106 -40 275 -25 346 31 25 19 26 25 26 119 l0 98 -38 -38 c-77 -78 -222 -88 -285 -22 -34 35 -49 111 -45 229 l3 88 198 3 197 2 0 133 c-1 237 -21 321 -94 385 -69 60 -209 79 -314 43z m185 -156 c30 -21 43 -74 43 -172 l0 -83 -110 0 -110 0 1 88 c0 103 15 152 49 169 34 17 101 16 127 -2z"/>
<path d="M4790 4261 l-55 -6 -3 -450 c-3 -481 0 -514 45 -574 33 -42 78 -61 148 -61 101 0 160 45 186 143 7 26 17 47 21 47 4 0 8 -40 8 -90 l0 -90 90 0 90 0 0 546 0 546 -77 -7 c-43 -4 -86 -9 -95 -11 -17 -5 -18 -34 -18 -425 0 -467 0 -468 -65 -495 -44 -18 -59 -18 -100 2 -21 10 -38 27 -44 46 -7 20 -11 181 -11 459 l0 429 -32 -2 c-18 -1 -58 -4 -88 -7z"/>
<path d="M254 2986 c-80 -18 -113 -36 -157 -85 -88 -98 -97 -153 -97 -614 0 -399 7 -459 58 -551 55 -97 151 -146 287 -146 86 0 163 21 205 56 30 26 30 26 30 131 l0 105 -39 -39 c-52 -54 -115 -77 -188 -70 -60 6 -93 25 -127 71 -20 27 -21 39 -21 444 0 401 1 418 20 452 30 51 74 72 148 73 75 0 131 -25 176 -80 l31 -38 0 106 c0 102 -1 106 -28 132 -56 54 -189 77 -298 53z"/>
<path d="M835 2995 c-5 -2 -40 -6 -77 -10 l-68 -7 0 -689 0 -689 90 0 90 0 0 420 c0 449 1 462 50 505 16 14 36 20 71 20 58 0 86 -23 99 -81 5 -21 10 -225 10 -451 l0 -413 90 0 90 0 0 463 c0 511 -1 524 -62 585 -37 39 -96 56 -167 49 -81 -9 -135 -59 -164 -151 -10 -36 -12 -9 -15 207 -2 249 -3 254 -37 242z"/>
<path d="M6485 2994 c-11 -2 -46 -6 -77 -9 l-58 -6 0 -690 0 -689 90 0 90 0 0 430 c0 473 0 474 60 505 56 29 120 14 145 -34 13 -26 15 -92 15 -466 l0 -435 95 0 95 0 0 443 c0 466 -5 528 -47 586 -29 40 -99 71 -158 71 -99 -1 -161 -50 -187 -148 -6 -23 -13 -41 -15 -38 -3 3 -4 113 -4 245 2 192 -1 241 -11 240 -7 -1 -22 -3 -33 -5z"/>
<path d="M7215 2995 c-5 -2 -40 -6 -77 -10 l-68 -7 0 -79 0 -80 83 6 c45 4 88 8 95 10 8 3 12 29 12 85 0 82 -4 89 -45 75z"/>
<path d="M1538 2687 c-25 -7 -58 -21 -72 -30 -25 -17 -26 -22 -26 -107 l0 -89 51 32 c43 26 64 32 123 35 57 2 77 -1 103 -17 59 -37 67 -61 73 -209 3 -73 3 -135 1 -137 -2 -2 -15 19 -30 48 -30 60 -72 92 -135 101 -87 13 -159 -22 -199 -97 -21 -40 -22 -54 -22 -262 0 -215 1 -221 25 -270 34 -69 90 -98 176 -93 81 5 134 39 160 102 11 25 22 46 27 46 4 0 7 -31 7 -70 l0 -70 91 0 90 0 -3 438 c-3 388 -6 442 -21 483 -26 67 -77 122 -139 149 -65 28 -209 37 -280 17z m202 -522 c43 -22 52 -66 48 -237 -3 -137 -4 -148 -26 -171 -29 -30 -76 -39 -119 -22 -53 22 -58 43 -58 223 l0 164 33 29 c37 33 77 37 122 14z"/>
<path d="M2420 2691 c-62 -19 -99 -61 -124 -143 l-14 -43 -1 94 -1 94 -52 -7 c-29 -3 -70 -6 -90 -6 l-38 0 0 -540 0 -540 90 0 90 0 0 426 c0 471 1 478 63 511 39 20 103 12 131 -17 21 -20 21 -28 24 -470 l3 -450 94 0 95 0 0 433 c0 414 1 433 20 465 44 71 150 69 185 -3 13 -28 15 -96 15 -464 l0 -431 93 0 92 0 -5 488 -5 488 -27 42 c-34 50 -73 72 -144 79 -105 11 -176 -38 -207 -142 -8 -27 -17 -43 -20 -35 -2 8 -10 33 -17 55 -31 98 -141 149 -250 116z"/>
<path d="M3546 2690 c-47 -15 -95 -69 -114 -130 l-17 -55 -3 94 -3 94 -52 -7 c-29 -3 -69 -6 -89 -6 l-38 0 0 -675 0 -675 90 0 90 0 2 228 c3 208 4 223 16 182 31 -103 91 -150 190 -150 65 0 107 17 150 61 58 59 62 91 62 494 0 407 -4 437 -64 496 -19 18 -50 39 -68 45 -39 15 -111 16 -152 4z m57 -167 c44 -39 47 -66 47 -377 0 -334 -5 -362 -65 -390 -42 -20 -68 -20 -110 -1 -59 29 -60 35 -60 396 l0 328 29 30 c16 16 38 32 50 35 36 7 88 -2 109 -21z"/>
<path d="M4497 2689 c-82 -19 -137 -65 -176 -148 l-26 -56 -3 -312 c-4 -349 2 -397 59 -479 47 -68 111 -97 229 -102 156 -6 239 38 294 157 20 44 21 61 21 396 l0 350 -30 60 c-49 97 -144 147 -278 144 -29 -1 -69 -5 -90 -10z m181 -183 c13 -12 27 -40 32 -62 12 -51 12 -547 0 -599 -13 -58 -49 -85 -111 -85 -61 0 -84 11 -106 53 -16 29 -18 64 -18 332 0 271 2 303 18 333 35 63 134 78 185 28z"/>
<path d="M5346 2690 c-52 -16 -116 -98 -116 -149 0 -11 -4 -23 -10 -26 -6 -4 -10 27 -10 86 l0 92 -52 -7 c-29 -3 -70 -6 -90 -6 l-38 0 0 -540 0 -540 95 0 95 0 0 440 0 440 29 32 c26 29 35 33 80 33 42 0 55 -5 78 -28 l28 -27 3 -445 3 -445 89 0 90 0 0 458 c0 511 -2 531 -63 590 -50 48 -133 65 -211 42z"/>
<path d="M5898 2685 c-66 -21 -120 -77 -148 -150 -18 -49 -21 -75 -18 -141 7 -134 45 -191 225 -341 68 -57 108 -135 98 -193 -11 -65 -41 -92 -109 -98 -72 -6 -134 17 -176 64 l-30 35 0 -105 0 -106 53 -27 c47 -25 62 -28 157 -28 95 0 110 3 157 27 104 55 158 189 132 331 -20 110 -55 158 -227 310 -69 61 -92 101 -92 163 0 41 5 59 23 79 47 55 176 40 232 -27 l26 -30 -3 98 -3 99 -49 24 c-62 31 -178 38 -248 16z"/>
<path d="M7706 2690 c-56 -18 -115 -99 -116 -157 0 -13 -4 -23 -10 -23 -6 0 -10 37 -10 91 l0 92 -52 -7 c-29 -3 -70 -6 -90 -6 l-38 0 0 -675 0 -675 97 0 96 0 -6 227 c-4 125 -3 223 1 218 5 -6 14 -29 21 -52 24 -87 88 -133 183 -133 119 1 186 68 207 205 14 94 14 605 1 700 -19 131 -80 194 -193 201 -34 2 -75 -1 -91 -6z m39 -154 c63 -27 65 -40 65 -380 0 -345 -4 -371 -65 -400 -18 -9 -44 -16 -58 -16 -37 0 -90 41 -104 80 -15 44 -18 588 -3 641 21 74 90 106 165 75z"/>
<path d="M4028 2683 l-58 -4 0 -539 0 -540 90 0 90 0 0 545 0 545 -32 -2 c-18 -1 -59 -3 -90 -5z"/>
<path d="M7128 2683 l-58 -4 0 -539 0 -540 95 0 95 0 0 545 0 545 -37 -2 c-21 -1 -64 -3 -95 -5z"/>
<path d="M2521 1410 c-146 -31 -226 -147 -226 -330 0 -162 38 -235 228 -438 64 -68 126 -145 139 -170 30 -63 32 -162 3 -212 -22 -38 -93 -80 -135 -80 -60 0 -131 31 -181 79 l-50 48 3 -111 3 -110 40 -29 c84 -58 267 -66 367 -16 116 59 176 187 165 353 -9 143 -54 228 -208 390 -120 126 -155 172 -174 225 -27 78 -17 142 32 193 61 64 212 35 267 -51 15 -25 16 -20 13 89 l-2 115 -55 27 c-62 30 -159 42 -229 28z"/>
<path d="M4320 1413 c-19 -2 -54 -6 -77 -9 l-43 -5 0 -81 0 -80 63 7 c126 13 117 5 117 96 0 57 -3 79 -12 77 -7 0 -29 -3 -48 -5z"/>
<path d="M3168 1107 c-89 -26 -142 -83 -173 -189 -14 -49 -16 -103 -13 -375 3 -298 5 -321 24 -363 30 -65 69 -107 128 -138 46 -24 61 -27 161 -27 115 0 164 11 209 46 25 19 26 25 26 117 l0 96 -32 -31 c-53 -49 -95 -66 -170 -66 -76 0 -107 15 -138 66 -17 27 -20 51 -20 165 l0 132 196 0 196 0 -4 188 c-4 151 -9 196 -24 236 -22 57 -67 106 -119 131 -50 23 -184 29 -247 12z m187 -177 c17 -18 20 -36 23 -130 l4 -110 -105 0 -104 0 -5 73 c-6 93 0 137 21 166 33 44 122 44 166 1z"/>
<path d="M3941 1095 c-31 -27 -61 -106 -61 -164 0 -17 -4 -31 -10 -31 -6 0 -10 42 -10 106 l0 107 -67 -6 c-38 -4 -78 -7 -90 -7 l-23 0 0 -540 0 -540 95 0 95 0 0 415 c0 472 -2 461 75 490 38 15 49 16 75 5 17 -7 35 -20 40 -29 6 -11 9 17 10 86 0 79 -3 105 -16 117 -24 24 -78 20 -113 -9z"/>
<path d="M4717 1110 c-82 -21 -131 -63 -169 -145 -22 -48 -23 -61 -26 -370 -4 -379 1 -413 74 -494 62 -69 111 -86 244 -86 110 0 160 11 204 46 25 20 26 25 26 120 l0 100 -30 -35 c-58 -66 -197 -92 -268 -51 -53 32 -67 73 -70 218 l-4 127 203 0 202 0 -6 178 c-6 199 -19 261 -69 318 -48 54 -97 74 -191 79 -45 2 -99 0 -120 -5z m169 -171 c22 -18 34 -77 34 -166 l0 -83 -110 0 -110 0 0 90 c0 90 15 149 41 165 38 24 112 21 145 -6z"/>
<path d="M5361 1107 c-55 -21 -112 -76 -139 -135 -20 -44 -25 -72 -25 -136 -1 -71 3 -90 30 -146 34 -72 64 -106 168 -194 97 -81 125 -125 125 -194 0 -48 -4 -58 -34 -88 -31 -31 -39 -34 -97 -34 -70 0 -131 28 -168 77 l-20 28 -1 -102 c0 -98 1 -102 26 -122 61 -48 203 -65 300 -36 106 32 173 127 182 260 9 138 -40 233 -179 351 -49 42 -100 90 -114 108 -50 66 -39 177 21 205 56 25 143 5 197 -46 l27 -27 0 95 0 95 -42 24 c-35 20 -60 25 -133 27 -55 2 -103 -2 -124 -10z"/>
<path d="M4248 1103 l-48 -4 0 -540 0 -539 90 0 90 0 0 545 0 545 -42 -2 c-24 -1 -64 -3 -90 -5z"/>
</g>
</svg>

const LEC =
<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30.000000pt" height="30.000000pt" viewBox="0 0 522.000000 827.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,827.000000) scale(0.100000,-0.100000)" fill={fillColor} stroke="none">
<path d="M5162 8243 c-29 -54 -76 -308 -256 -1388 -250 -1498 -351 -1980 -416 -1980 -24 0 -56 59 -94 177 -35 107 -68 236 -231 913 -70 290 -124 468 -149 493 -72 72 -136 -192 -325 -1328 -173 -1040 -237 -1333 -303 -1387 -37 -31 -77 71 -173 442 -87 340 -130 486 -161 546 -42 84 -66 63 -119 -106 -62 -197 -145 -618 -239 -1215 -61 -386 -126 -835 -126 -871 l0 -29 138 0 c423 0 959 -82 1347 -207 217 -70 472 -186 633 -288 l72 -47 0 -658 c0 -379 4 -669 9 -683 20 -52 58 -42 150 40 124 109 236 290 278 448 17 67 18 193 16 3320 -3 3239 -7 3793 -30 3816 -8 8 -14 7 -21 -8z"/>
<path d="M36 6948 c-12 -60 -25 -2098 -26 -3949 l0 -1827 21 -68 c93 -301 394 -579 829 -767 323 -140 771 -254 1185 -302 528 -61 1167 -26 1665 91 121 28 150 46 150 92 0 39 -185 684 -312 1087 -104 330 -208 585 -256 628 -55 49 -99 -36 -292 -568 -142 -389 -189 -495 -224 -508 -61 -24 -155 290 -310 1028 -72 347 -61 287 -271 1382 -260 1349 -363 1811 -440 1981 -42 92 -69 82 -113 -41 -60 -166 -129 -465 -306 -1317 -215 -1036 -296 -1357 -358 -1408 -24 -19 -51 21 -87 131 -103 312 -226 949 -526 2737 -248 1479 -277 1630 -309 1630 -7 0 -16 -15 -20 -32z"/>
</g>
</svg>

const PCS =
<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="40.000000pt" height="40.000000pt" viewBox="0 0 675.000000 200.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill={fillColor} stroke="none">
<path d="M820 1865 c-30 -7 -75 -22 -100 -33 l-45 -19 95 -7 c172 -13 178 -20 30 -34 -151 -14 -278 -42 -318 -71 -15 -11 -47 -41 -72 -66 -45 -47 -45 -48 -15 -40 62 15 201 35 235 34 21 -1 27 -3 15 -6 -64 -16 -218 -70 -281 -98 -78 -36 -104 -67 -146 -177 l-19 -51 23 15 c13 8 46 28 73 44 49 28 48 27 -33 -51 -97 -93 -101 -103 -101 -280 -1 -230 75 -418 238 -589 163 -170 368 -257 606 -259 179 -1 333 44 475 138 71 47 210 176 210 195 0 5 -29 -13 -65 -40 -80 -60 -184 -111 -290 -142 -67 -19 -104 -23 -225 -23 -80 0 -153 3 -163 7 -16 7 -16 11 -4 33 13 25 14 26 157 29 111 3 163 9 220 25 78 23 217 87 260 121 22 17 17 16 -36 -5 -144 -58 -303 -77 -457 -55 -85 12 -87 13 -87 39 l0 27 143 -3 c101 -3 138 -1 127 6 -8 6 -31 11 -51 11 -42 0 -171 32 -220 54 -19 8 -46 25 -60 36 -35 29 -90 40 -139 28 -44 -12 -110 -75 -110 -107 0 -32 -14 -32 -53 0 -48 39 -88 108 -93 159 -3 38 -2 40 16 30 36 -19 97 41 114 112 12 53 -47 149 -100 162 -14 4 -43 2 -66 -4 -40 -11 -40 -12 -35 -51 7 -53 -6 -49 -34 9 -12 26 -30 56 -39 67 -10 11 -20 36 -23 55 -3 19 -9 47 -12 63 -10 38 4 51 93 83 42 16 95 41 117 56 71 49 244 56 485 18 36 -5 89 -10 118 -10 45 0 52 -3 52 -19 0 -11 -9 -26 -20 -34 -19 -13 -17 -16 42 -53 35 -21 106 -80 158 -131 52 -51 102 -93 111 -93 26 0 98 28 124 48 l24 19 -46 7 c-56 7 -115 41 -165 93 -60 63 -88 112 -88 154 0 36 2 39 29 39 17 0 54 -17 90 -41 64 -42 171 -153 171 -176 0 -7 -11 -18 -25 -25 -32 -14 -23 -28 18 -28 48 0 119 40 137 76 8 17 24 35 35 40 41 18 30 44 -54 123 -106 99 -121 122 -121 189 0 29 4 62 10 72 19 36 -161 190 -296 251 -75 34 -201 69 -248 69 -27 0 -21 -5 49 -45 109 -62 112 -69 12 -29 -99 40 -227 74 -271 73 -17 0 -56 -7 -86 -14z m770 -407 c0 -4 -7 -8 -15 -8 -22 0 -55 37 -55 62 0 20 3 19 35 -12 19 -19 35 -38 35 -42z"/>
<path d="M2020 1625 l0 -55 40 0 c29 0 40 4 40 15 0 9 -9 15 -25 15 -22 0 -25 4 -25 40 0 29 -4 40 -15 40 -12 0 -15 -13 -15 -55z"/>
<path d="M2147 1674 c-4 -4 -7 -29 -7 -56 l0 -48 46 0 c52 0 57 21 8 31 l-29 7 28 1 c16 1 27 7 27 15 0 8 -12 17 -27 19 -25 4 -25 4 5 6 22 0 32 5 30 14 -5 13 -69 22 -81 11z"/>
<path d="M2285 1628 c-22 -48 -23 -53 -8 -57 10 -2 19 2 21 8 5 14 27 14 52 -1 10 -7 22 -9 25 -6 11 10 -36 108 -52 108 -8 0 -25 -24 -38 -52z m45 -8 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M2432 1660 c-26 -25 -27 -38 -6 -68 12 -18 24 -22 52 -20 32 3 37 7 37 27 0 13 -6 26 -12 29 -7 2 -13 -3 -13 -12 0 -10 -8 -16 -21 -16 -32 0 -22 45 11 46 43 2 46 4 26 19 -27 20 -48 19 -74 -5z"/>
<path d="M2567 1674 c-11 -11 -8 -63 3 -85 8 -13 21 -19 46 -19 28 0 36 5 45 27 13 36 8 78 -11 78 -10 0 -17 -13 -20 -38 -6 -48 -24 -49 -30 -1 -5 36 -19 52 -33 38z"/>
<path d="M2717 1674 c-4 -4 -7 -29 -7 -56 l0 -48 45 0 c33 0 45 4 45 15 0 9 -10 16 -27 16 -24 2 -25 2 -5 6 12 2 22 11 22 18 0 7 -10 16 -22 18 -19 4 -18 5 5 6 41 2 29 25 -14 29 -19 2 -38 0 -42 -4z"/>
<path d="M2930 1660 c-25 -25 -26 -52 -2 -73 38 -35 102 -9 102 41 0 23 -34 52 -62 52 -10 0 -27 -9 -38 -20z m64 -26 c8 -21 -1 -34 -25 -34 -12 0 -19 7 -19 18 0 34 33 45 44 16z"/>
<path d="M3077 1674 c-4 -4 -7 -29 -7 -56 0 -41 3 -48 20 -48 11 0 20 7 20 15 0 8 9 15 20 15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11 0 -20 5 -20 10 0 6 12 10 26 10 39 0 24 24 -17 28 -19 2 -38 0 -42 -4z"/>
<path d="M3277 1674 c-4 -4 -7 -29 -7 -56 l0 -48 46 0 c32 0 44 4 42 13 -3 6 -14 13 -26 15 -16 3 -22 11 -24 39 -3 35 -17 51 -31 37z"/>
<path d="M3397 1673 c-4 -3 -7 -28 -7 -55 l0 -48 45 0 c33 0 45 4 45 15 0 9 -10 16 -27 16 -24 2 -25 2 -5 6 12 2 22 11 22 18 0 7 -10 16 -22 18 -20 4 -19 4 5 6 45 2 35 31 -11 31 -21 0 -42 -3 -45 -7z"/>
<path d="M3542 1664 c-47 -33 -13 -98 50 -92 27 2 33 7 33 27 0 32 -23 39 -33 11 -8 -21 -9 -21 -25 -5 -14 13 -15 20 -6 31 7 8 19 12 28 9 9 -2 22 -1 30 4 11 7 10 11 -7 20 -28 15 -43 14 -70 -5z"/>
<path d="M3670 1626 l0 -56 46 0 c52 0 57 21 8 31 l-29 7 28 1 c16 1 27 7 27 15 0 8 -12 17 -27 19 -25 4 -25 4 5 6 48 2 35 25 -16 29 l-42 3 0 -55z"/>
<path d="M3807 1673 c-4 -3 -7 -28 -7 -55 0 -36 4 -48 15 -48 10 0 15 10 15 28 l1 27 22 -27 c13 -16 31 -28 40 -28 15 0 18 8 15 53 -2 37 -7 52 -18 52 -8 0 -17 -11 -20 -24 l-6 -24 -17 26 c-18 27 -28 32 -40 20z"/>
<path d="M3957 1673 c-4 -3 -7 -28 -7 -55 l0 -48 43 0 c24 0 48 5 55 12 20 20 14 65 -10 82 -23 16 -69 21 -81 9z m62 -32 c17 -11 7 -41 -14 -41 -9 0 -15 9 -15 25 0 27 6 30 29 16z"/>
<path d="M4115 1666 c-23 -17 -14 -43 20 -56 31 -12 33 -22 3 -18 -13 2 -24 -1 -25 -7 -2 -5 14 -10 35 -10 31 0 38 4 40 22 3 17 -3 26 -22 33 -30 11 -35 25 -7 17 24 -6 36 19 15 27 -24 9 -39 7 -59 -8z"/>
<path d="M3878 1461 c-74 -24 -116 -51 -168 -107 -69 -75 -85 -121 -85 -244 0 -93 3 -112 26 -161 83 -177 321 -260 528 -185 49 18 116 64 139 97 14 21 12 24 -39 70 -95 86 -93 86 -134 51 -82 -73 -215 -59 -260 27 -59 115 -2 256 109 268 67 8 108 -3 152 -41 l39 -34 75 69 75 69 -48 44 c-67 62 -139 88 -257 93 -73 2 -108 -1 -152 -16z"/>
<path d="M6200 1461 c-71 -23 -93 -35 -142 -79 -86 -78 -123 -171 -116 -297 5 -98 35 -167 100 -234 140 -143 411 -153 571 -21 20 17 37 37 37 44 0 12 -132 136 -145 136 -4 0 -23 -13 -41 -30 -82 -71 -203 -58 -256 29 -30 47 -31 143 -2 191 30 52 78 80 135 80 59 0 87 -10 131 -48 l34 -30 74 68 75 68 -35 37 c-89 94 -277 132 -420 86z"/>
<path d="M2030 1111 l0 -361 120 0 120 0 0 90 0 90 93 0 c158 0 248 42 302 142 57 104 33 247 -53 318 -76 62 -124 73 -364 78 l-218 4 0 -361z m369 158 c36 -13 51 -35 51 -78 0 -52 -29 -71 -110 -71 l-70 0 0 80 0 80 49 0 c26 0 62 -5 80 -11z"/>
<path d="M2870 1123 c-84 -192 -154 -354 -157 -360 -4 -10 24 -13 120 -13 l124 0 22 60 22 60 137 0 137 0 21 -57 20 -58 128 -3 c70 -1 126 1 123 5 -2 4 -73 167 -157 361 l-154 352 -117 0 -116 0 -153 -347z m300 16 c17 -45 29 -83 27 -86 -3 -2 -32 -3 -64 -1 l-60 3 31 83 c17 45 31 82 33 82 1 0 16 -37 33 -81z"/>
<path d="M4450 1110 l0 -360 120 0 120 0 0 360 0 360 -120 0 -120 0 0 -360z"/>
<path d="M4860 1110 l0 -360 120 0 120 0 0 115 0 115 150 0 150 0 0 90 0 90 -150 0 -150 0 0 60 0 60 170 0 171 0 -3 93 -3 92 -287 3 -288 2 0 -360z"/>
<path d="M5560 1110 l0 -360 120 0 120 0 0 360 0 360 -120 0 -120 0 0 -360z"/>
<path d="M616 1261 c-16 -3 -31 -11 -33 -17 -6 -19 38 -24 68 -9 57 30 40 43 -35 26z"/>
<path d="M2107 676 c-52 -19 -75 -42 -89 -88 -31 -104 67 -197 177 -167 22 6 48 18 58 27 17 15 16 17 -10 44 -24 26 -28 27 -44 12 -26 -23 -71 -14 -89 18 -13 25 -13 31 0 55 18 33 63 42 89 19 16 -15 20 -14 44 12 26 27 27 29 10 44 -15 13 -85 39 -103 38 -3 -1 -22 -7 -43 -14z"/>
<path d="M3727 676 c-52 -19 -75 -42 -89 -88 -31 -104 65 -195 178 -167 23 6 55 24 71 41 24 25 28 37 28 88 0 48 -4 63 -25 85 -42 45 -106 61 -163 41z m85 -83 c37 -33 11 -103 -38 -103 -24 0 -54 33 -54 60 0 26 30 60 52 60 12 0 30 -7 40 -17z"/>
<path d="M4305 675 c-48 -17 -65 -38 -65 -80 0 -39 22 -60 80 -75 57 -15 65 -20 49 -31 -14 -8 -63 -2 -90 12 -12 6 -20 0 -32 -26 l-16 -33 30 -12 c48 -20 136 -16 167 7 67 50 46 117 -44 142 -57 16 -63 19 -45 30 7 5 29 5 50 0 29 -7 39 -5 49 8 25 34 15 51 -38 62 -28 6 -52 11 -53 11 -1 -1 -20 -7 -42 -15z"/>
<path d="M5371 680 c-40 -10 -67 -35 -76 -72 -10 -38 36 -83 93 -93 42 -7 55 -21 29 -31 -8 -3 -33 2 -56 10 l-43 14 -13 -31 c-7 -18 -11 -35 -9 -39 11 -17 106 -28 150 -17 51 13 74 39 74 85 0 39 -22 59 -83 74 -64 17 -72 31 -17 32 3 1 19 -2 36 -5 26 -5 33 -2 43 19 6 14 11 29 11 34 0 6 -94 33 -103 29 -1 -1 -17 -5 -36 -9z"/>
<path d="M6520 679 c-19 -4 -45 -19 -57 -34 -46 -53 -21 -106 57 -125 27 -7 52 -16 55 -20 9 -14 -22 -19 -65 -9 -48 11 -58 6 -62 -31 -3 -22 3 -26 50 -38 65 -16 122 -3 151 35 47 59 15 110 -81 127 -21 4 -38 11 -38 15 0 16 28 20 62 10 44 -12 42 -13 55 21 10 25 9 32 -5 39 -27 14 -84 18 -122 10z"/>
<path d="M2300 551 l0 -131 45 0 45 0 0 45 c0 45 0 45 35 45 35 0 35 0 35 -45 l0 -45 45 0 45 0 0 130 0 130 -45 0 -45 0 0 -45 c0 -45 0 -45 -34 -45 -34 0 -35 1 -38 43 -3 42 -3 42 -45 45 l-43 3 0 -130z"/>
<path d="M2672 663 c-20 -36 -102 -226 -102 -234 0 -5 20 -9 44 -9 35 0 45 4 49 20 4 17 14 20 55 20 42 0 52 -3 57 -20 5 -16 15 -20 51 -20 29 0 44 4 43 13 0 6 -24 64 -53 127 l-52 115 -41 3 c-32 2 -44 -1 -51 -15z m68 -125 c0 -4 -9 -8 -21 -8 -15 0 -19 4 -15 16 3 9 6 21 6 27 1 13 30 -22 30 -35z"/>
<path d="M2890 550 l0 -130 40 0 40 0 0 52 0 51 30 -41 c17 -23 37 -42 45 -42 8 0 28 21 45 48 l30 47 0 -57 0 -58 40 0 40 0 0 130 0 130 -38 0 c-36 0 -40 -3 -76 -65 -21 -36 -39 -65 -41 -65 -1 0 -21 29 -44 65 -37 60 -43 65 -76 65 l-35 0 0 -130z"/>
<path d="M3240 550 l0 -130 45 0 c43 0 45 1 45 29 0 27 3 29 49 35 64 8 101 42 101 91 0 77 -44 105 -165 105 l-75 0 0 -130z m148 36 c4 -20 -13 -36 -40 -36 -13 0 -18 8 -18 31 0 27 3 30 27 27 18 -2 29 -10 31 -22z"/>
<path d="M3510 550 l0 -130 45 0 45 0 0 130 0 130 -45 0 -45 0 0 -130z"/>
<path d="M3957 673 c-4 -3 -7 -62 -7 -130 l0 -123 45 0 44 0 3 55 3 55 44 -55 c40 -50 48 -55 82 -55 l39 0 0 130 0 130 -45 0 -45 0 0 -56 0 -56 -46 56 c-39 47 -52 56 -79 56 -17 0 -35 -3 -38 -7z"/>
<path d="M4490 550 l0 -130 45 0 45 0 0 45 0 45 40 0 40 0 0 -45 0 -45 45 0 45 0 0 130 0 130 -45 0 -45 0 0 -45 0 -45 -40 0 -40 0 0 45 0 45 -45 0 -45 0 0 -130z"/>
<path d="M4790 550 l0 -130 45 0 45 0 0 130 0 130 -45 0 -45 0 0 -130z"/>
<path d="M4920 550 l0 -130 45 0 c43 0 45 1 45 29 0 27 3 29 49 35 62 8 101 44 101 93 0 75 -45 103 -165 103 l-75 0 0 -130z m148 36 c4 -20 -13 -36 -40 -36 -13 0 -18 8 -18 31 0 27 3 30 27 27 18 -2 29 -10 31 -22z"/>
<path d="M5550 550 l0 -130 111 0 110 0 -3 33 c-3 32 -3 32 -65 35 -47 2 -63 7 -63 17 0 11 14 15 56 15 l55 0 -3 33 c-3 31 -4 32 -55 35 -73 4 -67 22 7 22 l60 0 0 35 0 35 -105 0 -105 0 0 -130z"/>
<path d="M5800 550 l0 -130 45 0 c43 0 45 1 45 30 0 17 4 30 9 30 5 0 19 -13 31 -30 18 -26 28 -30 66 -30 49 0 52 4 24 40 -25 31 -25 36 0 67 24 30 25 63 5 102 -19 37 -60 51 -151 51 l-74 0 0 -130z m144 44 c8 -21 -11 -44 -35 -44 -14 0 -19 7 -19 30 0 25 4 30 24 30 13 0 27 -7 30 -16z"/>
<path d="M6070 550 l0 -130 45 0 45 0 0 130 0 130 -45 0 -45 0 0 -130z"/>
<path d="M6207 673 c-4 -3 -7 -62 -7 -130 l0 -123 110 0 110 0 0 35 0 35 -65 0 c-51 0 -65 3 -65 15 0 12 13 15 56 15 l55 0 -3 33 c-3 31 -4 32 -55 35 -73 4 -67 22 7 22 l60 0 0 35 0 35 -98 0 c-54 0 -102 -3 -105 -7z"/>
</g>
</svg>

const VCS =
<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="45.000000pt" height="45.000000pt" viewBox="0 0 600.000000 214.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,214.000000) scale(0.100000,-0.100000)" fill={fillColor} stroke="none">
<path d="M1259 2122 c-141 -154 -270 -362 -367 -592 -46 -109 -120 -322 -147 -422 -10 -38 -21 -68 -24 -68 -12 0 -111 317 -111 352 0 49 20 71 97 106 l69 32 54 116 c29 63 73 150 97 191 24 42 42 78 40 79 -1 1 -178 -72 -392 -162 -572 -242 -570 -241 -570 -246 0 -8 497 -762 514 -779 15 -16 31 -14 219 15 l204 31 48 120 c144 360 357 716 607 1013 29 35 53 67 53 71 0 7 -358 162 -370 160 -3 0 -12 -8 -21 -17z"/>
<path d="M2380 1969 c0 -18 58 -419 75 -516 l6 -33 69 0 69 0 6 33 c16 92 75 500 75 517 0 17 -7 20 -49 20 -28 0 -52 -4 -56 -9 -3 -5 -13 -98 -22 -205 -9 -108 -19 -194 -21 -192 -2 2 -12 90 -22 195 -10 104 -21 195 -25 201 -3 5 -28 10 -56 10 -43 0 -49 -2 -49 -21z"/>
<path d="M2865 1977 c-3 -7 -4 -134 -3 -282 l3 -270 113 -3 c122 -3 116 -6 126 61 l5 37 -70 0 -69 0 0 75 0 75 61 0 60 0 -3 48 -3 47 -57 3 -58 3 0 59 0 60 60 0 60 0 0 50 0 50 -110 0 c-80 0 -112 -4 -115 -13z"/>
<path d="M3127 1984 c-4 -4 -7 -27 -7 -50 l0 -43 43 -3 42 -3 3 -232 2 -233 50 0 50 0 2 233 3 232 43 3 43 3 -3 47 -3 47 -131 3 c-71 1 -133 -1 -137 -4z"/>
<path d="M3437 1983 c-4 -3 -7 -132 -7 -285 l0 -279 48 3 47 3 -2 169 c-2 93 -1 167 1 165 2 -2 18 -71 35 -154 18 -82 35 -158 37 -167 4 -15 17 -18 65 -18 l59 0 -2 283 -3 282 -45 0 -45 0 0 -157 c0 -86 -1 -155 -3 -153 -2 2 -18 68 -36 148 -18 79 -36 150 -40 156 -8 12 -98 16 -109 4z"/>
<path d="M3825 1978 c-5 -18 -75 -519 -75 -540 0 -15 9 -18 50 -18 56 0 56 -1 65 83 6 57 7 57 40 57 33 0 34 0 40 -57 4 -32 8 -64 10 -70 5 -14 101 -19 108 -5 5 7 -69 525 -78 550 -3 8 -29 12 -80 12 -52 0 -77 -4 -80 -12z m99 -260 l7 -68 -27 0 c-25 0 -26 2 -21 43 4 23 9 74 13 112 l8 70 6 -45 c4 -25 10 -75 14 -112z"/>
<path d="M4095 1977 c-3 -7 -4 -134 -3 -282 l3 -270 47 -3 48 -3 2 213 3 213 18 -170 c10 -93 20 -189 24 -212 l5 -43 43 0 c33 0 45 4 49 18 2 9 12 89 21 177 9 88 20 170 23 183 4 12 7 -67 7 -175 l0 -198 50 0 50 0 0 280 0 280 -75 0 -75 0 -21 -142 c-11 -79 -23 -145 -26 -148 -2 -3 -10 52 -17 123 -18 178 -15 172 -101 172 -48 0 -72 -4 -75 -13z"/>
<path d="M2712 1703 l3 -278 53 -3 52 -3 0 280 0 281 -55 0 -55 0 2 -277z"/>
<path d="M1684 1833 c-263 -291 -451 -590 -568 -908 -39 -104 -42 -120 -28 -123 25 -4 934 136 943 145 6 7 79 172 79 180 0 2 -166 3 -370 3 -203 0 -370 3 -370 8 0 4 66 50 148 102 81 52 211 136 289 186 l142 93 -81 198 c-44 109 -84 202 -88 206 -4 5 -47 -36 -96 -90z"/>
<path d="M2483 1343 c-33 -6 -70 -47 -82 -89 -14 -50 -14 -347 -1 -396 16 -57 60 -88 126 -88 99 0 144 45 144 145 l0 45 -55 0 -55 0 0 -45 c0 -49 -18 -67 -45 -45 -12 10 -15 44 -15 184 0 94 4 177 9 184 5 8 17 12 27 10 14 -2 20 -13 22 -46 l3 -42 55 0 54 0 0 43 c0 105 -75 162 -187 140z"/>
<path d="M4356 1340 c-41 -13 -74 -48 -86 -89 -13 -47 -13 -345 0 -392 16 -57 51 -82 119 -87 71 -5 118 14 146 61 18 29 20 51 20 222 0 205 -7 236 -58 270 -30 19 -103 27 -141 15z m79 -285 c0 -180 -1 -190 -19 -193 -39 -8 -46 22 -46 194 0 89 3 170 6 179 4 10 16 15 33 13 l26 -3 0 -190z"/>
<path d="M4993 1335 c-42 -18 -62 -51 -70 -112 -9 -76 10 -116 89 -193 69 -68 69 -69 66 -117 -2 -37 -7 -49 -22 -51 -24 -5 -36 14 -36 56 0 17 -1 32 -3 32 -2 0 -25 -7 -51 -16 l-48 -17 5 -45 c7 -66 40 -95 115 -100 107 -8 153 38 154 151 0 86 -13 111 -93 188 -66 63 -70 70 -67 106 2 30 7 38 23 38 16 0 21 -8 23 -37 4 -42 7 -43 63 -26 36 10 39 14 39 47 0 86 -97 136 -187 96z"/>
<path d="M2710 1060 l0 -280 55 0 55 0 0 126 0 125 33 -3 32 -3 3 -122 3 -123 54 0 55 0 0 280 0 280 -55 0 -55 0 0 -110 0 -110 -35 0 -35 0 0 110 0 110 -55 0 -55 0 0 -280z"/>
<path d="M3100 1332 c0 -11 -68 -487 -75 -525 l-5 -28 58 3 57 3 3 40 c5 77 9 85 46 85 l34 0 7 -65 7 -65 54 0 c50 0 54 2 53 23 0 12 -17 137 -37 277 l-37 255 -83 3 c-45 2 -82 -1 -82 -6z m104 -289 c4 -30 2 -33 -21 -33 l-25 0 6 78 c11 131 19 148 28 62 4 -41 9 -90 12 -107z"/>
<path d="M3370 1060 l0 -280 50 0 50 0 2 203 3 202 20 -200 20 -200 48 -3 c26 -2 47 1 48 5 0 4 11 94 23 198 l24 190 1 -197 1 -198 55 0 55 0 0 280 0 280 -79 0 -79 0 -16 -112 c-9 -62 -20 -131 -23 -153 l-7 -40 -7 50 c-4 28 -13 96 -19 153 l-12 102 -79 0 -79 0 0 -280z"/>
<path d="M3800 1060 l0 -280 55 0 55 0 0 109 0 109 57 4 c47 4 61 10 84 36 31 35 46 114 35 185 -15 91 -58 117 -196 117 l-90 0 0 -280z m168 178 c15 -15 16 -102 2 -129 -6 -11 -21 -19 -35 -19 -24 0 -25 2 -25 80 0 77 1 80 23 80 13 0 28 -5 35 -12z"/>
<path d="M4117 1333 c-4 -3 -7 -129 -7 -280 l0 -273 55 0 55 0 0 280 0 280 -48 0 c-27 0 -52 -3 -55 -7z"/>
<path d="M4590 1060 l0 -280 50 0 50 0 2 163 3 162 34 -162 34 -163 64 0 63 0 -2 278 -3 277 -50 0 -50 0 2 -155 2 -155 -20 85 c-12 47 -29 117 -38 155 l-17 70 -62 3 -62 3 0 -281z"/>
<path d="M5230 1060 l0 -280 55 0 55 0 0 126 0 125 33 -3 32 -3 3 -122 3 -123 54 0 55 0 0 280 0 280 -55 0 -54 0 -3 -107 -3 -108 -32 -3 -33 -3 0 110 0 111 -55 0 -55 0 0 -280z"/>
<path d="M5560 1060 l0 -280 55 0 55 0 0 280 0 280 -55 0 -55 0 0 -280z"/>
<path d="M5720 1060 l0 -280 50 0 49 0 3 108 3 107 53 6 c88 11 122 60 122 176 0 125 -46 163 -195 163 l-85 0 0 -280z m164 174 c11 -28 6 -120 -6 -132 -7 -7 -23 -12 -36 -12 -18 0 -23 4 -18 16 3 9 6 45 6 80 0 61 1 64 24 64 13 0 27 -7 30 -16z"/>
<path d="M1205 714 l-760 -115 -42 -41 c-24 -23 -40 -45 -35 -49 4 -4 124 -55 267 -114 143 -59 265 -112 270 -117 7 -7 3 -8 -10 -5 -11 3 -157 38 -324 78 l-304 71 -45 -45 -46 -46 25 -9 c58 -23 736 -305 753 -314 14 -8 81 16 315 112 163 68 300 126 304 130 9 8 -218 110 -425 191 -76 29 -138 56 -138 59 0 3 186 4 414 2 326 -2 415 0 423 10 11 14 133 299 133 311 0 4 -3 7 -7 6 -5 -1 -350 -52 -768 -115z"/>
<path d="M2461 688 c-55 -29 -82 -104 -66 -184 5 -29 25 -57 81 -114 71 -73 74 -78 74 -124 0 -32 -5 -48 -15 -52 -24 -10 -45 15 -45 52 0 39 -1 39 -63 18 -47 -16 -47 -16 -41 -53 9 -51 30 -80 69 -97 70 -29 151 -8 185 48 22 37 28 136 10 185 -6 15 -42 58 -81 94 -66 63 -70 69 -67 105 2 31 7 39 23 39 16 0 21 -8 23 -38 3 -35 5 -37 30 -31 65 16 72 22 72 59 0 24 -9 44 -28 66 -24 27 -37 33 -83 36 -32 2 -64 -1 -78 -9z"/>
<path d="M3760 693 c-58 -21 -91 -114 -70 -193 9 -35 27 -60 80 -111 73 -71 93 -116 71 -158 -23 -42 -61 -14 -61 44 0 24 -3 26 -25 21 -59 -15 -75 -25 -75 -50 0 -95 94 -150 193 -112 70 26 101 107 78 204 -12 50 -29 72 -114 150 -47 43 -59 77 -38 111 16 25 41 4 41 -34 0 -40 -1 -39 60 -21 40 12 45 17 43 42 -4 50 -25 86 -59 100 -33 14 -96 17 -124 7z"/>
<path d="M2700 410 l0 -280 114 0 113 0 7 37 c10 64 11 63 -59 63 l-65 0 0 75 0 75 55 0 55 0 0 45 0 45 -55 0 -55 0 0 63 0 62 57 0 58 0 3 48 3 47 -115 0 -116 0 0 -280z"/>
<path d="M2970 410 l0 -280 55 0 55 0 0 120 c0 113 1 120 20 120 31 0 40 -32 40 -139 l0 -101 56 0 57 0 -6 117 c-4 93 -9 121 -25 142 -19 26 -19 26 1 53 28 37 35 131 13 181 -21 52 -61 67 -176 67 l-90 0 0 -280z m162 176 c11 -16 10 -84 -2 -107 -6 -10 -20 -19 -30 -19 -18 0 -20 7 -20 74 0 72 1 74 23 68 12 -3 25 -10 29 -16z"/>
<path d="M3280 410 l0 -280 55 0 55 0 0 280 0 280 -55 0 -55 0 0 -280z"/>
<path d="M3430 410 l0 -280 109 0 108 0 6 42 c3 23 3 46 1 50 -3 4 -30 8 -60 8 l-54 0 0 75 0 75 60 0 60 0 0 45 0 44 -57 3 -58 3 0 59 0 59 55 1 55 1 3 48 3 47 -115 0 -116 0 0 -280z"/>
</g>
</svg>

const LRN =
<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="35.000000pt" height="35.000000pt" viewBox="0 0 583.000000 279.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,279.000000) scale(0.100000,-0.100000)" fill={fillColor} stroke="none">
<path d="M1030 2685 l-94 -94 -9 -506 -9 -505 26 -32 c14 -18 62 -71 106 -118 l80 -85 28 31 c15 17 62 70 105 116 l77 86 -10 508 -11 509 -92 93 c-50 50 -94 92 -97 92 -3 0 -48 -43 -100 -95z"/>
<path d="M1548 2064 l-3 -470 -37 -75 c-39 -78 -144 -204 -299 -356 l-84 -82 -153 160 c-160 168 -209 231 -245 320 -21 52 -22 67 -25 504 l-3 450 -34 -73 c-19 -41 -50 -95 -70 -122 -101 -138 -156 -391 -142 -655 7 -130 22 -190 75 -299 72 -149 179 -274 448 -525 l151 -140 169 158 c218 203 302 294 374 404 133 201 164 401 113 732 -20 132 -54 222 -121 325 -31 47 -68 114 -84 150 l-27 65 -3 -471z"/>
<path d="M1995 1985 c4 -38 15 -122 26 -185 10 -63 19 -160 19 -216 0 -206 -56 -308 -327 -599 -131 -141 -564 -567 -582 -573 -12 -4 -466 443 -629 618 -203 220 -261 317 -285 482 -9 67 -8 97 13 240 13 90 26 190 27 223 l4 60 -33 -75 c-19 -41 -56 -109 -82 -150 -110 -169 -136 -243 -136 -389 0 -263 65 -357 680 -975 l437 -439 382 379 c490 486 601 612 681 777 87 178 78 388 -23 556 -19 31 -53 87 -76 124 -23 38 -56 101 -72 140 l-30 72 6 -70z"/>
<path d="M2680 1375 l0 -415 445 0 445 0 0 110 0 110 -312 2 -313 3 -3 303 -2 302 -130 0 -130 0 0 -415z"/>
<path d="M3700 1375 l0 -415 125 0 124 0 3 108 3 107 100 3 100 2 115 -109 115 -110 168 -1 167 0 -57 52 c-127 114 -183 168 -183 178 0 5 16 16 35 24 60 26 104 66 132 124 23 47 27 68 27 141 0 132 -45 215 -144 266 -76 39 -144 45 -497 45 l-333 0 0 -415z m673 172 c32 -20 43 -55 30 -94 -15 -45 -59 -54 -267 -51 l-181 3 -3 60 c-2 32 0 70 3 83 l6 24 192 -4 c164 -2 197 -6 220 -21z"/>
<path d="M4770 1375 l0 -415 130 0 130 0 0 271 c0 175 4 269 10 267 11 -3 206 -252 333 -425 l82 -112 183 -1 182 0 0 415 0 415 -130 0 -130 0 -2 -272 -3 -271 -70 91 c-38 50 -131 172 -205 271 l-135 179 -187 1 -188 1 0 -415z"/>
</g>
</svg>

const LRS =
<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30.000000pt" height="30.000000pt" viewBox="0 0 875.000000 637.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,637.000000) scale(0.100000,-0.100000)" fill={fillColor} stroke="none">
<path d="M1677 6318 c-8 -24 -40 -113 -70 -198 l-54 -155 53 -139 53 -140 125 -125 c95 -95 128 -123 137 -114 8 8 10 57 7 170 l-5 158 -112 290 c-62 159 -114 291 -116 293 -1 2 -10 -16 -18 -40z"/>
<path d="M1307 5880 c-3 -19 -8 -125 -11 -235 -5 -169 -11 -233 -42 -412 l-37 -211 46 -210 c50 -222 89 -366 113 -418 19 -39 395 -412 569 -563 258 -225 425 -336 496 -329 73 7 102 125 101 413 l0 200 -195 580 -195 580 -2 -110 c-1 -139 -14 -263 -28 -277 -19 -19 -83 35 -259 216 -184 190 -176 188 -132 21 10 -38 16 -71 15 -73 -5 -5 -146 149 -172 188 -12 19 -73 169 -134 332 -61 164 -114 308 -119 320 -8 21 -9 20 -14 -12z"/>
<path d="M976 4900 c-3 -19 -15 -116 -26 -215 -19 -163 -30 -213 -122 -550 l-101 -370 6 -125 c4 -69 13 -190 22 -270 l16 -145 69 -140 c185 -375 423 -733 698 -1052 133 -154 378 -396 492 -487 143 -114 120 -105 -105 42 -466 304 -843 673 -1099 1075 l-58 92 6 -110 c3 -60 13 -155 21 -209 8 -55 14 -101 12 -102 -5 -6 -177 446 -232 609 -48 145 -56 178 -70 322 -33 317 -26 304 -58 115 -39 -228 -74 -380 -101 -442 -13 -29 -94 -241 -181 -472 -112 -298 -155 -423 -149 -436 13 -24 1655 -2020 1664 -2020 5 0 1552 1875 1663 2016 17 22 11 41 -265 817 -156 438 -284 794 -286 793 -1 -2 3 -66 9 -142 14 -167 6 -478 -15 -582 -20 -97 -52 -156 -89 -160 -87 -10 -443 271 -994 786 -89 82 -143 127 -143 116 0 -25 67 -190 107 -264 20 -36 56 -94 81 -129 62 -88 31 -67 -126 84 -261 252 -407 468 -477 710 -31 108 -84 383 -129 670 -25 158 -35 201 -40 175z"/>
<path d="M4518 4273 c66 -71 129 -137 141 -147 11 -11 21 -23 21 -28 0 -4 -63 -8 -140 -8 l-140 0 0 -850 0 -850 595 0 595 0 0 225 0 225 -330 0 -330 0 0 780 0 780 -266 0 -266 0 120 -127z"/>
<path d="M5750 4395 c0 -4 64 -73 143 -153 l142 -147 -147 -3 -148 -3 0 -849 0 -850 265 0 265 0 0 340 0 340 34 0 34 0 79 -187 c44 -104 108 -257 144 -340 l64 -153 308 0 307 0 -29 58 c-57 114 -331 682 -331 687 0 2 19 14 41 26 108 55 189 180 216 336 23 133 14 474 -15 563 -52 161 -156 255 -342 311 -73 22 -91 23 -552 27 -263 2 -478 0 -478 -3z m794 -469 c59 -25 66 -45 66 -194 0 -143 -10 -180 -53 -204 -13 -7 -76 -14 -154 -16 l-133 -4 0 216 0 216 120 0 c81 0 132 -5 154 -14z"/>
<path d="M7862 4389 c-166 -19 -288 -69 -380 -154 -108 -99 -152 -220 -152 -415 0 -271 92 -433 303 -534 52 -25 176 -69 287 -102 298 -87 315 -99 315 -230 0 -90 -20 -117 -100 -135 -70 -16 -200 -7 -248 16 -58 27 -71 55 -77 159 l-5 91 -245 0 -245 0 3 -120 c2 -66 10 -147 18 -179 50 -194 182 -316 409 -378 70 -19 105 -22 290 -22 185 0 219 3 288 22 220 61 354 183 402 367 21 82 21 325 -1 408 -30 117 -90 210 -173 271 -85 63 -167 96 -385 155 -242 66 -281 82 -307 125 -29 47 -27 156 4 191 40 46 195 57 283 20 57 -23 74 -58 74 -146 l0 -79 245 0 245 0 0 106 c0 176 -46 300 -149 401 -137 136 -400 197 -699 162z"/>
<path d="M4576 1900 c-76 -23 -106 -84 -106 -215 l0 -95 -50 0 -50 0 0 -64 0 -65 48 -3 47 -3 3 -302 2 -303 80 0 80 0 2 303 3 302 83 3 82 3 0 64 0 64 -82 3 -83 3 -3 65 c-5 112 -8 110 108 110 l100 0 0 70 0 70 -117 -1 c-65 0 -131 -5 -147 -9z"/>
<path d="M4910 1438 c0 -443 2 -476 19 -515 28 -60 59 -73 181 -73 l100 0 0 75 0 74 -62 3 -63 3 -3 453 -2 452 -85 0 -85 0 0 -472z"/>
<path d="M5595 1638 c-127 -16 -241 -101 -302 -226 -35 -70 -38 -82 -38 -166 0 -110 13 -155 66 -235 92 -137 267 -207 427 -171 114 27 222 111 272 213 148 300 -90 628 -425 585z m160 -177 c207 -96 184 -393 -35 -458 -199 -58 -377 159 -281 345 57 111 206 164 316 113z"/>
<path d="M5580 1240 c0 -88 3 -110 14 -110 19 0 178 92 183 105 3 10 -169 115 -188 115 -5 0 -9 -49 -9 -110z"/>
<path d="M6100 1613 c0 -5 50 -178 112 -385 l112 -378 116 0 117 0 78 271 c58 198 81 266 86 251 5 -12 38 -134 75 -272 l67 -250 118 0 118 0 16 58 c13 43 171 590 201 695 5 15 -3 17 -78 17 l-83 0 -79 -303 c-44 -166 -81 -306 -84 -311 -3 -5 -46 130 -97 300 l-92 309 -88 0 -88 0 -86 -302 c-47 -167 -89 -303 -92 -303 -3 0 -43 137 -89 305 l-85 305 -87 0 c-49 0 -88 -3 -88 -7z"/>
</g>
</svg>
    switch(value){
        case "LLA":
            return (
                LLA
            )
        case "LCK":
            return (
                LCK
            )
        case "LPL":
            return (
                LPL
            )
        case "CBLOL":
            return(
                CBLOL
            )
        case "LJL":
            return(
                LJL
            )
        case "LCS":
            return (
                LCS
            )
        case "LEC":
            return(
                LEC
            )
        case "PCS":
            return(
                PCS
            )
        case "VCS":
            return(
                VCS
            )
        case "LRN":
            return(
                LRN
            )
        case "LRS":
            return(
                LRS
            )
    }

}