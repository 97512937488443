import React from 'react';

function SVGComponent({ valor }) {
  let svgContent;

  switch (valor) {
    case 'LLA':
      svgContent = (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="220.000000pt" height="144.000000pt" viewBox="0 0 220.000000 144.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.16, written by Peter Selinger 2001-2019
</metadata>
<g transform="translate(0.000000,144.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M1680 1400 l0 -39 -46 19 c-25 11 -51 20 -57 20 -7 0 -88 -176 -182
-390 l-169 -390 -36 -10 c-19 -5 -80 -23 -135 -39 -54 -17 -100 -29 -102 -27
-2 2 13 38 33 82 20 43 56 124 79 179 80 187 186 430 201 459 8 16 14 32 14
37 0 9 -219 101 -232 97 -11 -4 -52 -93 -155 -333 -31 -71 -75 -175 -100 -230
-65 -148 -67 -151 -119 -270 -26 -60 -51 -114 -55 -118 -6 -8 -271 -89 -274
-84 -1 1 36 88 82 192 46 105 103 235 127 290 24 55 78 178 121 273 42 96 75
178 73 184 -4 10 -204 98 -224 98 -12 0 -10 5 -189 -405 -70 -159 -133 -303
-140 -320 -73 -159 -195 -450 -195 -464 0 -12 18 -25 53 -39 51 -21 54 -25 67
-73 8 -28 20 -53 26 -56 7 -2 87 17 177 44 160 48 165 49 154 26 -5 -13 -8
-25 -6 -26 13 -11 211 -87 224 -87 10 0 27 24 47 68 16 37 40 90 53 117 l23
51 132 38 c72 21 133 36 135 34 2 -1 -17 -48 -41 -103 -24 -55 -44 -104 -44
-110 0 -14 214 -99 232 -93 7 3 53 97 101 209 l89 204 116 34 c64 18 122 36
129 38 10 4 13 -45 13 -241 l0 -246 130 0 130 0 0 284 0 283 130 39 c110 33
130 42 130 59 0 26 -61 225 -70 225 -3 0 -47 -13 -98 -29 l-92 -29 0 304 0
304 -130 0 -130 0 0 -40z m0 -523 l0 -123 -61 -16 c-56 -15 -60 -15 -54 0 13
37 110 262 113 262 1 0 2 -55 2 -123z"/>
</g>
</svg>
      );
      break;
    case 'caso2':
      svgContent = (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="220.000000pt"
          height="144.000000pt"
          viewBox="0 0 220.000000 144.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          {/* Contenido del SVG para el caso 2 */}
        </svg>
      );
      break;
    // Agrega más casos según sea necesario

    default:
      svgContent = (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="220.000000pt"
          height="144.000000pt"
          viewBox="0 0 220.000000 144.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          {/* Contenido del SVG por defecto si el valor no coincide con ningún caso */}
        </svg>
      );
  }

  return (
    <div>
      {svgContent}
    </div>
  );
}

export default SVGComponent;