import React from "react";
import { useState } from "react";
import "./Map.css";

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import returnSvg from "../images/svgs/mapsvg.js";
import { Link } from "react-router-dom"; // Importa Link desde React Router
import map from "../data/map.json";
const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries-sans-antarctica.json";
const markers = [
  {
    markerOffset: -15,
    name: "CBLOL",
    isoCode: "BRA",
    coordinates: [-60.882778, -0.793889],
    link: "/ranking/CBLOL",
  },
  {
    markerOffset: -15,
    name: "LLA",
    isoCode: "MEX",
    coordinates: [-99.125519, 19.451054],
    link: "/ranking/LLA",
  },
  {
    markerOffset: 25,
    name: "LCK",
    isoCode: "KOR",
    coordinates: [127.024612, 37.5326],
    link: "/ranking/LCK",
  },
  {
    markerOffset: 25,
    name: "LCS",
    isoCode: "USA",
    coordinates: [-122.327759, 40.098907],
    link: "/ranking/LCS",
  },
  {
    markerOffset: 25,
    name: "LPL",
    isoCode: "CHN",
    coordinates: [95.383331, 43.916668],
    link: "/ranking/LPL",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "DEU",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "ESP",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "FRA",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "POL",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "DNK",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "ITA",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "GBR",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "BEL",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "TUR",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "SRB",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "RUS",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "AUT",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "CHE",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "GRC",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "PRT",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "CZE",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "CYP",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "UKR",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "ROU",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "BGR",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "MDA",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "LEC",
    isoCode: "LTU",
    coordinates: [6.404954, 65.520008],
    link: "/ranking/LEC",
  },
  {
    markerOffset: 25,
    name: "PCS",
    isoCode: "TWN",
    coordinates: [121.597366, 25.105497],
    link: "/ranking/PCS",
  },
  {
    markerOffset: 25,
    name: "PCS",
    isoCode: "AUS",
    coordinates: [121.597366, 25.105497],
    link: "/ranking/PCS",
  },
  {
    markerOffset: 25,
    name: "VCS",
    isoCode: "VNM",
    coordinates: [106.660172, 18.762622],
    link: "/ranking/VCS",
  },
  {
    markerOffset: 25,
    name: "LJL",
    isoCode: "JPN",
    coordinates: [139.817413, 48.672855],
    link: "/ranking/LJL",
  },
  {
    markerOffset: 25,
    name: "LRN",
    isoCode: "CRI",
    coordinates: [-84.087502, 13.934739],
    link: "/ranking/LRN",
  },
  {
    markerOffset: 25,
    name: "LRN",
    isoCode: "COL",
    coordinates: [-84.087502, 13.934739],
    link: "/ranking/LRN",
  },
  {
    markerOffset: 25,
    name: "LRN",
    isoCode: "NIC",
    coordinates: [-84.087502, 13.934739],
    link: "/ranking/LRN",
  },
  {
    markerOffset: 25,
    name: "LRN",
    isoCode: "VEN",
    coordinates: [-84.087502, 13.934739],
    link: "/ranking/LRN",
  },
  {
    markerOffset: 25,
    name: "LRN",
    isoCode: "ECU",
    coordinates: [-84.087502, 13.934739],
    link: "/ranking/LRN",
  },
  {
    markerOffset: 25,
    name: "LRN",
    isoCode: "URY",
    coordinates: [-84.087502, 13.934739],
    link: "/ranking/LRN",
  },
  {
    markerOffset: 25,
    name: "LRN",
    isoCode: "PRY",
    coordinates: [-84.087502, 13.934739],
    link: "/ranking/LRN",
  },
  {
    markerOffset: 25,
    name: "LRN",
    isoCode: "BOL",
    coordinates: [-84.087502, 13.934739],
    link: "/ranking/LRN",
  },
  {
    markerOffset: 25,
    name: "LRN",
    isoCode: "DOM",
    coordinates: [-84.087502, 13.934739],
    link: "/ranking/LRN",
  },
  {
    markerOffset: 25,
    name: "LRN",
    isoCode: "PRI",
    coordinates: [-84.087502, 13.934739],
    link: "/ranking/LRN",
  },
  {
    markerOffset: 25,
    name: "LRS",
    isoCode: "CHL",
    coordinates: [-70.673676087502, -15.447487],
    link: "/ranking/LRS",
  },
  {
    markerOffset: 25,
    name: "LRS",
    isoCode: "ARG",
    coordinates: [-70.673676087502, -15.447487],
    link: "/ranking/LRS",
  },
  {
    markerOffset: 25,
    name: "LRS",
    isoCode: "PER",
    coordinates: [-70.673676087502, -15.447487],
    link: "/ranking/LRS",
  },
];

const markedCountries = markers.map((marker) => marker.isoCode);
const HomeSection = () => {
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const addedMarkers = new Set();

  return (
    <div className="home-section">
      <div className="map-container">
        <div className="map-titles">
          <h1 className="home-header">LEAGUES</h1>
          <Link to="/ranking/worlds">
            <img
              src={require("../images/worlds.png")}
              className="worlds-logo-leagues"
              alt="worldslogo"
            ></img>
          </Link>
        </div>
        <ComposableMap data-tip="">
          <Geographies geography={map}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const isoCode = geo["id"];
                const isMarked = markedCountries.includes(isoCode);
                return (
                  <Geography
                    key={geo.rsmkey}
                    geography={geo}
                    fill={isMarked ? "#2B7AAC" : "#262626"}
                  />
                );
              })
            }
          </Geographies>
          {markers.map(({ name, coordinates, link, isoCode }) => {
            // Verificar si el nombre del marcador ya ha sido agregado
            if (addedMarkers.has(name)) {
              return null; // Si ya ha sido agregado, no crear otro marcador
            }

            addedMarkers.add(name); // Agregar el nombre a la lista de marcadores agregados
            return (
              <Marker
                coordinates={coordinates}
                key={name}
                onMouseEnter={() => setHoveredMarker(name)}
                onMouseLeave={() => setHoveredMarker(null)}
              >
                <a
                  href={link}
                  style={{
                    display: "block",
                    width: "50px", // Tamaño del área de clic más pequeño
                    height: "50px",
                    position: "relative",
                    top: "-40px", // Mueve el área de clic hacia arriba
                    left: "-40px", // Mueve el área de clic hacia la izquierda
                    cursor: "pointer",
                    zIndex: 1, // Asegúrate de que esté por encima de otros elementos
                    textAlign: "center", // Centra el contenido dentro del área de clic
                  }}
                >
                  <svg width="100" height="100" viewBox="0 0 100 100">
                    <circle cx="25" cy="25" r="30" fill="transparent" />
                    {returnSvg(name, hoveredMarker === name)}
                  </svg>
                </a>
              </Marker>
            );
          })}
        </ComposableMap>
      </div>
    </div>
  );
};

export default HomeSection;
