import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./ExtraCategory.css";

const roleValues = ["Top", "Jungle", "Mid", "Bot", "Support"];
const teamListAPIUrl =
  "https://leagueprodatabackend.vercel.app/api/team-tricodes/";
const countriesAPIUrl =
  "https://leagueprodatabackend.vercel.app/api/player-countries/";

const ExtraCategory = ({
  item,
  selectedCountry,
  setSelectedCountry,
  playerTeam,
  setPlayerTeam,
  playerRole,
  setPlayerRole,
  updateFilters,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchText, setSearchText] = useState("");
  const [countryInputValue, setCountryInputValue] = useState("");
  const [teamInputValue, setTeamInputValue] = useState("");
  const [showClearButton, setShowClearButton] = useState(false); 
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (item === "COUNTRY") {
      axios
        .get(countriesAPIUrl)
        .then((response) => {
          setOptions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching countries:", error);
        });
    } else if (item === "TEAM") {
      axios
        .get(teamListAPIUrl)
        .then((response) => {
          setOptions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching team tricodes:", error);
        });
    }
  }, [item]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = options.filter((option) => {
    return item === "TEAM"
      ? option.TeamTriCode.toLowerCase().includes(inputValue.toLowerCase())
      : option.country.toLowerCase().includes(searchText.toLowerCase());
  });

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (item === "COUNTRY") {
      setCountryInputValue(newValue);
      setSearchText(newValue);
      setSelectedCountry(newValue);
    } else if (item === "TEAM") {
      setTeamInputValue(newValue);
      setInputValue(newValue);
      setSelectedOption(newValue); 
    } else {
      setPlayerRole(newValue);
    }
  };

  const handleInputClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOptionClick = (option) => {
    if (item === "COUNTRY") {
      const newSelectedCountry =
        selectedCountry === option.country ? "" : option.country;
      setSelectedCountry(newSelectedCountry);
      setCountryInputValue(newSelectedCountry);
      setInputValue(newSelectedCountry);
      updateFilters("selectedCountry", newSelectedCountry);
      setSelectedOption(newSelectedCountry);
      setShowClearButton(!!newSelectedCountry); 
    } else if (item === "TEAM") {
      const newPlayerTeam =
        playerTeam === option.TeamTriCode ? "" : option.TeamTriCode;
      setPlayerTeam(newPlayerTeam);
      setTeamInputValue(newPlayerTeam);
      setInputValue(""); 
      updateFilters("playerTeam", newPlayerTeam);
      setSelectedOption(""); 
      setShowClearButton(!!newPlayerTeam); 
    } else if (item === "ROLE") {
      const newPlayerRole = playerRole === option ? "" : option;
      setPlayerRole(newPlayerRole);
      updateFilters("playerRole", newPlayerRole);
      setSelectedOption(newPlayerRole);
    }
    setShowDropdown(false);
  };

  const handleClearSelection = () => {
    setSelectedOption("");

    if (item === "COUNTRY" || item === "TEAM") {
      if (item === "COUNTRY") {
        setSelectedCountry("");
        setCountryInputValue("");
        updateFilters("selectedCountry", "");
      } else if (item === "TEAM") {
        setPlayerTeam("");
        setTeamInputValue("");
        setInputValue(""); 
        updateFilters("playerTeam", "");
      }

      setShowClearButton(false); 
    } else if (item === "ROLE") {
      setPlayerRole("");
      updateFilters("playerRole", "");
    }
  };
  return (
    <div className="extra-category">
      <div className="extra-category-filter">
        {item === "ROLE" ? (
          <div className="role-buttons">
          {roleValues.map((role, index) => (
            <button
              key={index}
              onClick={() => {
                if (playerRole === role) {
                  setPlayerRole("");
                  updateFilters("playerRole", "");
                } else {
                  setPlayerRole(role);
                  updateFilters("playerRole", role);
                }
              }}
              className={playerRole === role ? "selected-role-button" : ""}
            >
              <img
                src={require(`../images/${role.toLowerCase()}.png`)}
                alt={role}
              />
            </button>
          ))}
        </div>
        
        ) : (
          <div className="dropdown-container" ref={dropdownRef}>
            <div className="selection-container">
              <input
                type="text"
                placeholder={item}
                value={
                  item === "COUNTRY"
                    ? countryInputValue
                    : item === "TEAM"
                    ? teamInputValue
                    : inputValue
                }
                onChange={handleInputChange}
                onClick={handleInputClick}
              />
              {item === "TEAM" && teamInputValue && !inputValue && (
                <img
                  className="team-image-preview"
                  src={require(`../images/teamImages/${teamInputValue.toUpperCase()}.webp`)}
                  alt={teamInputValue}
                />
              )}
              {showClearButton && (
                <button
                  className="clear-selection"
                  onClick={handleClearSelection}
                >
                  Clear
                </button>
              )}
            </div>
            {showDropdown && filteredOptions.length > 0 && (
              <ul className="dropdown-options">
                {filteredOptions.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => handleOptionClick(option)}
                    className={
                      item === "TEAM" && playerTeam === option.TeamTriCode
                        ? "selected"
                        : ""
                    }
                  >
                    {item === "TEAM" ? (
                      <>
                        <img
                          src={require(`../images/teamImages/${option.TeamTriCode.toUpperCase()}.webp`)}
                          alt={option.TeamTriCode}
                        />
                        <span className="team-code">{option.TeamTriCode.toUpperCase()}</span>
                      </>
                    ) : (
                      option.country
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExtraCategory;
