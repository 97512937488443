import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import user from "../data/user.json";
import axios from "axios";
import "./Accounts.css";
import Bar from "./Bar";
import ColorSelecter from "./ColorSelecter";
import "./Accounts.css";
import ExtraCategory from "./ExtraCategory";

const Accounts = () => {
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState("TIER");
  const [sortOrder, setSortOrder] = useState("desc");
  const pageSize = 10;
  const [itemsToShow, setItemsToShow] = useState(pageSize);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [playerTeam, setPlayerTeam] = useState("");
  const [playerRole, setPlayerRole] = useState("");

  let filters = ["#", "PLAYER", "TEAM", "ROLE", "TIER", "W/R"];
  let extraFilters = ["COUNTRY", "TEAM", "ROLE"];
  const roleValues = ["Top", "Jungle", "Mid", "Bot", "Support"];
  const [selectedFilters, setSelectedFilters] = useState({
    selectedCountry: "",
    playerTeam: "",
    playerRole: "",
  });
  const updateFilters = (filterType, value) => {
    setSelectedFilters({
      ...selectedFilters,
      [filterType]: value,
    });
  };
  // player.role || player.team_name || player.country || player.TeamTriCode

  var { league } = useParams();

  var serveropgg = ""; // Variable para el servidor OPGG

  const leagueToServerMapOPGG = {
    LCK: "kr",
    LEC: "euw",
    LLA: "na",
    LCS: "na",
    CBLOL: "br",
    LRN: "na",
    LRS: "br",
    LPL: "kr",
    LJL: "kr",
    PCS: "kr",
    VCS: "euw",
    worlds: "euw",
  };
  serveropgg = leagueToServerMapOPGG[league] || "kr"; // Usar un valor predeterminado si no se encuentra la liga
  const buildOpggUrl = (summonerName) => {
    const formattedSummonerName = summonerName.replace('#', '-');
    const encodedSummonerName = encodeURIComponent(formattedSummonerName);
    return `https://www.op.gg/summoners/${serveropgg}/${encodedSummonerName}`;
  };
  const tierValues = {
    CHALLENGER: 1000,
    GRANDMASTER: 800,
    MASTER: 600,
    DIAMOND: 400,
    EMERALD: 200,
    PLATINUM: 100,
    GOLD: 50,
    SILVER: 25,
    BRONZE: 0,
  };

  const rankValues = {
    I: 100,
    II: 75,
    III: 50,
    IV: 25,
  };

  const getTierValue = (tier) => {
    return tierValues[tier] || 0;
  };

  const [token, setToken] = useState(localStorage.getItem("token"));
  const buildApiUrl = () => {
    let apiUrl;

    if (league === "worlds") {
      apiUrl = "https://leagueprodatabackend.vercel.app/api/players-worlds/";

      // Incluye todos los filtros en la URL si están definidos
      if (selectedFilters.selectedCountry) {
        apiUrl += `?country=${selectedFilters.selectedCountry}`;
      }
      if (selectedFilters.playerTeam) {
        apiUrl += apiUrl.includes("?")
          ? `&team_tricode=${selectedFilters.playerTeam}`
          : `?team_tricode=${selectedFilters.playerTeam}`;
      }
      if (selectedFilters.playerRole) {
        apiUrl += apiUrl.includes("?")
          ? `&role=${selectedFilters.playerRole}`
          : `?role=${selectedFilters.playerRole}`;
      }
    } else {
      const leagueToServerMap = {
        LCK: "KR",
        LEC: "EUW1",
        LLA: "NA1",
        LCS: "NA1",
        CBLOL: "BR1",
        LRN: "NA1",
        LRS: "BR1",
        LPL: "KR",
        LJL: "KR",
        PCS: "KR",
        VCS: "EUW1",
        worlds: "KR",
      };
      const server = leagueToServerMap[league] || "KR1";

      apiUrl = `https://leagueprodatabackend.vercel.app/api/all-players/?league_name=${league}&server=${server}`;

      // Incluye todos los filtros en la URL si están definidos
      if (selectedFilters.selectedCountry) {
        apiUrl += `&country=${selectedFilters.selectedCountry}`;
      }
      if (selectedFilters.playerTeam) {
        apiUrl += `&team_tricode=${selectedFilters.playerTeam}`;
      }
      if (selectedFilters.playerRole) {
        apiUrl += `&role=${selectedFilters.playerRole}`;
      }
    }
    return apiUrl;
  };

  useEffect(() => {
    const apiUrl = buildApiUrl(); // Construir la URL aquí

    const checkTokenValidity = async () => {
      setIsLoadingData(true); // Establecer isLoadingData en true antes de la solicitud
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Si el token es inválido o ha expirado, solicitar uno nuevo
          try {
            const response = await axios.post(
              "https://leagueprodatabackend.vercel.app/api/token/",
              {
                username: user.username,
                password: user.password,
              }
            );
            const newAccessToken = response.data.access;
            setToken(newAccessToken);
            localStorage.setItem("token", newAccessToken);

            // con el nuevo token intenta acceder nuevamente al API con una nueva response
            const newResponse = await axios.get(apiUrl, {
              headers: {
                Authorization: `Bearer ${newAccessToken}`,
              },
            });

            setData(newResponse.data);
          } catch (refreshError) {}
        } else {
          console.error("Error al obtener datos protegidos:", error);
        }
      } finally {
        setIsLoadingData(false); // Establecer isLoadingData en false después de recibir los datos o al manejar errores
      }
    };

    if (token) {
      checkTokenValidity();
    } else {
      // Si no hay un token almacenado, solicita uno nuevo
      axios
        .post("https://leagueprodatabackend.vercel.app/api/token/", {
          username: user.username,
          password: user.password,
        })
        .then((res) => {
          // Almacena el token en el state y en el local storage
          const accessToken = res.data.access;
          const refreshToken = res.data.refresh;
          setToken(accessToken);
          localStorage.setItem("token", accessToken);
          localStorage.setItem("refreshToken", refreshToken);

          // Vuelve a intentar validar el uso del Token para acceder al API
          checkTokenValidity();
        })
        .catch((error) => {
          // Si falla al obtener el token, devuelve el error en la consola
          console.error("Error al obtener token JWT:", error);
          setIsLoadingData(false); // Asegúrate de establecer isLoadingData en false en caso de error
        });
    }
  }, [token, selectedFilters]); // Dependencias actualizadas

  const handleSort = (category) => {
    if (category === sortBy) {
      // Toggle the sorting order if the same category is clicked again
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Set the new sorting category and default to ascending order
      setSortBy(category);
      setSortOrder("asc");
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortBy === "PLAYER") {
      // Sort by player name
      const nameA = a.playerName.toUpperCase();
      const nameB = b.playerName.toUpperCase();
      if (nameA < nameB) return sortOrder === "asc" ? -1 : 1;
      if (nameA > nameB) return sortOrder === "asc" ? 1 : -1;
      return 0;
    } else if (sortBy === "W/R") {
      // Sort by win rate
      const winrateA =
        (a.soloq_accounts_wins /
          (a.soloq_accounts_wins + a.soloq_accounts_losses)) *
        100;
      const winrateB =
        (b.soloq_accounts_wins /
          (b.soloq_accounts_wins + b.soloq_accounts_losses)) *
        100;

      return sortOrder === "asc"
        ? winrateB - winrateA // Reverse order for ascending
        : winrateA - winrateB;
    } else if (sortBy === "ROLE") {
      // Sort by player role
      const nameA = a.role.toUpperCase();
      const nameB = b.role.toUpperCase();
      if (nameA < nameB) return sortOrder === "asc" ? -1 : 1;
      if (nameA > nameB) return sortOrder === "asc" ? 1 : -1;
      return 0;
    } else if (sortBy === "TEAM") {
      // Sort by player role
      const nameA = a.TeamTriCode.toUpperCase();
      const nameB = b.TeamTriCode.toUpperCase();
      if (nameA < nameB) return sortOrder === "asc" ? -1 : 1;
      if (nameA > nameB) return sortOrder === "asc" ? 1 : -1;
      return 0;
    } else if (sortBy === "TIER") {
      // Calculate the total value for tier and rank
      const tierValueA = getTierValue(a.soloq_accounts_tier);
      const tierValueB = getTierValue(b.soloq_accounts_tier);
      const rankValueA = rankValues[a.soloq_accounts_rank] || 0;
      const rankValueB = rankValues[b.soloq_accounts_rank] || 0;
      const totalValueA = tierValueA + rankValueA;
      const totalValueB = tierValueB + rankValueB;
      // Sort by the total value
      if (totalValueA === totalValueB) {
        // If total values are the same, compare league points
        return sortOrder === "asc"
          ? a.soloq_accounts_leaguePoints - b.soloq_accounts_leaguePoints
          : b.soloq_accounts_leaguePoints - a.soloq_accounts_leaguePoints;
      } else {
        return sortOrder === "asc"
          ? totalValueA - totalValueB
          : totalValueB - totalValueA;
      }
    } else {
      // Handle other sorting categories if needed
      return 0;
    }
  });

  function getRoleImage(role) {
    const roleImages = {
      Mid: "mid.png",
      Top: "top.png",
      Bot: "adc.png",
      Jungle: "jungle.png",
      Support: "support.png",
      Coach: "fill.png",
    };
    const imageName = roleImages[role];
    if (imageName) {
      return require(`../images/${imageName}`);
    } else {
      return null; // Handle unknown roles
    }
  }

  function getEloImage(elo) {
    const eloImages = {
      CHALLENGER: "CHALLENGER.webp",
      GRANDMASTER: "GRANDMASTER.webp",
      MASTER: "MASTER.webp",
      DIAMOND: "DIAMOND.webp",
      EMERALD: "EMERALD.webp",
      PLATINUM: "PLATINUM.webp",
      GOLD: "GOLD.webp",
      SILVER: "SILVER.webp",
      BRONZE: "BRONZE.webp",
      IRON: "IRON.webp",
    };
    const imageName = eloImages[elo];
    if (imageName) {
      return require(`../images/rankIcons/${imageName}`);
    } else {
      return null; // Handle unknown roles
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 100 &&
        itemsToShow < sortedData.length &&
        !isLoading // Ensure loading only happens once
      ) {
        setIsLoading(true); // Set loading state to true
        // Load more items with a delay of 2 seconds
        setTimeout(() => {
          setItemsToShow(itemsToShow + pageSize);
          setIsLoading(false); // Set loading state to false after the delay
        }, 1000);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [itemsToShow, pageSize, sortedData, isLoading]); // Add isLoading to dependencies
  return (
    <div className="lpd-accounts-table">
      <img
        src={require(`../images/leagueImages/${league}.webp`)}
        className="lpd-worlds-logo"
        alt="worldslogo"
      />
      <div className="lpd-extra-categories">
        {extraFilters.map((item, index) => (
          <ExtraCategory
            key={index}
            item={item}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            playerTeam={playerTeam}
            setPlayerTeam={setPlayerTeam}
            playerRole={playerRole}
            setPlayerRole={setPlayerRole}
            updateFilters={updateFilters}
          />
        ))}
      </div>
      <div className="lpd-categories">
        {filters.map((item, idx) => (
          <div
            className={`lpd-category ${
              idx === 0 ? "player-lpd-ranking" : ""
            }   ${item === "ROLE" ? "lpd-role-column" : ""} ${
              item === "PLAYER" || "TIER" ? "player-lpd-ranking" : ""
            }`}
            key={item}
            onClick={() => handleSort(item)}
            style={{ cursor: "pointer" }}
          >
            <h2>{item}</h2>
          </div>
        ))}
      </div>
      {isLoadingData ? (
        <div className="lpd-loader-screen">
          <div className="lpd-loading-indicator">
            <div className="lpd-dot-spinner">
              {[...Array(8)].map((_, i) => (
                <div key={i} className="lpd-dot-spinner__dot"></div>
              ))}
            </div>
          </div>
        </div>
      ) : data.length === 0 ? (
        <div className="lpd-work-in-progress">
          No results found, try other filters
        </div>
      ) : (
        <div className="lpd-account-section">
          {sortedData.slice(0, itemsToShow).map((item, index) => (
            <div
              className="lpd-account-card"
              key={index}
              style={{
                background:
                  index % 2 === 0
                    ? "linear-gradient(110deg, #24282f 0%, rgba(36,36,36,0.65) 42%, #24282f 100%)"
                    : "linear-gradient(110deg, #24282f 0%, rgba(36,36,36,0.65) 42%, #24282f 100%)",
              }}
            >
              <div className="lpd-account-card-item-index">
                <h2>{index + 1}</h2>
              </div>
              <div className="lpd-account-card-item lpd-account-card-name-info">
                <img
                  src={require(`../components/lolpredict/playerImages/${item.playerName}.webp`)}
                  alt={`${item.playerName} avatar`}
                  className="lpd-player-avatar"
                  onError={(e) => {
                    e.target.src = require("../components/lolpredict/playerImages/default.webp");
                  }}
                />
                <img
                  src={require(`../images/leagueImages/${item.league_name}.webp`)}
                  alt="playerimg"
                  className="lpd-league-img"
                />
                <span className="lpd-elo-acc-item">{item.playerName}</span>
              </div>
              <div className="lpd-account-card-item lpd-center-content">
                <img
                  src={require(`../images/teamImages/${item.TeamTriCode.toUpperCase()}.webp`)}
                  className="lpd-team-image"
                  alt="teamimage"
                />
              </div>
              <div className="lpd-account-card-item lpd-center-content lpd-role-column">
                {getRoleImage(item.role) && (
                  <img
                    src={getRoleImage(item.role)}
                    alt="Role"
                    className="lpd-role-img"
                  />
                )}
              </div>
              <div
                className="lpd-account-card-item lpd-account-card-item-elo"
                onClick={() =>
                  window.open(
                    buildOpggUrl(item.soloq_accounts_summonerName),
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <div className="lpd-elo-img-container">
                  {getEloImage(item.soloq_accounts_tier) && (
                    <img
                      src={getEloImage(item.soloq_accounts_tier)}
                      alt="Elo"
                      className="lpd-elo-img"
                    />
                  )}
                </div>
                <div className="lpd-elo-text">
                  <span className="lpd-elo-acc-item">
                    {item.soloq_accounts_summonerName}
                  </span>
                  <span className="lpd-elo-acc-item">
                    {item.soloq_accounts_tier} {item.soloq_accounts_rank}
                  </span>
                  <span className="lpd-elo-acc-item">
                    {item.soloq_accounts_leaguePoints} LP
                  </span>
                </div>
              </div>
              <div className="lpd-account-card-item lpd-winrate">
                <div className="lpd-bar">
                  <div
                    className="lpd-bar-fill"
                    style={{
                      width: `${
                        (item.soloq_accounts_wins /
                          (item.soloq_accounts_wins +
                            item.soloq_accounts_losses)) *
                        100
                      }%`,
                    }}
                  />
                </div>
                <span className="lpd-winrate-text">
                  {(
                    (item.soloq_accounts_wins /
                      (item.soloq_accounts_wins + item.soloq_accounts_losses)) *
                    100
                  ).toFixed(0)}
                  %
                </span>
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="lpd-scroll-loader">
              <div className="lpd-dot-spinner">
                {[...Array(8)].map((_, i) => (
                  <div key={i} className="lpd-dot-spinner__dot"></div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Accounts;
