import "./App.css";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import icon from "./images/favico.ico";
import { useEffect, useState } from "react";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const favico = document.getElementById("favico");
    favico.setAttribute("href", icon);
  }, []);

  const handleNavbarLoad = () => {
    setLoading(false);
  };

  return (
    <div className="App">
      <Navbar onNavbarLoad={handleNavbarLoad} />
      {!loading && <Footer />}
    </div>
  );
}

export default App;