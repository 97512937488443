import React from 'react';
import { Link } from 'react-router-dom';
import "./login.css";
import Logo from '../images/LPD.png';

const AccountActivated = () => {
  return (
    <div className="login-box">
      <div className="login-header">
      <img src={Logo} alt="Logo" className="login-logo" />

        <h2>Your Account Has Been Activated Successfully</h2>
      </div>
      <div className='activated'>
        <p>
            Congratulations! Your account has been successfully activated.
        </p>
      </div>
      <Link to="/login">
        <button className="loginButton loginButtonColor activated-button">
          Login
        </button>
      </Link>
    </div>
  );
};

export default AccountActivated;
