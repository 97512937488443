import React, { useState, useEffect } from "react";
import axios from "axios";

const MAX_RETRIES = 3; // Número máximo de reintentos

const fetchWithRetries = async (url, retries = MAX_RETRIES) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    if (retries > 0) {
      console.warn(`Retrying fetch for ${url}. Attempts left: ${retries}`);
      return fetchWithRetries(url, retries - 1);
    } else {
      throw new Error(`Failed to fetch ${url} after ${MAX_RETRIES} attempts`);
    }
  }
};

const Modal = ({ show, onClose, prediction }) => {
  const [teamBlueData, setTeamBlueData] = useState([]);
  const [teamRedData, setTeamRedData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga

  const teamBlueApiUrl = `https://leagueprodatabackend.vercel.app/api/players/${prediction.blue_team}`;
  const teamRedApiUrl = `https://leagueprodatabackend.vercel.app/api/players/${prediction.red_team}`;

  useEffect(() => {
    // Add an event listener to update the windowWidth state when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data for teamBlue
        const teamBluePlayers = await fetchWithRetries(teamBlueApiUrl);
        const playerStatsPromises = teamBluePlayers.map((player) =>
          fetchWithRetries(
            `https://leagueprodatabackend.vercel.app/api/get_player_stats/${player.leaguepedia}`
          )
        );
        const statsResponses = await Promise.all(playerStatsPromises);
        const updatedTeamBlueData = teamBluePlayers.map((player, index) => ({
          ...player,
          stats: statsResponses[index],
        }));
        setTeamBlueData(updatedTeamBlueData);

        // Fetch data for teamRed
        const teamRedPlayers = await fetchWithRetries(teamRedApiUrl);
        const playerStatsPromisesRed = teamRedPlayers.map((player) =>
          fetchWithRetries(
            `https://leagueprodatabackend.vercel.app/api/get_player_stats/${player.leaguepedia}`
          )
        );
        const statsResponsesRed = await Promise.all(playerStatsPromisesRed);
        const updatedTeamRedData = teamRedPlayers.map((player, index) => ({
          ...player,
          stats: statsResponsesRed[index],
        }));
        setTeamRedData(updatedTeamRedData);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Asegúrate de que el estado de carga se establezca en falso incluso si ocurre un error
      }
    };

    fetchData();
  }, [teamBlueApiUrl, teamRedApiUrl]);

  const filterUniqueRoles = (players) => {
    const uniqueRoles = [];
    const allowedRoles = ["Top", "Jungle", "Mid", "Bot", "Support"];
    const filteredPlayers = players.filter((player) => {
      if (
        allowedRoles.includes(player.role) &&
        !uniqueRoles.includes(player.role)
      ) {
        uniqueRoles.push(player.role);
        return true;
      }
      return false;
    });
    return filteredPlayers;
  };

  const LoadingMessage = () => (
    <div className="loader-screen">
      <div className="loading-indicator">
        <div className="dot-spinner">
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
        </div>
      </div>
    </div>
  );

  const winnerMatchClass =
    prediction.result_winner === null
      ? "gray"
      : prediction.result_winner === prediction.winnerTeam
      ? "green"
      : "red";
  const blueScoreMatchClass =
    prediction.result_scoreblue === null
      ? "gray"
      : prediction.result_scoreblue === prediction.blueScore
      ? "green"
      : "red";
  const redScoreMatchClass =
    prediction.result_scorered === null
      ? "gray"
      : prediction.result_scorered === prediction.redScore
      ? "green"
      : "red";

  const checkmark = "✓";
  const xMark = "❌";
  const clock = "🕒";

  const actualWinner = prediction.result_winner || "waiting...";
  const blueScore =
    prediction.result_scoreblue !== null
      ? prediction.result_scoreblue
      : "waiting...";
  const redScore =
    prediction.result_scorered !== null
      ? prediction.result_scorered
      : "waiting...";

  const shareOnTwitter = () => {
    // Detect language from the browser
    const browserLanguage = navigator.language.startsWith("es") ? "es" : "en";

    // Tweet text for Spanish or English based on browser language
    const tweetText =
      browserLanguage === "es"
        ? `Ya realicé mis predicciones en el Worlds 2024. Voté por ${prediction.winnerTeam}, el resultado fue ${prediction.blue_team} ${prediction.blueScore} - ${prediction.red_team} ${prediction.redScore}, ¿qué esperas para hacer la tuya?`
        : `I've made my predictions for Worlds 2024. I voted for ${prediction.winnerTeam}, the result was ${prediction.blue_team} ${prediction.blueScore} - ${prediction.red_team} ${prediction.redScore}. What are you waiting for to make yours?`;

    const leagueProDataUrl = encodeURIComponent("https://leagueprodata.com");
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&url=${leagueProDataUrl}&hashtags=Worlds2024`;

    window.open(tweetUrl, "_blank");
  };

  return (
    show && (
      <div className={`login-modal ${show ? "show" : ""}`}>
        <div className="login-modal-content">
          {loading ? (
            <LoadingMessage />
          ) : (
            <>
              <div className="results-container">
                <div className="results-header">
                  <h2>Game Results</h2>
                </div>
                <div className="results-content">
                  <div className="results-column">
                    <h3>Game Outcome</h3>
                    <div className={`result-item ${winnerMatchClass}`}>
                      <span>Actual Winner:</span>
                      <span>{actualWinner}</span>
                    </div>
                    <div className={`result-item ${blueScoreMatchClass}`}>
                      <span>{prediction.blue_team}:</span>
                      <span>{blueScore}</span>
                    </div>
                    <div className={`result-item ${redScoreMatchClass}`}>
                      <span>{prediction.red_team}:</span>
                      <span>{redScore}</span>
                    </div>
                  </div>
                  <div className="results-column">
                    <h3>Actual Results</h3>
                    <div
                      className={`result-item ${winnerMatchClass} result-item-center`}
                    >
                      {winnerMatchClass === "green"
                        ? checkmark
                        : winnerMatchClass === "red"
                        ? xMark
                        : clock}
                    </div>
                    <div
                      className={`result-item ${blueScoreMatchClass} result-item-center`}
                    >
                      {blueScoreMatchClass === "green"
                        ? checkmark
                        : blueScoreMatchClass === "red"
                        ? xMark
                        : clock}
                    </div>
                    <div
                      className={`result-item ${redScoreMatchClass} result-item-center`}
                    >
                      {redScoreMatchClass === "green"
                        ? checkmark
                        : redScoreMatchClass === "red"
                        ? xMark
                        : clock}
                    </div>
                  </div>
                  <div className="results-column">
                    <h3>Your Choices</h3>
                    <div className="result-item">
                      <span>Winner:</span>
                      <span>{prediction.winnerTeam}</span>
                    </div>
                    <div className="result-item">
                      <span>{prediction.blue_team}:</span>
                      <span>{prediction.blueScore}</span>
                    </div>
                    <div className="result-item">
                      <span>{prediction.red_team}:</span>
                      <span>{prediction.redScore}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="team-players">
                <div className="team-players-container">
                  <div className="team-title blue-title">
                    <h3>{prediction.blue_team}</h3>
                  </div>
                  <div className="players-table">
                    <div className="table-header">
                      <div>Player</div>
                      <div>Kills</div>
                      <div>Deaths</div>
                      <div>Assists</div>
                      <div>CS/M</div>
                      <div>DMG/M</div>
                      <div>GOLD/M</div>
                    </div>
                    {filterUniqueRoles(teamBlueData).map((player) => (
                      <div key={player.id} className="table-row">
                        <div className="player-info">
                          <img
                            src={require(`./playerImages/${player.playerName}.webp`)}
                            className="player-avatar-pic"
                            alt={player.playerName}
                          />
                          <div>
                            <div className="player-name">
                              {player.playerName}
                            </div>
                            <div className="player-role">{player.role}</div>
                          </div>
                        </div>
                        <div>{player.stats.Kills}</div>
                        <div>{player.stats.Deaths}</div>
                        <div>{player.stats.Assists}</div>
                        <div>{player.stats.CS_per_min}</div>
                        <div>{player.stats.DMG_per_min}</div>
                        <div>{player.stats.Gold_per_min}</div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="team-players-container">
                  <div className="team-title red-title">
                    <h3>{prediction.red_team}</h3>
                  </div>
                  <div className="players-table">
                    <div className="table-header">
                      <div>Player</div>
                      <div>Kills</div>
                      <div>Deaths</div>
                      <div>Assists</div>
                      <div>CS/M</div>
                      <div>DMG/M</div>
                      <div>GOLD/M</div>
                    </div>
                    {filterUniqueRoles(teamRedData).map((player) => (
                      <div key={player.id} className="table-row">
                        <div className="player-info">
                          <img
                            src={require(`./playerImages/${player.playerName}.webp`)}
                            className="player-avatar-pic"
                            alt={player.playerName}
                          />
                          <div>
                            <div className="player-name">
                              {player.playerName}
                            </div>
                            <div className="player-role">{player.role}</div>
                          </div>
                        </div>
                        <div>{player.stats.Kills}</div>
                        <div>{player.stats.Deaths}</div>
                        <div>{player.stats.Assists}</div>
                        <div>{player.stats.CS_per_min}</div>
                        <div>{player.stats.DMG_per_min}</div>
                        <div>{player.stats.Gold_per_min}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="footer-modal">
                <button
                  className="button-share button3 margin-button "
                  onClick={shareOnTwitter}
                >
                  Share on X
                </button>
                <button
                  className="button-share button3 margin-button"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default Modal;
