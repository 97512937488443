import axios from "axios";

export const getToken = async (username, password) => {
  try {
    const response = await axios.post(
      "https://leagueprodatabackend.vercel.app/session/token/get/",
      {
        username: username,
        password: password,
      }
    );

    if (response.status === 200) {
      const { access, refresh } = response.data;

      // Guardar los tokens en localStorage
      localStorage.setItem("accessToken", access);
      localStorage.setItem("refreshToken", refresh);

      // Podrías también retornar el token si lo necesitas en otra parte de tu código
      return response;
    } else {
      // Manejar otros códigos de estado si es necesario
      console.error("Error al obtener el token:", response.data);
      return response;
    }
  } catch (error) {
    // Manejar errores de red u otros errores
    console.error("Error al obtener el token:", error);
    return error;
  }
};


// Function to check if a JWT token is expired
export const verifyTokenExpired = (token) => {
  if (token) {
    try {
      const tokenPayload = JSON.parse(atob(token.split('.')[1])); // Decode the JWT token

      const tokenExpiryTime = tokenPayload.exp * 1000; // Convert expiration time to milliseconds
      const currentTime = new Date().getTime(); // Get the current time in milliseconds

      return currentTime > tokenExpiryTime; // Return true if the current time is greater than the expiry time
    } catch (error) {
      console.error('Error decoding token:', error);
      return true; // Return true if there's an error decoding the token
    }
  } else {
    return true; // Return true if no token is found
  }
};

// Function to verify and refresh the token if necessary
export const verify_refresh_token = async () => {
  // Get the access and refresh tokens from localStorage
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  // Check if the access token is expired
  const isTokenExpired = verifyTokenExpired(accessToken);

  if (accessToken && refreshToken && !isTokenExpired) {
    try {
      // Attempt to refresh the token using the refresh token
      const response = await axios.post(
        'https://leagueprodatabackend.vercel.app/session/token/refresh/',
        {
          refresh: refreshToken,
        }
      );

      if (response.status === 200) {
        const { access } = response.data;

        // Update the access token in localStorage
        localStorage.setItem('accessToken', access);

        // Return the new access token
        return access;
      } else {
        // If there's an error while refreshing, remove tokens and redirect to /login
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        return null;
      }
    } catch (error) {
      // If there's an error while refreshing, remove tokens and redirect to /login
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return null;
    }
  } else {
    // If no tokens found or access token is expired, redirect to /login
    return null;
  }
};

