import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { GiPodiumWinner } from "react-icons/gi";
import { FaTwitch } from "react-icons/fa";
import {
  FaUserAlt,
  FaTrophy,
  FaChartLine,
  FaBolt,
  FaThumbsUp,
  FaThumbsDown,
  FaPercentage,
} from "react-icons/fa";
import "./inhouses.css"; // Asegúrate de tener estos archivos CSS en tu proyecto de React
const StyledTable = styled.table`
  margin: 0 auto;
  border-collapse: collapse;
  width: 80%;
  font-size: 18px; /* Aumenté el tamaño de la fuente */
  background-color: rgba(0, 0, 0, 0.5);
`;

const StyledTh = styled.th`
  background-color: #222;
  color: #fff;
  padding: 16px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  touch-action: manipulation;
  position: relative; /* Para añadir la línea bajo el th */
  transition: all 0.3s ease; /* Animación de la línea */

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px; /* Tamaño de la línea */
    width: 0;
    background-color: #e38030; /* Color de la línea */
    transition: width 0.3s ease; /* Cambié la transición solo para 'width' */
  }

  &:hover::after {
    width: 100%; /* Mostrar la línea al pasar el mouse */
  }
`;

const StyledTd = styled.td`
  padding: 16px;
  text-align: center;
  font-size: 18px;
  color: ${(props) => {
    if (props.rank === 1 && (props.field === "name" || props.field === "rank"))
      return "#ffd700"; // Gold
    if (props.rank === 2 && (props.field === "name" || props.field === "rank"))
      return "#c0c0c0"; // Silver
    if (props.rank === 3 && (props.field === "name" || props.field === "rank"))
      return "#cd7f32"; // Bronze
    return "#fff"; // Default color for other ranks or fields
  }};
  font-family: "Spiegel-Regular", sans-serif;
`;

const Icon = styled.span`
  margin-right: 6px;
`;

const TwitchIcon = styled.a`
  display: block;
  font-size: 20px;
  text-align: center;
  padding: 16px; /* Incrementé el padding */
  color: ${(props) => (props.isLive ? "green" : "red")};
`;

const LoadingMessage = () => (
  <div className="loader-screen">
    <div className="loading-indicator">
      <div class="dot-spinner">
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
      </div>
    </div>
  </div>
);

const getWinrateColor = (winrate) => {
  const numericWinrate = parseFloat(winrate);
  if (numericWinrate === 0.5) return "#fff"; 
  if (numericWinrate < 0.5) return "red";
  return "green"; 
};

const InhouseRanking = () => {
  const [playerData, setPlayerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedHeader, setSelectedHeader] = useState(""); 
  const [currentPage, setCurrentPage] = useState(1);
  const playersPerPage = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [queueId, setQueueId] = useState("");

  const twitchUsernames = {
    Deam147: "Rubius",
    Fenglolz: "fenglolz",
  };

  const addTwitchToPlayers = (players, twitchList) => {
    return players.map((player) => {
      const twitchUsername = twitchList[player.name];
      return { ...player, twitch: twitchUsername || "" };
    });
  };
  const checkIfTwitchLive = async (username) => {
    try {
      const response = await axios.get(
        `https://leagueprodatabackend.vercel.app/check-live/${username}/`
      );
      return response.data.live;
    } catch (error) {
      console.error("Error checking Twitch status:", error);
      // En caso de error, asumimos que el usuario no está en línea
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `https://api.neatqueue.com/api/leaderboard/784121805437665352/${queueId}`,
          {
            headers: {
              Authorization: "I72b1Ri-22tqhyjZwJCwjhjWeo7w9ze2",
            },
          }
        );

        const allPlayers = response.data.alltime || [];

        // Ordenar por MMR en orden descendente
        const sortedPlayers = allPlayers.sort(
          (a, b) => b.data.mmr - a.data.mmr
        );

        const updatedPlayerData = addTwitchToPlayers(
          sortedPlayers,
          twitchUsernames
        );

        const playersWithRanks = updatedPlayerData.map((player, index) => ({
          ...player,
          data: {
            ...player.data,
            rank: index + 1,
          },
        }));

        const playersWithLiveStatus = await Promise.all(
          playersWithRanks.map(async (player) => {
            if (player.twitch) {
              const isLive = await checkIfTwitchLive(player.twitch);
              return { ...player, isLive };
            }
            return { ...player, isLive: false };
          })
        );

        setPlayerData(playersWithLiveStatus);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [queueId]);

  const handleTRQueueClick = () => {
    setQueueId("1185020766866112544");
  };

  const handleLiveQueueClick = () => {
    setQueueId("1185023429846519899");
  };

  const handleFEMQueueClick = () => {
    setQueueId("1185024739467608145");
  };

  const [sortOrders, setSortOrders] = useState({});

  const handleSort = (field) => {
    const newOrder = sortOrders[field] === "asc" ? "desc" : "asc";

    const updatedSortOrders = { ...sortOrders, [field]: newOrder };

    const sorted = [...playerData].sort((a, b) => {
      if (a.data[field] === b.data[field]) {
        const secondaryField = field === "wins" ? "losses" : "wins";
        return sortOrders[secondaryField] === "asc"
          ? a.data[secondaryField] - b.data[secondaryField]
          : b.data[secondaryField] - a.data[secondaryField];
      }

      if (field === "name") {
        return newOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }

      const orderMultiplier = newOrder === "asc" ? 1 : -1;
      return (a.data[field] - b.data[field]) * orderMultiplier;
    });

    const isSameAsCurrent =
      JSON.stringify(playerData) === JSON.stringify(sorted);

    if (isSameAsCurrent) {
      updatedSortOrders[field] = newOrder === "asc" ? "desc" : "asc";
      sorted.reverse();
    }

    setPlayerData(sorted);
    setSortOrders(updatedSortOrders);
  };

  const filteredPlayers = playerData.filter((player) =>
    player.name.toLowerCase().includes(searchTerm)
  );

  const indexOfLastPlayer = currentPage * playersPerPage;
  const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
  const currentPlayers = filteredPlayers.slice(
    indexOfFirstPlayer,
    indexOfLastPlayer
  );

  // Cambia a la página Next
  const nextPage = () => {
    if (indexOfLastPlayer < playerData.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Cambia a la página Back
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase()); // Actualiza el término de búsqueda en minúsculas
    setCurrentPage(1); // Reinicia a la primera página al iniciar una nueva búsqueda
  };

  return (
    <div className="inhouses">
      <h1
        className="font-main"
        style={{
          textAlign: "center",
          fontSize: "28px",
          marginBottom: "50px",
          marginTop: "50px", // Agregando un margen uniforme arriba
          textTransform: "uppercase", // Convertir el texto a mayúsculas
          color: "#fff", // Color blanco
        }}
      >
        INHOUSE LATAM NORTE
      </h1>
      <div className="queueButtons">
        <button className="button-queue" onClick={handleTRQueueClick}>
          TR QUEUE
        </button>
        <button className="button-queue" onClick={handleLiveQueueClick}>
          LIVE QUEUE
        </button>
        <button className="button-queue" onClick={handleFEMQueueClick}>
          FEM QUEUE
        </button>
      </div>
      {loading ? (
        <LoadingMessage />
      ) : (
        <>
          <div className="searchBarContainer">
            <input
              type="text"
              placeholder="Buscar jugador..."
              value={searchTerm}
              onChange={handleSearch}
              className="searchBar"
            />
          </div>

          <StyledTable>
            <thead>
              <tr>
                <StyledTh
                  className={
                    selectedHeader === "rank"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("rank")}
                  onClick={() => handleSort("rank")}
                >
                  <Icon>
                    <GiPodiumWinner />
                  </Icon>
                  Rank
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "name"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("name")}
                  onClick={() => handleSort("name")}
                >
                  <Icon>
                    <FaUserAlt />
                  </Icon>
                  Nombre
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "mmr"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("mmr")}
                  onClick={() => handleSort("mmr")}
                >
                  <Icon>
                    <FaTrophy />
                  </Icon>
                  MMR
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "peak_mmr"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("peak_mmr")}
                  onClick={() => handleSort("peak_mmr")}
                >
                  <Icon>
                    <FaChartLine />
                  </Icon>
                  Peak MMR
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "streak"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("streak")}
                  onClick={() => handleSort("streak")}
                >
                  <Icon>
                    <FaBolt />
                  </Icon>
                  Streak
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "peak_streak"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("peak_streak")}
                  onClick={() => handleSort("peak_streak")}
                >
                  <Icon>
                    <FaBolt />
                  </Icon>
                  Peak Streak
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "wins"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("wins")}
                  onClick={() => handleSort("wins")}
                >
                  <Icon>
                    <FaThumbsUp />
                  </Icon>
                  Wins
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "losses"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("losses")}
                  onClick={() => handleSort("losses")}
                >
                  <Icon>
                    <FaThumbsDown />
                  </Icon>
                  Losses
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "winrate"
                      ? "selected font-main"
                      : "font-main"
                  }
                  onMouseEnter={() => setSelectedHeader("winrate")}
                  onClick={() => handleSort("winrate")}
                >
                  <Icon>
                    <FaPercentage />
                  </Icon>
                  Winrate
                </StyledTh>
                <StyledTh
                  className={
                    selectedHeader === "Twitch"
                      ? "selected font-main"
                      : "font-main"
                  }
                >
                  Twitch
                </StyledTh>
              </tr>
            </thead>
            <tbody>
              {currentPlayers.map((player, index) => (
                <tr key={index}>
                  <StyledTd rank={player.data.rank} field="rank">
                    {player.data.rank}
                  </StyledTd>
                  <StyledTd rank={player.data.rank} field="name">
                    {player.name}
                  </StyledTd>
                  <StyledTd rank={player.data.rank}>
                    {Math.floor(player.data.mmr)}
                  </StyledTd>
                  <StyledTd rank={player.data.rank}>
                    {Math.floor(player.data.peak_mmr)}
                  </StyledTd>
                  <StyledTd rank={player.data.rank}>
                    {player.data.streak}
                  </StyledTd>
                  <StyledTd rank={player.data.rank}>
                    {player.data.peak_streak}
                  </StyledTd>
                  <StyledTd rank={player.data.rank}>
                    {player.data.wins}
                  </StyledTd>
                  <StyledTd rank={player.data.rank}>
                    {player.data.losses}
                  </StyledTd>
                  <StyledTd
                    rank={player.data.rank}
                    style={{ color: getWinrateColor(player.data.winrate) }}
                  >
                    {(player.data.winrate * 100).toFixed(2)}%
                  </StyledTd>{" "}
                  <TwitchIcon
                    href={`https://www.twitch.com/${player.twitch}`}
                    target="_blank"
                    isLive={player.isLive} // Pasar el estado isLive como prop
                  >
                    <FaTwitch /> {/* Agrega el ícono de Twitch aquí */}
                  </TwitchIcon>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      )}
      <div className="buttons-pagination">
        <button
          className="button-pag"
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Back
        </button>
        <button
          className="button-pag"
          onClick={nextPage}
          disabled={indexOfLastPlayer >= playerData.length}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default InhouseRanking;
