import React from "react";
import "./loginPrompt.css"; // Usamos el mismo archivo CSS

const DiscordLinkedSuccess = () => {
  return (
    <div className="wrapper-prompt">
      <div className="login-prompt">
        <h2>Your Discord account has been successfully linked!</h2>
        <p>You can now access all the features.</p>
      </div>
    </div>
  );
};

export default DiscordLinkedSuccess;
