import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './HomePage.module.css'

export default function HomePage() {
  const [animatedText, setAnimatedText] = useState('WITH CUSTOMIZED PROFILES')
  const [fade, setFade] = useState(true) // Nuevo estado para manejar el fade
  const texts = [
    'WITH SOLOQ RANKINGS',
    'WITH PRO PLAYER INSIGHTS',
    'WITH LPL PROVIEWS',
    'WITH WORLDS PREDICTS'
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false)
      setTimeout(() => {
        setAnimatedText(texts[Math.floor(Math.random() * texts.length)])
        setFade(true)
      }, 500)
    }, 3500)

    return () => clearInterval(interval)
  }, [])

  return (
    <main className={styles.homePage}>
      <div className={styles.backgroundLights}>
        <div className={styles.light1}></div>
        <div className={styles.light2}></div>
        <div className={styles.light3}></div>
      </div>

      <div className={styles.container}>
        <section className={styles.hero}>
          <h1 className={styles.mainTitle}>LEAGUE PRO DATA</h1>
          <p className={styles.subtitle}>EXPERIENCE THE NEW ERA OF</p>
          <h2 className={styles.secondaryTitle}>LEAGUE OF LEGENDS</h2>
          <p className={`${styles.animatedText} ${fade ? styles.fadeIn : styles.fadeOut}`}>
            {animatedText}
          </p>
          <div className={styles.ctaButtons}>
            <Link to="/ranking/worlds" className={styles.rankings}>View Rankings</Link>
            <Link to="/Predictions" className={styles.worldsPredict}>Worlds Predictions</Link>
          </div>
        </section>

        <section className={styles.contentShowcase}>
          <div className={styles.textContent}>
            <div>
              <h3 className={`${styles.contentTitle} ${styles.cyan}`}>Dive into the Pro Scene</h3>
              <p className={styles.contentText}>
                Stay ahead of the game with our comprehensive coverage of professional League of Legends. From team
                rankings to player stats, we've got everything you need to follow your favorite teams and players.
              </p>
            </div>
            <div>
              <h3 className={`${styles.contentTitle} ${styles.yellow}`}>Master Your Gameplay</h3>
              <p className={styles.contentText}>
                Level up your skills with our in-depth guides, pro tips, and advanced analytics. Whether you're a
                casual player or aspiring pro, we have the tools to help you improve.
              </p>
            </div>
            <Link to="/Proviews-2024" className={styles.proViews}>Explore Pro Views</Link>
          </div>
          <div className={styles.championImage}>
            <img src="https://leagueprodata.com/images/home.png" alt="Featured Champion" />
            <div className={styles.imageOverlay}></div>
          </div>
        </section>
      </div>
    </main>
  )
}
