import "./register.css";
import React, { useState } from "react";
import axios from "axios";
import Logo from "../images/LPD.png";

const RegisterForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();

    // Validations
    if (
      !username ||
      !password ||
      !confirmPassword ||
      !email ||
      !firstName ||
      !lastName
    ) {
      setError("Todos los campos son obligatorios.");
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError("Formato de correo inválido.");
      return;
    }

    if (password.length < 8) {
      setError("La contraseña debe tener al menos 8 caracteres.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden.");
      return;
    }

    // Perform registration
    try {
      const response = await axios.post(
        "https://leagueprodatabackend.vercel.app/api/user/register/",
        {
          username,
          password,
          password2: confirmPassword,
          email,
          first_name: firstName,
          last_name: lastName,
        }
      );

      if (response.status === 201) {
        setSuccessMessage("Usuario registrado exitosamente");
        clearFormFields();
        setTimeout(() => {
          window.location.href = "/login";
        }, 5000);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("Error en el registro");
      }
    }
  };

  const clearFormFields = () => {
    setUsername("");
    setPassword("");
    setConfirmPassword("");
    setEmail("");
    setFirstName("");
    setLastName("");
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <img src={Logo} alt="Logo" className="register-logo" />
        <h2>CREATE ACCOUNT</h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {!successMessage && (
          <form onSubmit={handleRegister}>
            {error && <div className="error-message">{error}</div>}
            <div className="input-container">
              <label className="account-label" htmlFor="firstName">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="registerButton"
              />
            </div>
            <div className="input-container">
              <label className="account-label" htmlFor="lastName">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="registerButton"
              />
            </div>
            <div className="input-container">
              <label className="account-label" htmlFor="username">
                Username
              </label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="registerButton"
              />
            </div>
            <div className="input-container">
              <label className="account-label" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="registerButton"
              />
            </div>
            <div className="input-container">
              <label className="account-label" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="registerButton"
              />
            </div>
            <div className="input-container">
              <label className="account-label" htmlFor="confirmPassword">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="registerButton"
              />
            </div>
            <button
              className="registerButton registerButtonColor"
              type="submit"
            >
              Register
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default RegisterForm;
