import React, { useState, useEffect, useCallback } from "react";
import "./Predictions.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

const RankingSection = () => {
  const apiUrl =
    "https://leagueprodatabackend.vercel.app/api/user-points";
  const myPointsApiUrl =
    "https://leagueprodatabackend.vercel.app/api/get_my_points/";
  const [userPoints, setUserPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [myPointsData, setMyPointsData] = useState(null);

  const [tournament, setTournament] = useState("Worlds 2024"); 

  const availableTournaments = [
    { value: "General", label: "General" },

    { value: "Worlds 2024", label: "Worlds 2024" },
    { value: "Americas Challengers", label: "Americas Challengers" },
  ];

  const handleTournamentChange = (event) => {
    setTournament(event.target.value);
  };

  const setDocumentTitle = () => {
    document.title = `League Pro Data | Ranking Predictions`;
  };

  useEffect(() => {
    setDocumentTitle();

    return () => {
      document.title = "League Pro Data | Ranking Predictions";
    };
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}?page=${page}&page_size=${pageSize}&tournaments=${tournament}`
      );
      console.log(response)
      setUserPoints(response.data.results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user points:", error);
      setLoading(false);
    }
  };

  const fetchMyPoints = async () => {
    try {
      const response = await axios.post(
        myPointsApiUrl,
        { tournaments: tournament },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setMyPointsData(response.data);
    } catch (error) {
      console.error("Error fetching user points:", error);
      setMyPointsData(null);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchMyPoints();
  }, [page, tournament]);

  const nextPage = useCallback(() => {
    if (userPoints.length >= pageSize) {
      setPage((prevPage) => prevPage + 1);
      window.scrollTo(0, 0);
    }
  }, [userPoints]);

  const prevPage = useCallback(() => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
      window.scrollTo(0, 0);
    }
  }, [page]);

  const LoadingMessage = () => (
    <div className="loader-screen">
      <div className="loading-indicator">
        <div className="dot-spinner">
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="ranking-section">
      {loading ? (
        <LoadingMessage />
      ) : (
        <div className="ranking-cards-container">
          <select
            className="select-tournament"
            value={tournament}
            onChange={handleTournamentChange}
          >
            <option value="" disabled>
              Select a Tournament
            </option>
            {availableTournaments.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {myPointsData && (
            <>
              <h1>Your Position</h1>

              <div className="ranking-card user-points-card">
                <div className="rank">{myPointsData.user_position}</div>
                <div className="username">{myPointsData.username}</div>
                <div className="total-points">
                  {myPointsData.user_points} Points
                </div>
              </div>
            </>
          )}
          <h2 className="ch">LEAGUE PRO DATA RANKING</h2>

          <div className="ranking-card header">
            <div className="rank">#</div>
            <div className="username">User</div>
            <div className="total-points">Points</div>
          </div>
          {userPoints.map((user, index) => (
            <div
              className={`ranking-card ${
                index < 3 && page === 1
                  ? index === 0
                    ? "gold"
                    : index === 1
                    ? "silver"
                    : "bronze"
                  : ""
              }`}
              key={index}
            >
              <div
                className={`rank ${
                  index < 3 && page === 1
                    ? index === 0
                      ? "gold"
                      : index === 1
                      ? "silver"
                      : "bronze"
                    : ""
                }`}
              >
                {index + 1 + (page - 1) * pageSize}
              </div>
              <div className="username">
                {user.verified && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="verified-icon"
                  />
                )}
                {user.username}
                {user.verified && (
                  <a
                    href={user.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      className="twitter-icon"
                    />
                  </a>
                )}
              </div>
              <div className="total-points">{user.total_points} Points</div>
            </div>
          ))}
          <div className="pagination">
            <button
              className="pagination-button"
              onClick={prevPage}
              disabled={page === 1 || loading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 12H20M4 12L8 8M4 12L8 16"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Previous
            </button>
            <button
              className="pagination-button"
              onClick={nextPage}
              disabled={loading}
            >
              Next
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 12H20M20 12L16 8M20 12L16 16"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RankingSection;
