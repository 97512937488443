import React, { useState } from 'react';
import { Globe } from 'lucide-react';
import './Info.css'; // Make sure to create this CSS file

const Info = () => {
  const [language, setLanguage] = useState('ENG');  // Cambiado a string

  const toggleLanguage = () => {
    setLanguage(prevLang => prevLang === 'ENG' ? 'ESP' : 'ENG');
  };

  const content = {
    ENG: {
      title: 'Points System',
      subtitle: 'Win points in Predicts League Pro Data!',
      points: 'Points',
      correctResult: 'per correct result',
      correctWinner: 'per correct winner',
      guessScore: 'Guess the score and winner to earn 200 points!',
      compete: 'Compete and have fun with your friends and favorite content creators.',
      changeLanguage: 'Change Language'
    },
    ESP: {
      title: 'Sistema de Puntos',
      subtitle: '¡Gana puntos en Predicts League Pro Data!',
      points: 'Puntos',
      correctResult: 'por resultado correcto',
      correctWinner: 'por ganador correcto',
      guessScore: '¡Acierta el marcador y el ganador para llevarte 200 puntos!',
      compete: '¡Compite y diviértete junto a tus amigos y creadores de contenido favoritos!',
      changeLanguage: 'Cambiar Idioma'
    }
  };

  const { title, subtitle, points, correctResult, correctWinner, guessScore, compete, changeLanguage } = content[language];

  return (
    <div className="info-container">
      <h2 className="info-title">{title}</h2>
      <div className="info-content">
        <p className="info-subtitle">{subtitle}</p>
        <div className="points-row">
          <span className="points-value">100 {points}</span>
          <span>{correctResult}</span>
        </div>
        <div className="points-row">
          <span className="points-value">100 {points}</span>
          <span>{correctWinner}</span>
        </div>
        <p className="guess-score">{guessScore}</p>
        <p className="compete-text">{compete}</p>
      </div>
      <button onClick={toggleLanguage} className="language-button">
        <Globe className="globe-icon" />
        {changeLanguage}
      </button>
    </div>
  );
};

export default Info;