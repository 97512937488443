import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "./Loader";

export default function UpdateProfile() {
  const [profileData, setProfileData] = useState({
    username: "",
    country: "",
    birth_date: "",
    favorite_player: "",
    favorite_team: "",
    discord_user: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [countries, setCountries] = useState([]);
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [countryFlags, setCountryFlags] = useState({});
  const defaultImage = "https://leagueprodata.com/images/default.webp";
  const [imageSrc, setImageSrc] = useState(defaultImage);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countriesRes, teamsRes, playersRes, profileRes] =
          await Promise.all([
            axios.get("https://restcountries.com/v3.1/all"),
            axios.get(
              "https://leagueprodatabackend.vercel.app/api/team-tricodes/"
            ),
            axios.get("https://leagueprodatabackend.vercel.app/api/players/"),
            axios.get(
              "https://leagueprodatabackend.vercel.app/api/user/profile/",
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
              }
            ),
          ]);

        const countryData = countriesRes.data.map((country) => ({
          name: country.name.common,
          flag: country.flags.png,
        }));
        setCountries(countryData.sort((a, b) => a.name.localeCompare(b.name)));
        setCountryFlags(
          Object.fromEntries(countryData.map((c) => [c.name, c.flag]))
        );
        setTeams(teamsRes.data.map((team) => team.TeamTriCode));
        setPlayers(playersRes.data.map((player) => player.playerName));
        setProfileData({
          ...profileRes.data,
          birth_date: profileRes.data.birth_date
            ? new Date(profileRes.data.birth_date).toISOString().split("T")[0]
            : "",
        });
      } catch (error) {
        setError("Error loading data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const image = require(`../components/lolpredict/playerImages/${profileData.favorite_player}.webp`);
        setImageSrc(image);
      } catch (error) {
        setImageSrc(defaultImage);
      }
    };

    loadImage();
  }, [profileData.favorite_player]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://leagueprodatabackend.vercel.app/update-profile/",
        profileData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setIsEditing(false);
    } catch (error) {
      setError("Error updating profile. Please try again.");
    }
  };

  const isProfileComplete = () => {
    return Object.values(profileData).every(
      (value) => value !== "" && value !== null
    );
  };

  const handleImageError = (e) => {
    e.target.src = defaultImage;
  };

  if (loading)
    return (
      <div className="register-container">
        <Loader></Loader>
      </div>
    );
  if (error)
    return (
      <div className="register-container">
        <div className="register-box">{error}</div>
      </div>
    );

  return (
    <div className="register-container update-container">
      <div className="register-box">
        <h2>UPDATE PROFILE</h2>
        {isProfileComplete() && !isEditing ? (
          <>
            <div className="profile-info">
              <h3 className="username-profile">{profileData.username}</h3>
              <div className="profile-details">
                <div className="profile-item">
                  <p><strong>Country:</strong></p>
                  <p className="profile-value">
                    {profileData.country && (
                      <img
                        src={countryFlags[profileData.country] || defaultImage}
                        alt={`${profileData.country} flag`}
                        className="country-flag"
                        onError={handleImageError}
                      />
                    )}
                    {profileData.country}
                  </p>
                </div>
                <div className="profile-item">
                  <p><strong>Birth Date:</strong></p>
                  <p className="profile-value">{profileData.birth_date}</p>
                </div>
              </div>
              <div className="favorites-container">
                <div className="favorite-item">
                  <p>
                    <strong className="text-label">Favorite Player</strong>
                  </p>
                  {profileData.favorite_player && (
                    <div className="image-container">
                      <img
        src={imageSrc}
        className="player-avatar-pic-profile"
        alt={profileData.favorite_player}
      />
                    </div>
                  )}
                  <p className="favorite-name">{profileData.favorite_player}</p>
                </div>
                <div className="favorite-item">
                  <p>
                    <strong>Favorite Team</strong>
                  </p>
                  {profileData.favorite_team && (
                    <div className="image-container">
                      <img
                        src={require(`../components/lolpredict/teamImages/${profileData.favorite_team}.webp`)}
                        className="team-avatar-pic-profile"
                        alt={profileData.favorite_team}
                        onError={handleImageError}
                      />
                    </div>
                  )}
                  <p className="favorite-name">{profileData.favorite_team}</p>
                </div>
              </div>
            </div>
            <div className="update-profile">
              <button
                onClick={() => setIsEditing(true)}
                className="registerButton registerButtonColor"
              >
                Edit Profile
              </button>
              {profileData.discord_user ? (
                <p className="text-discord">Discord account linked</p>
              ) : (
                <>
                  <p className="text-discord">Link your Discord account to complete your profile</p>
                  <a
                    href="/Link-Discord/"
                    className="registerButton registerButtonColor"
                  >
                    Link Discord
                  </a>
                </>
              )}
            </div>
          </>
        ) : (
          <form onSubmit={handleUpdate}>
            {error && <div className="error-message">{error}</div>}

            <div className="input-container">
              <label className="account-label" htmlFor="country">
                Country
              </label>
              <select
                id="country"
                value={profileData.country}
                onChange={(e) =>
                  setProfileData({ ...profileData, country: e.target.value })
                }
                className="custom-select"
              >
                <option value="">Select a Country</option>
                {countries.map((country) => (
                  <option key={country.name} value={country.name}>
                    <img
                      src={country.flag || defaultImage}
                      alt={`${country.name} flag`}
                      className="country-flag-option"
                      onError={handleImageError}
                    />{" "}
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-container">
              <label className="account-label" htmlFor="birthDate">
                Birth Date
              </label>
              <input
                type="date"
                id="birthDate"
                value={profileData.birth_date}
                onChange={(e) =>
                  setProfileData({ ...profileData, birth_date: e.target.value })
                }
                className="registerButton"
              />
            </div>
            <div className="input-container">
              <label className="account-label" htmlFor="favoritePlayer">
                Favorite Player
              </label>
              <select
                id="favoritePlayer"
                value={profileData.favorite_player}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    favorite_player: e.target.value,
                  })
                }
                className="custom-select"
              >
                <option value="">Select a Player</option>
                {players.map((player) => (
                  <option key={player} value={player}>
                    {player}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-container">
              <label className="account-label" htmlFor="favoriteTeam">
                Favorite Team
              </label>
              <select
                id="favoriteTeam"
                value={profileData.favorite_team}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    favorite_team: e.target.value,
                  })
                }
                className="custom-select"
              >
                <option value="">Select a Team</option>
                {teams.map((team) => (
                  <option key={team} value={team}>
                    {team}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              className="registerButton registerButtonColor update-profile"
            >
              Update Profile
            </button>
            {!profileData.discord_user && (
              <div className="update-profile">
                <p className="text-discord text-discord-link">Link your Discord account to complete your profile</p>
                <a
                  href="/Link-Discord/"
                  className="registerButton registerButtonColor"
                >
                  Link Discord
                </a>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
}
