import React from "react";
import "./loginPrompt.css"; // Usamos el mismo archivo CSS

const LinkDiscordPrompt = () => {
  return (
    <div className="wrapper-prompt">
      <div className="login-prompt">
        <h2>Complete your profile to access this content.</h2>
        <a
          href="/update-profile"
          className="loginButtonPrompt loginButtonColor"
        >
          Edit Profile
        </a>
      </div>
    </div>
  );
};

export default LinkDiscordPrompt;
