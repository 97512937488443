import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTiktok,
  faXTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faSignInAlt, faLanguage } from "@fortawesome/free-solid-svg-icons";

import "./Prizes.css";

function Premios() {
  const [language, setLanguage] = useState("ESP");
  const [username, setUsername] = useState("");
  const [joinedSuccessfully, setJoinedSuccessfully] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authResponse = await axios.post(
          "https://leagueprodatabackend.vercel.app/api/get_session_info/",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        const authData = authResponse.data;
        setUsername(authData.username);
      } catch (error) {
        console.error(
          "Error al obtener la información de autenticación: ",
          error
        );
      }
    };

    fetchData();
  }, []);

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "ENG" ? "ESP" : "ENG"));
  };

  const handleJoinLeague = () => {
    const joinData = {
      creator_code: "IsurusWorlds2023",
      username: username,
    };

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };

    axios
      .post("https://leagueprodatabackend.vercel.app/api/join_league/", joinData, {
        headers,
      })
      .then((response) => {
        setJoinedSuccessfully(true);
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud POST:", error);
      });
  };

  const content = {
    ENG: {
      title: "Prizes",
      xsonicTitle: "Prizes by XSonic (Global Ranking)",
      isurusTitle: "Isurus League Prizes",
      xsonicPrizes: [
        "First Place - 1350 RP + 2 Chests",
        "Second Place - 6 Chests",
        "Third Place - 4 Chests",
        "4th to 10th Place - 2 Chests",
      ],
      isurusPrizes: [
        "1st Place",
        "1 Isurus 2024 Jersey",
        "1 video of your favorite Isurus player, streamer, or staff",
        "1 Kingston Portable 1TB SSD",
      ],
      participationInfo: "To participate, go to the Leagues section and join the league named Isurus. Click the Join button and use the code IsurusWorlds2023.",
      winnerInfo: "Winners will be contacted at the registered email address, and there will be a 60-day deadline for prize delivery.",
      joinButton: "Join League (IsurusWorlds2023)",
      successMessage: "You have successfully joined",
      changeLanguage: "Change Language",
    },
    ESP: {
      title: "Premios",
      xsonicTitle: "Premios de XSonic (Ranking Global)",
      isurusTitle: "Premios de la Liga Isurus",
      xsonicPrizes: [
        "Primer Lugar - 1350 RP + 2 Cajitas",
        "Segundo Lugar - 6 cajitas",
        "Tercer Lugar - 4 cajitas",
        "Del 4 al 10 lugar - 2 cajitas",
      ],
      isurusPrizes: [
        "Primer Lugar",
        "1 jersey Isurus 2024",
        "1 video de su jugador, streamer o staff favorito de Isurus",
        "1 SSD Kingston portátil 1TB",
      ],
      participationInfo: "Para participar puedes unirte directamente con el boton de abajo o puedes ir al apartado Leagues y entrar a la liga llamada Isurus y darle al botón de Join para unirte con el código IsurusWorlds2023.",
      winnerInfo: "Los ganadores serán contactados al correo electrónico registrado y habrá un plazo de 60 días para la entrega de premios.",
      joinButton: "Participar Join League (IsurusWorlds2023)",
      successMessage: "Te has unido correctamente",
      changeLanguage: "Cambiar Idioma",
    },
  };

  const { title, xsonicTitle, isurusTitle, xsonicPrizes, isurusPrizes, participationInfo, winnerInfo, joinButton, successMessage, changeLanguage } = content[language];

  return (
    <div className="prizes-container">
      <h1 className="prizes-title">{title}</h1>
      
      <div className="prizes-section">
        <h2 className="section-title">{xsonicTitle}</h2>
        <div className="sponsor-info">
          <img src={require("./lolpredict/xsonic.jpeg")} alt="XSonic" className="sponsor-image" />
          <div className="social-icons">
            <a href="https://www.tiktok.com/@xsoniclol" target="_blank" rel="noopener noreferrer" className="social-link">
              <FontAwesomeIcon icon={faTiktok} />
            </a>
            <a href="https://twitter.com/XSoniclol" target="_blank" rel="noopener noreferrer" className="social-link">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a href="https://www.instagram.com/xsoniclol/" target="_blank" rel="noopener noreferrer" className="social-link">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
        <ul className="prize-list">
          {xsonicPrizes.map((prize, index) => (
            <li key={index}>{prize}</li>
          ))}
        </ul>
      </div>

      <div className="prizes-section">
        <h2 className="section-title">{isurusTitle}</h2>
        <div className="sponsor-info">
          <img src={require("./lolpredict/isurus.png")} alt="Isurus" className="sponsor-image" />
          <div className="social-icons">
            <a href="https://www.tiktok.com/@teamisurus" target="_blank" rel="noopener noreferrer" className="social-link">
              <FontAwesomeIcon icon={faTiktok} />
            </a>
            <a href="https://twitter.com/teamisurus" target="_blank" rel="noopener noreferrer" className="social-link">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a href="https://www.instagram.com/teamisurus/" target="_blank" rel="noopener noreferrer" className="social-link">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
        <ul className="prize-list">
          {isurusPrizes.map((prize, index) => (
            <li key={index}>{prize}</li>
          ))}
        </ul>
      </div>

      <p className="info-text">{participationInfo}</p>
      <p className="info-text">{winnerInfo}</p>

      <button className="action-button join-button" onClick={handleJoinLeague}>
        <FontAwesomeIcon icon={faSignInAlt} className="button-icon" />
        {joinButton}
      </button>

      {joinedSuccessfully && (
        <p className="success-message">{successMessage}</p>
      )}

      <button className="action-button language-button" onClick={toggleLanguage}>
        <FontAwesomeIcon icon={faLanguage} className="button-icon" />
        {changeLanguage}
      </button>
    </div>
  );
}

export default Premios;